<div id="klayout-container">
    <div id="klayout-right">
      <div id="klayout-right-top" class="klayout-top">
        <!-- Actions -->
        <div class="kpage-title">
          Report: Cost & Consumption by Product
        </div>
  
        <div class="kv-spacer"></div>
  
        <button mat-button color="primary" class="button-narrow" (click)="refresh()" [disabled]="!isReady()">
          <mat-icon>
            play_arrow
          </mat-icon>
          Run
        </button>
      </div>
      <div id="klayout-right-bottom">
  
        <!-- Contents -->
        <div class="report-container" *ngIf="userService.initialised | async">
          <div class="settings">
            Settings
  
            <mat-form-field appearance="outline" class="date-field-short">
              <mat-label>From</mat-label>
              <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a date" [(ngModel)]="date_from">
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="date-field-short">
              <mat-label>To</mat-label>
              <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date" [(ngModel)]="date_to">
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
  
  
            Bodyshops
  
            <ng-container *ngFor="let bshop of userService.currentUser.reportingBodyshops">
              <mat-checkbox color="primary" [value]="bshop" *ngIf="bshop.isEnabled()" [checked]="isBodyshopSelected(bshop)"
                (change)="clickBodyshop($event)">{{bshop.id_custom}}</mat-checkbox>
            </ng-container>
          </div>
          <div class="report">
            <table id="data-table">
              <tr>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Cost ({{userService.currentUser.tenant.currency.iso_4217}})</th>
                <th>Consumption (g)</th>
              </tr>
              <tr class="data-row" *ngFor="let product of data">
                <td>{{product.product.code}}</td>
                <td>{{product.product.name}}</td>
                <td class="td-right-align">{{product.cost | number:'1.2-2'}}</td>
                <td class="td-right-align">{{product.kg * 1000 | number:'1.2-2'}}</td>
  
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>