<div id="klayout-container">
  <div id="klayout-right">
    <div id="klayout-right-top" class="klayout-top">
      <!-- Actions -->
      <div class="kpage-title">
        Report: Job KPI
      </div>

      <div class="kv-spacer"></div>

      <button mat-button color="primary" class="button-narrow" (click)="refresh()" [disabled]="!isReady()">
        <mat-icon>
          play_arrow
        </mat-icon>
        Run
      </button>
    </div>
    <div id="klayout-right-bottom">

      <!-- Contents -->
      <div class="report-container" *ngIf="userService.initialised | async">
        <div class="settings">
          Settings

          <mat-form-field appearance="outline" class="date-field-short">
            <mat-label>From</mat-label>
            <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a date" [(ngModel)]="date_from">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="date-field-short">
            <mat-label>To</mat-label>
            <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date" [(ngModel)]="date_to">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>


          Bodyshops

          <ng-container *ngFor="let bshop of userService.currentUser.reportingBodyshops">
            <mat-checkbox color="primary" [value]="bshop" *ngIf="bshop.isEnabled()" [checked]="isBodyshopSelected(bshop)"
              (change)="clickBodyshop($event)">{{bshop.id_custom}}</mat-checkbox>
          </ng-container>
        </div>
        <div class="report">
          <table id="data-table">
            <tr>
              <th rowspan="2">Bodyshop</th>
              <th rowspan="2">Job ID</th>
              <th rowspan="2">Status</th>
              <th rowspan="2">Created</th>
              <th rowspan="2">User</th>
              <th rowspan="2">Benchmark ({{userService.currentUser.tenant.currency.iso_4217}})</th>
              <th rowspan="2">Benchmark vs Actual ({{userService.currentUser.tenant.currency.iso_4217}})</th>
              <th rowspan="2">Panels</th>
              <th rowspan="2">Cost/Panel ({{userService.currentUser.tenant.currency.iso_4217}})</th>
              <th colspan="4">Cost ({{userService.currentUser.tenant.currency.iso_4217}})</th>
              <th colspan="4">Consumption (g)</th>
            </tr>
            <tr>
              <th>Total</th>
              <th>Applied</th>
              <th>Wasted</th>
              <th>Matching</th>
              <th>Total</th>
              <th>Applied</th>
              <th>Wasted</th>
              <th>Matching</th>
            </tr>
            <tr class="data-row" *ngFor="let job of data">
              <td>{{job.bodyshop.id_custom}}</td>
              <td>
                <a [routerLink]="['/job', job.id, 'detail']">
                  <ng-container *ngIf="!job.id_custom">
                    __unknown__
                  </ng-container>
                  {{job.id_custom}}
                </a>
              </td>
              <td>
                <ng-container *ngIf="job.is_discarded">
                  Deleted
                </ng-container>
                <ng-container *ngIf="!job.is_discarded">
                  Closed
                </ng-container>
              </td>
              <td>{{stringToDate(job.ts_created) | date:'yyyy-MMM-dd'}}</td>
              <td>{{job.user_created.name}}</td>
              <td class="td-right-align">{{job.benchmark_amount | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.benchmark_vs_actual | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.cnt_panels}}</td>
              <td class="td-right-align">{{job.kpi.cost_per_panel | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.cost.total | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.cost.used | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.cost.waste | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.cost.match | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.quantity_kg.total * 1000 | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.quantity_kg.used * 1000 | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.quantity_kg.waste * 1000 | number:'1.2-2'}}</td>
              <td class="td-right-align">{{job.kpi.quantity_kg.match * 1000 | number:'1.2-2'}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>