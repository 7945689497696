import { Toolkit } from './../../toolkit/toolkit';

export class Currency {
    public id: string;
    public iso_4217: string;
    public name: string;
}

export class Unit {
    public id: string;
    public name_singular: string;
    public name_plural: string;
}

export class Bodyshop {
    public id: number;
    public name: string;
    public id_custom: string;
    public tenant: Tenant;
    public is_active: boolean;
    public is_deleted: boolean;
    public address = {};

    public isEnabled() {
        return this.is_active && !this.is_deleted;
    }
}

export class Tenant {
    public id: number;
    public name: string;
    public currency: Currency;
    public country: Country;
}

export class Country {
    public id: string;
    public iso_3166: string;
    public name_common: string;
    public name_full: string;
}

export class User {
    public id: number;
    public email: string;
    public name: string;
    public date_birth: Date;
    public employee_nb: string;
    public bodyshops: Bodyshop[] = []
    public bodyshop_id: number;
    public tenant: Tenant;
    public currentBodyshop: Bodyshop;
    public is_tenantAdmin: boolean = false;
    public is_active: boolean = false;
    public is_kansai:boolean = false;
    public is_developer:boolean = false;
    public permissions: any = {};
    public reportingBodyshops: Bodyshop[] = [];

    isPermitted(domain: string, entity: string) {
        if (!this.permissions)
            return false;

        if (!this.permissions[domain])
            return false;

        if (!this.permissions[domain][entity])
            return false;
        else
            return true;
    }

    isPermittedForDomain(domain: string) {
        if (!this.permissions)
            return false;

        if (this.permissions[domain]) {
            for (let entity in this.permissions[domain]) {
                if (this.permissions[domain][entity])
                    return true;
            }
            return false;
        }
        else
            return false;
    }

    isAllowedReports(bshop_id: number) {
        for (let bshop of this.reportingBodyshops) {
            if (bshop.id == bshop_id)
                return true;
        }

        return false;
    }
}


export class SecurityRole {
    public id: number;
    public code: string;
    public name: string;
    public rights: SecurityRight[] = [];
}

export class SecurityRight {
    public id: number;
    public code: string;
    public name: string;
}

export class Colour {
    public id: number;
    public carManufacturer: CarManufacturer;
    public name: string;
    public code: string;
}

export class Coat {
    public id: string;
    public name: string;
    public order: number;
    public code: string;
    public type: string;
    public applicationTechniques: ApplicationTechnique[] = [];
}

export class CoatSystem {
    public id: number;
    public product_type: ProductType;
    public coats: Coat[] = [];

}

export class FormulaComponent {
    public amount_kg: number = 0.0;
    public ratio: number;
    public error: number = 0.0;
    public product: Product;
    public product_category: ProductCategory;
}

export class RepairType {
    public id: string;
    public name: string;
}

export class Substrate {
    public id: string;
    public name: string;
}

export class Variant {
    public id: number;
    public name: string;
    public version: string;
    public is_active: boolean;
    public colour: Colour;
    public product_type: ProductType;
    public created_user: User;
    public system: string;
    public coats: VariantCoat[] = [];

    getCoat(coat: Coat) {
        return this.getCoatByCoatId(coat.id);
    }

    getCoatByCoatId(id) {
        for (let c of this.coats) {
            if (c.coat.id == id) {
                return c;
            }
        }
    }

    sortFormulasByCoat() {
        this.coats.sort((a: VariantCoat, b: VariantCoat) => {
            return a.coat.order - b.coat.order;
        });
    }

    toFullString(): string {
        if (this.colour && this.colour.carManufacturer)
            return this.colour.carManufacturer.name + " | " + this.colour.code + " - " + this.colour.name + " | " + this.name;
    }

    isWithRatios(): boolean {
        for (let coat of this.coats) {
            for (let component of coat.formula) {
                if (component.ratio == null) {
                    return false;
                }
            }
        }

        return true;
    }
}

export class VariantCoat {
    public coat: Coat;
    public formula: FormulaComponent[] = [];
    public history: VariantCoatAttempt[] = [];
    public batch_dev: Batch;
    public batch_mix: Batch;
}

export class VariantCoatAttempt {
    public user: User;
    public timestamp: Date = new Date();
    public formula: FormulaComponent[] = [];
    public f_index: number;
    public batch_test: Batch;
}

export class VariantAttempt {
    public variant: Variant;
    public timestamp: Date = new Date();
    public user: User;
}

export class ColourMatch {
    public id: number;
    public colour: Colour;
    public job_id: number;
    public variant: Variant;
    public variant_init: Variant;
    public batches_dev: any = {};
    public history: VariantAttempt[] = [];
    public is_active: boolean = true;
    public is_locked: boolean = false;

    setVariant(variant: Variant) {
        if (this.variant) {
            let historyEntry = new VariantAttempt();
            historyEntry.variant = this.variant;
            this.history.push(historyEntry);
        }
        this.variant = variant;
    }

    relinkBatches(batches: Batch[]) {
        //link batches to colour matches

        for (let coatKey in this.batches_dev) {
            if (this.batches_dev[coatKey]) {
                for (let batch of batches) {
                    if (batch.id == this.batches_dev[coatKey]) {
                        this.batches_dev[coatKey] = batch;
                        break;
                    }
                }
            }
        }

        if (this.variant) {
            for (let coat of this.variant.coats) {
                if (coat.history) {
                    for (let attempt of coat.history) {
                        for (let batch of batches) {
                            if (batch.id == attempt.batch_test.id) {
                                attempt.batch_test = batch;
                            }
                        }
                    }
                }
            }
        }
    }
}


export class Job {
    public id: number;
    public car_license_plate: string;
    public car_manufacturer: CarManufacturer;
    public car_model: CarModel;
    public car_model_text: string;
    public car_year: number;
    public car_chassis_number: string;
    public car_country: Country;
    public id_custom: string;
    public customer_id: string;
    public customer_name: string;
    public customer_phone: string;
    public bodyshop: Bodyshop;
    public comments: string;
    public panels: JobPanel[] = [];
    public batches: Batch[] = [];
    public colours: ColourMatch[] = [];
    public benchmark_amount: number;
    public date_created: Date;
    public date_modified: Date;
    public date_closed: Date;
    public date_discarded: Date;
    public cost: number;
    public quantity_kg: number;
    public customProductConsumption: JobCustomProduct[] = [];

    isEditable() {
        return !this.date_closed && !this.date_discarded;
    }

    getCustomProduct(id){
        for(let product of this.customProductConsumption){
            if(product.product.id === id){
                return product;
            }
        }

        return undefined;
    }

    getCostForMatchingBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getConsumptionForMatchingBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                total += batch.getTotalQuantityInKg();
            }
        }

        return total;
    }

    getTotalCostForMixBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getBatchById(id: number) {
        let batch = undefined;

        for (let b of this.batches) {
            if (b.id == id) {
                return b;
            }
        }

        return batch;
    }

    replaceBatch(batch: Batch) {
        for (let b of this.batches) {
            if (b.id == batch.id) {
                this.batches.splice(this.batches.indexOf(b), 1, batch);
                break;
            }
        }
    }

    getEstimateForSimpleCoat(coat: Coat, appTech: ApplicationTechnique, productType: ProductType, substrate?: Substrate, colour?: Colour) {
        let total_kg = 0.0;
        let appTech_id = appTech ? appTech.id : 'std';

        if (coat && productType) {
            for (let panel of this.panels) {
                if (panel.isSelected()) {
                    //only considere panels with correct substrate, or if none specified, all
                    if (substrate && panel.substrate.id != substrate.id)
                        continue;

                    if (colour && panel.colour.colour && panel.colour.colour.id != colour.id)
                        continue;

                    for (let surface of panel.surfaces) {
                        let side = surface.is_inside ? 'inside' : 'outside';
                        if (surface.is_selected) {
                            for (let sCoat of surface.coats) {
                                if (sCoat.is_selected) {
                                    if (sCoat.coat.id == coat.id) {
                                        if (panel.panel.id in this.car_model.consumption)
                                            if (side in this.car_model.consumption[panel.panel.id])
                                                if (surface.repair.id in this.car_model.consumption[panel.panel.id][side])
                                                    if (coat.id in this.car_model.consumption[panel.panel.id][side][surface.repair.id])
                                                        if (appTech_id in this.car_model.consumption[panel.panel.id][side][surface.repair.id][coat.id])
                                                            if (productType.id in this.car_model.consumption[panel.panel.id][side][surface.repair.id][coat.id][appTech_id])
                                                                total_kg += this.car_model.consumption[panel.panel.id][side][surface.repair.id][coat.id][appTech_id][productType.id];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return total_kg;
    }

    getBaseConsumptionForSimpleCoat(coat: Coat, appTech: ApplicationTechnique, substrate?: Substrate, colour?: Colour) {
        let total = 0.0;
        let appTech_id = appTech ? appTech.id : 'std';

        for (let batch of this.batches) {
            if (batch.coat.id == coat.id) {
                if (batch.applicationTechnique.id == appTech_id) {
                    if (substrate && batch.substrate && batch.substrate.id != substrate.id) {
                        continue
                    }

                    if (colour && batch.colour_id != colour.id)
                        continue;

                    total += batch.getTotalBaseQuantityInKg();
                }
            }
        }

        return total;
    }

    getColourMatchById(id: number) {
        for (let cmatch of this.colours) {
            if (cmatch.id == id) {
                return cmatch;
            }
        }
    }

    getTargetVsActualCost() {
        if (this.benchmark_amount) {
            return Toolkit.precision(this.benchmark_amount - this.getTotalCost(), 5)
        }
        else {
            return undefined;
        }
    }

    getConsumptionByProduct() {
        let result = {};

        for (let batch of this.batches) {
            for (let component of batch.getAllComponents()) {
                if (component.product) {
                    if (!(component.product.id in result)) {
                        result[component.product.id] = 0.0;
                    }

                    result[component.product.id] += component.quantity_kg;
                }
            }
        }

        return result;
    }


    addColourMatch(cMatch: ColourMatch) {
        if (!this.containsColour(cMatch.colour)) {
            this.colours.push(cMatch);
        }
    }

    getUnfinishedBatchesForCoat(coat:Coat, colour?:ColourMatch){
        let batches:Batch[] = [];

        for(let batch of this.batches){
            if(batch.coat.id == coat.id){
                let ok = true;
                if(colour){
                    ok = (colour.id == batch.colourMatch_id);
                }

                if(ok){
                    if(!batch.is_discarded && !batch.is_locked && !batch.is_matchingBatch){
                        batches.push(batch);
                    }
                }
            }
        }

        return batches;
    }


    getBatchesForCoat(coat: Coat, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.batches) {
            if (batch.coat.id == coat.id) {

                if (colour) {
                    if (colour.id == batch.colour_id) {
                        batches.push(batch);
                    }
                }
                else {
                    batches.push(batch);
                }
            }
        }

        return batches;
    }

    getBatchesForCoatAndSubstrate(coat: Coat, substrate: Substrate, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.getBatchesForCoat(coat, colour)) {
            if (!substrate && !batch.substrate) {
                batches.push(batch);
            }
            else if (batch.substrate && substrate && batch.substrate.id == substrate.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    getBatchesForCoatAndSubstrateAndAppTech(coat: Coat, substrate: Substrate, appTech: ApplicationTechnique, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.getBatchesForCoatAndSubstrate(coat, substrate, colour)) {
            if (!appTech && !batch.applicationTechnique) {
                batches.push(batch);
            }
            else if (batch.applicationTechnique && appTech && batch.applicationTechnique.id == appTech.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    containsColour(colour: Colour) {
        let found = false;

        for (let cmatch of this.colours) {
            if (cmatch.colour.id == colour.id) {
                if (cmatch.is_active) {
                    found = true;
                    break;
                }
            }
        }

        return found;
    }

    deleteColour(colourMatch: ColourMatch) {
        let foundJobColour: ColourMatch = undefined;

        //find item with same colour id
        for (let jc of this.colours) {
            if (jc.colour.id == colourMatch.colour.id) {
                foundJobColour = jc;
                break;
            }
        }

        //set as inactive
        foundJobColour.is_active = false;

        //splice array
        if (foundJobColour) {
            this.colours.splice(this.colours.indexOf(foundJobColour), 1);
        }
    }

    getActiveColours(): ColourMatch[] {
        let cols = [];

        for (let colour of this.colours) {
            if (colour.is_active)
                cols.push(colour);
        }

        return cols;
    }

    getTotalCost() {
        let total = 0.0;
        for (let batch of this.batches) {
            total += batch.getTotalCost();
        }

        return Toolkit.precision(total, 2);
    }

    getTotalCostAll(){
        let total = this.getTotalCost();

        for(let item of this.customProductConsumption){
            total += item.unit_cost + item.quantity;
        }

        return total;
    }

    getTotalCostWaste() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getTotalConsumptionWaste(){
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                total += batch.getTotalQuantityInKg();
            }
        }

        return total;
    }

    getTotalConsumption() {
        let total = 0.0;
        for (let batch of this.batches) {
            total += batch.getTotalQuantityInKg();
        }

        return Toolkit.precision(total, 5);
    }

    getTotalCostInUse(){
        let total = 0.0;
        for (let batch of this.batches) {
            if(!batch.is_matchingBatch && !batch.is_discarded){
                total += batch.getTotalCost();
            }
        }

        return Toolkit.precision(total, 5);
    }

    getTotalConsumptionInUse(){
        let total = 0.0;
        for (let batch of this.batches) {
            if(!batch.is_matchingBatch && !batch.is_discarded){
                total += batch.getTotalQuantityInKg();
            }
        }

        return Toolkit.precision(total, 5);
    }

    getCountRecalculations(){
        let total = 0;

        for(let batch of this.batches){
            if(!batch.is_matchingBatch){
                total += batch.recalculations.length;
            }
        }

        return total;
    }

    getEstimate(coat: Coat, substrate?: Substrate, variant?: Variant, productType?: ProductType, appTech?: ApplicationTechnique) {
        let appTechValue: string = appTech ? appTech.id : 'std';

        if (this.car_model && this.car_model.hasEstimatesData()) {
            //find all panels with this coat
            let found_panels: JobPanel[] = [];

            for (let panel of this.panels) {
                for (let surface of panel.surfaces) {
                    for (let s_coat of surface.coats) {
                        if (s_coat.coat.id == coat.id) {
                            found_panels.push(panel);
                        }
                    }
                }
            }

            //filter the panels by substrate
            if (substrate) {
                let tmp_panels = []
                for (let panel of found_panels) {
                    if (panel.substrate.id == substrate.id) {
                        tmp_panels.push(panel);
                    }
                }

                found_panels = tmp_panels;
            }

            //filter the panels by variant
            if (variant) {
                let tmp_panels = []
                for (let panel of found_panels) {
                    if (panel.colour.variant.id == variant.id) {
                        tmp_panels.push(panel);
                    }
                }

                found_panels = tmp_panels;
            }


            //calculate total
            let total = 0.0;

            for (let panel of found_panels) {
                if (panel.isSelected()) {
                    for (let surface of panel.surfaces) {
                        if (surface.is_selected) {
                            let surface_value = surface.is_inside ? 'inside' : 'outside';

                            if (surface.repair) {
                                for (let s_coat of surface.coats) {
                                    if (s_coat.coat.id == coat.id) {
                                        if (s_coat.is_selected) {
                                            try {
                                                total += this.car_model.consumption[panel.panel.id][surface_value][surface.repair.id][s_coat.coat.id][appTechValue][panel.colour.variant.product_type.id];
                                            }
                                            catch (TypeError) {

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            return Toolkit.precision(total, 5);
        }
    }

    getSelectedPanels(): JobPanel[] {
        let selected = [];

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                selected.push(panel);
            }
        }

        return selected;
    }


    getVariants(): Variant[] {
        let variants = new Set();

        for (let jobColour of this.colours) {
            if (jobColour.variant) {
                variants.add(jobColour.variant);
            }
        }

        return Array.from(variants);
    }

    getProductTypes(): ProductType[] {
        let ptypes = new Set();

        for (let jobColour of this.colours) {
            if (jobColour.variant) {
                ptypes.add(jobColour.variant.product_type);
            }
        }

        return Array.from(ptypes);
    }

    getAvailaleCoatsByTypeId(coatTypeId: string): Coat[] {
        let coats = new Set();

        for (let jColour of this.colours) {
            if (jColour.variant) {
                for (let coat of jColour.variant.product_type.getCoatsByTypeId(coatTypeId)) {
                    coats.add(coat);
                }
            }
        }

        return Array.from(coats);
    }

    getSubstrates(): Substrate[] {
        let substrates = new Set();

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                substrates.add(panel.substrate);
            }
        }

        return Array.from(substrates);
    }

    getApplicationTechniques(coatTypeId?: string): ApplicationTechnique[] {
        let appTechs = new Set();

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                for (let surface of panel.surfaces) {
                    if (surface.is_selected) {
                        for (let coat of surface.coats) {
                            if (coat.is_selected) {
                                if (coatTypeId) {
                                    if (coat.coat.type == coatTypeId) {
                                        appTechs.add(coat.application_technique);
                                    }

                                }
                                else {
                                    appTechs.add(coat.application_technique);
                                }
                            }
                        }
                    }
                }
            }
        }

        return Array.from(appTechs);
    }

    getAvailableApplicationTechniques(coatTypeId?: string): ApplicationTechnique[] {
        let appTechs = new Set();
        for (let coat of this.getAvailaleCoatsByTypeId(coatTypeId)) {
            for (let appTech of coat.applicationTechniques) {
                appTechs.add(appTech);
            }
        }

        return Array.from(appTechs);
    }

    getBatchStats(batches: Batch[]) {
        let stats = {
            'sum_baseKg_target': 0.0,
            'sum_baseKg_actual': 0.0,
            'sum_totalKg_actual': 0.0,
            'sum_cost_actual': 0.0
        }

        for (let batch of batches) {
            stats['sum_baseKg_target'] += batch.quantity_target_kg;
            stats['sum_baseKg_actual'] += batch.getTotalBaseQuantityInKg();
            stats['sum_totalKg_actual'] += batch.getTotalQuantityInKg();
            stats['sum_cost_actual'] += batch.getTotalCost();
        }

        return stats;
    }

    sortColours(): void {
        this.colours.sort((a: ColourMatch, b: ColourMatch) => {
            if (a.colour.code > b.colour.code) {
                return 1;
            }
            else if (a.colour.code < b.colour.code) {
                return -1;
            }
            else {
                return 0;
            }
        });
    }

    hasSelectedPanels(): boolean {
        let result = false;
        for (let panel of this.panels) {
            result = result || panel.isSelected();
        }

        return result;
    }

    usedSubstrates(): Substrate[] {
        let substrates = new Set();

        for (let panel of this.panels) {
            substrates.add(panel.substrate);
        }

        return Array.from(substrates).sort();
    }


    getJobPanel(search_panel: CarPanel): JobPanel {
        let result = null;

        for (let panel of this.panels) {
            if (panel.panel.id == search_panel.id) {
                return panel;
            }
        }

        return result;
    }


    // sort batches by
    // 1 - coat
    // 2 - variant or first product in components
    // 3 - sequence
    sortBatches() {
        this.batches.sort((a: Batch, b: Batch) => {
            return a.id - b.id; //TO-DO
        });
    }
}

export class JobPanel {
    public panel: CarPanel;
    public substrate: Substrate;
    public colour: ColourMatch;
    //public colour:JobColour;
    public surfaces: JobPanelSurface[] = [];

    isSelected(): boolean {
        return this.isSurfaceInsideSelected() || this.isSurfaceOutsideSelected();
    }

    getSurfaceInside() {
        return this.getSurface(true);
    }

    getSurfaceOutside() {
        return this.getSurface(false);
    }

    isSurfaceOutsideSelected() {
        if (this.getSurfaceOutside()) {
            return this.getSurfaceOutside().is_selected;
        }
        else {
            return false;
        }
    }

    isSurfaceInsideSelected() {
        if (this.getSurfaceInside()) {
            return this.getSurfaceInside().is_selected;
        }
        else {
            return false;
        }
    }

    getSurface(inside: boolean) {
        for (let surface of this.surfaces) {
            if (surface.is_inside == inside) {
                return surface;
            }
        }

        return undefined;
    }

}

export class JobPanelCoat {
    public coat: Coat;
    public application_technique: ApplicationTechnique;
    public is_selected: boolean = false;
}

export class JobPanelSurface {
    public is_inside: boolean;
    public is_selected: boolean = false;
    public repair: RepairType;
    public coats: JobPanelCoat[] = [];
}




export class Batch {
    public id: number;
    public quantity_target_kg: number;

    public baseProduct: Product;
    public formula: FormulaComponent[] = [];
    public formula_ancillary: FormulaComponent[] = [];

    public components: BatchComponent[] = [];
    public components_ancillary: BatchComponent[] = [];

    public coat: Coat;
    public substrate: Substrate;
    public system: string;
    public product_type: ProductType;
    public applicationTechnique: ApplicationTechnique;

    public is_discarded: boolean = false;
    public is_locked: boolean = false;
    public is_matchingBatch: boolean = false;
    public is_fromPreMix: boolean = false;

    public variant_id: Variant;
    public job_id: number;
    public colour_id: number;
    public colourMatch_id: number;
    public bodyshop_id: number;
    public is_split:boolean;

    public split_from: number;
    public recalculations: BatchOvershot[] = [];
    public panelSelection:any;

    constructor(formula?: FormulaComponent[],
        product_type?: ProductType,
        coat?: Coat,
        system?: string) {

        this.product_type = product_type;
        this.coat = coat;
        this.system = system;

        if (formula) {
            let newFormula = [];

            for (let component of formula) {
                let newComponent = new FormulaComponent();
                newComponent.product = component.product;
                newComponent.ratio = component.ratio;
                newComponent.error = component.error;
                newComponent.amount_kg = component.amount_kg;

                newFormula.push(newComponent);
            }

            this.formula = newFormula;
        }

    }

    updateValues(newBatch: Batch) {

    }

    isEditable() {
        return !this.is_locked && !this.is_discarded;
    }

    getAllFormulaComponents() {
        let all: FormulaComponent[] = [];

        for (let cmp of this.formula) {
            all.push(cmp);
        }
        for (let cmp of this.formula_ancillary) {
            all.push(cmp);
        }

        return all;
    }

    getAllComponents() {
        let all: BatchComponent[] = [];

        for (let cmp of this.components) {
            all.push(cmp);
        }
        for (let cmp of this.components_ancillary) {
            all.push(cmp);
        }

        return all;
    }

    initComponents() {
        this.components = [];

        for (let component of this.formula) {
            let newComp = new BatchComponent();
            newComp.product = component.product;
            newComp.quantity_kg = 0.0;

            this.components.push(newComp);
        }
    }

    getTarget(product: Product): number {
        if (product) {
            let fComponent = this.getFormulaComponentByProductId(product.id);
            let bComponent = this.getComponentByProductId(product.id);

            let target = 0.0;

            //check if it is from formula or formula_ancillary
            if (this.formula.indexOf(fComponent) > -1) {
                target = fComponent.ratio * this.quantity_target_kg;
            }
            else {
                target = this.getTotalBaseQuantityInKg() * fComponent.ratio;
            }

            return Toolkit.precision(target, 5);
        }
    }

    getDelta(product: Product): number {
        let fComponent = this.getFormulaComponentByProductId(product.id);
        let bComponent = this.getComponentByProductId(product.id);

        let delta = 0.0;

        //check if it is from formula or formula_ancillary
        if (this.formula.indexOf(fComponent) > -1) {
            delta = bComponent.quantity_kg - fComponent.ratio * this.quantity_target_kg;
        }
        else {
            delta = bComponent.quantity_kg - this.getTotalBaseQuantityInKg() * fComponent.ratio;
        }

        return Toolkit.precision(delta, 5);
    }

    getTotalQuantityInKg() {
        let total = this.getTotalBaseQuantityInKg();
        for (let component of this.components_ancillary) {
            total += component.quantity_kg;
        }

        return Toolkit.precision(total, 5);
    }


    getTotalBaseQuantityInKg() {
        let total = 0.0;
        for (let component of this.components) {
            total += component.quantity_kg;
        }

        return Toolkit.precision(total, 5);
    }

    getFormulaComponentByProductId(productId: string): FormulaComponent {
        let component = undefined;

        for (let item of this.formula) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        if (!component) {
            //find ancillaries
            for (let item of this.formula_ancillary) {
                if (item.product && item.product.id == productId) {
                    component = item;
                    break;
                }
            }
        }

        return component;
    }

    getComponentByProductId(productId: string): BatchComponent {
        let component = undefined;

        for (let item of this.components) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        //if not found, look in ancillaries
        if (!component) {
            for (let item of this.components_ancillary) {
                if (item.product && item.product.id == productId) {
                    component = item;
                    break;
                }
            }
        }

        return component;
    }



    getTotalCost() {
        let total = 0.0;

        //loop through components
        for (let component of this.components) {
            if (component.quantity_kg > 0)
                total += component.quantity_kg * component.cost_per_kg;
        }

        //loop through ancillaries
        for (let component of this.components_ancillary) {
            if (component.quantity_kg > 0)
                total += component.quantity_kg * component.cost_per_kg;
        }

        return Toolkit.precision(total, 2);
    }

    getMinimumAmountTotal() {
        if (this.formula.length == 0)
            return 0.0;


        ////////////////////////////////////////////////
        // Minimum based on Formula
        ////////////////////////////////////////////////

        //find biggest and smallest ratio
        let smallest = this.formula[0];
        let biggest = this.formula[0];
        for (let component of this.formula) {
            if (component.ratio > 0 && smallest.ratio > component.ratio) {
                smallest = component;
            }
            if (biggest.ratio < component.ratio) {
                biggest = component;
            }
        }

        //scaling vs 0.01g of minimum amount
        let formulaScalingFactor = 0.00001 / smallest.ratio;

        let formulaMinimum = 0.0;
        for (let component of this.formula) {
            formulaMinimum += component.ratio * formulaScalingFactor;
        }

        ////////////////////////////////////////////////
        // Minimum based on Consumption
        ////////////////////////////////////////////////

        let consumptionMinimum = 0.0;
        for (let component of this.components) {
            let formulaComponent = this.getFormulaComponentByProductId(component.product.id);

            if (!formulaComponent)
                continue;

            if (formulaComponent.ratio == 0)
                continue;

            let newConsumptionMinimum = component.quantity_kg / formulaComponent.ratio;

            if (newConsumptionMinimum > consumptionMinimum) {
                consumptionMinimum = newConsumptionMinimum;
            }
        }

        return formulaMinimum >= consumptionMinimum ? Toolkit.precision(formulaMinimum, 5) : Toolkit.precision(consumptionMinimum, 5);
    }

    getAncillaries(product_category_id: string) {
        if (this.substrate) {
            //console.log(this.variant.product_type.formula[this.variant.system][this.coat.code][this.substrate.id]);

            let components = undefined;

            if (!this.baseProduct) {
                components = this.product_type.formula[this.system][this.coat.code][this.substrate.id]['components'];
            }

            if (this.baseProduct && this.applicationTechnique) {
                components = this.baseProduct.formula[this.substrate.id][this.applicationTechnique.id]['components'];
            }

            if (components) {
                for (let item of components) {
                    if (item['category']['id'] == product_category_id) {
                        return item['products'];
                    }
                }
            }
        }

        return [];
    }
}

export class BatchComponent {
    public product: Product;
    public quantity_kg: number;
    public tare: number = 0.0;
    public cost_per_kg: number;
}

export class BatchOvershot {
    public timestamp: Date = new Date();
    public product: Product;
    public target_kg: number;
    public actual_kg: number;
    public user: User;
}

export class JobCustomProduct{
    public product:Product;
    public quantity:number;
    public unit_cost:number;
}

export class ProductSku{
    public pack_size: number;
    public code:string;
}

export class Product {
    public id;
    public code: string;
    public code_short: string;
    public kg_conversion: number;
    public name: string;
    public type: ProductType;
    public category: ProductCategory;
    public unit: Unit;
    public available_in: ProductType[] = [];
    public formula: object;
    public tracers: Tracer[] = [];
    public is_kansai:boolean;
    public skus:ProductSku[] =[];
    public manufacturer:string;
    public tinter_class:string;

    hasTracer() {
        return this.tracers && this.tracers.length > 0;
    }

    isAvailableIn(ptype: ProductType): boolean {
        for (let productType of this.available_in) {
            if (!(ptype && productType)) {
                return false;
            }

            if (ptype.id == productType.id) {
                return true;
            }
        }

        return false;
    }

    getApplicationTechniqueIds() {
        let appTechs = new Set();

        if (this.formula) {
            for (let substrate in this.formula) {
                for (let appTech in this.formula[substrate]) {
                    appTechs.add(appTech);
                }
            }
        }

        return Array.from(appTechs);
    }
}

export class Tracer {
    public product: Product;
    public dilutionFactor: number;
}

export class ProductType {
    public id: string;
    public code: string;
    public name: string;
    public logo_url;
    public formula: object;
    public coat_filter: object;
    public available_coats: object;

    public getCoatsByTypeId(coat_type?: string): Coat[] {
        let avCoats = new Set();

        for (let ct_in_dict in this.available_coats) {
            if (coat_type && ct_in_dict != coat_type) {
                continue;
            }

            for (let coat of this.available_coats[ct_in_dict]) {
                avCoats.add(coat);
            }

        }

        return Array.from(avCoats);
    }
}


export class ProductCategory {
    public id: string;
    public name: string;
}

export class InventoryItem {
    public product: Product;
    public unit_cost: number;
    public active:boolean;
}

export class CarManufacturer {
    public id: number;
    public name: string;
    public logo_url: string;

    public models: CarModel[] = [];

    getModelById(id: number) {
        for (let model of this.models) {
            if (model.id == id) {
                return model;
            }
        }

        return undefined;
    }

    sortModelsByName() {
        this.models.sort((a: CarModel, b: CarModel) => {
            if (a.name > b.name) {
                return 1;
            }
            else if (a.name < b.name) {
                return -1;
            }
            else {
                return 0;
            }
        });
    }
}

export class CarModel {
    public id: number;
    public name: string;
    public car_manufacturer: CarManufacturer;
    public is_generic: boolean;
    public consumption: object;
    public estimates: object;

    hasEstimatesData() {
        return this.consumption && Object.keys(this.consumption).length > 0;
    }

    hasEstimatesForPanel(panel: CarPanel): boolean {
        if (this.hasEstimatesData()) {
            return panel.id in this.consumption;
        }

        return false;
    }

    hasEstimatesForSurface(panel: CarPanel, inside: boolean): boolean {
        if (this.hasEstimatesForPanel(panel)) {
            let surface = inside ? 'inside' : 'outside';
            return surface in this.consumption[panel.id];
        }

        return false;
    }


    hasEstimatesForRepairType(panel: CarPanel, inside: boolean, repair_type: RepairType): boolean {
        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForSurface(panel, inside)) {
            return repair_type.id in this.consumption[panel.id][surface];
        }

        return false;
    }

    hasEstimatesForCoat(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat): boolean {
        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForRepairType(panel, inside, repair_type)) {
            return coat.id in this.consumption[panel.id][surface][repair_type.id];
        }

        return false;
    }

    hasEstimatesForApplicationTechnique(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat, appTech: ApplicationTechnique): boolean {
        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForCoat(panel, inside, repair_type, coat)) {
            return appTech.id in this.consumption[panel.id][surface][repair_type.id][coat.id];
        }

        return false;
    }

    hasEstimatesForProductType(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat, appTech: ApplicationTechnique, ptype: ProductType): boolean {
        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForApplicationTechnique(panel, inside, repair_type, coat, appTech)) {
            return ptype.id in this.consumption[panel.id][surface][repair_type.id][coat.id][appTech.id];
        }

        return false;
    }

    getEstimate(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat, appTech: ApplicationTechnique, ptype: ProductType) {
        if (this.hasEstimatesForProductType(panel, inside, repair_type, coat, appTech, ptype)) {
            let surface = inside ? 'inside' : 'outside';
            return this.consumption[panel.id][surface][repair_type.id][coat.id][appTech.id][ptype.id];
        }
    }

    getRepairTypesIdsWithEstimates(panel: CarPanel, inside: boolean) {
        let ids = []

        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForSurface(panel, inside)) {
            for (let id in this.consumption[panel.id][surface]) {
                ids.push(id);
            }
        }

        return ids;
    }

    getCoatIdsWithEstimates(panel: CarPanel, inside: boolean, repair_type: RepairType) {
        let ids = []

        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForRepairType(panel, inside, repair_type)) {
            for (let id in this.consumption[panel.id][surface][repair_type.id]) {
                ids.push(id);
            }
        }

        return ids;
    }

    getApplicationTechniqueIdsWithEstimates(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat) {
        let ids = []

        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForCoat(panel, inside, repair_type, coat)) {
            for (let id in this.consumption[panel.id][surface][repair_type.id][coat.id]) {
                ids.push(id);
            }
        }

        return ids;
    }

    getProductTypeIdsWithEstimates(panel: CarPanel, inside: boolean, repair_type: RepairType, coat: Coat, appTech: ApplicationTechnique) {
        let ids = []

        let surface = inside ? 'inside' : 'outside';
        if (this.hasEstimatesForApplicationTechnique(panel, inside, repair_type, coat, appTech)) {
            for (let id in this.consumption[panel.id][surface][repair_type.id][coat.id][appTech.id]) {
                ids.push(id);
            }
        }

        return ids;
    }
}

export class ApplicationTechnique {
    public id: string;
    public name: string;
    public is_default: string;
}

export class CarPanel {
    public id: string;
    public name: string;
    public is_outside: boolean;
    public surface_in: boolean;
    public surface_out: boolean;
    public default_substrate: Substrate;
}