<div id="klayout-right-top" class="klayout-top">
  <!-- Actions -->
  <div class="kpage-title">
    Manage Users
  </div>

  <div class="kv-spacer"></div>


  <a [routerLink]="['new']">
    <button mat-button class="top-nav-button" color="primary">
      <i class="material-icons">
        add
      </i> New
    </button>
  </a>
</div>
<div id="klayout-right-bottom no-padding">
  <!-- Contents -->
  <div id="container" *ngIf="users">

    <mat-tab-group>
      <mat-tab label="Active">
        <mat-table class="user-table" mat-table [dataSource]="dataSource">
          <!-- Icon -->
          <ng-container matColumnDef="icon">
            <mat-header-cell class="icon-column" *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="icon-column" *matCellDef="let element">
              <i class="material-icons">
                person
              </i>
            </mat-cell>
          </ng-container>


          <!-- Position Column -->
          <ng-container matColumnDef="bodyshop">
            <mat-header-cell *matHeaderCellDef> Bodyshop </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.currentBodyshop.id_custom}} </mat-cell>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="employee_nb">
            <mat-header-cell *matHeaderCellDef> Employee Nb. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.employee_nb}} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> eMail </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button matTooltip="Edit" class="top-nav-button button-narrow" (click)="navigate(element)">
                <i class="material-icons">
                  edit
                </i> Edit
              </button>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <div class="spinner-div" *ngIf="_isRetrieving"><mat-spinner diameter="50"></mat-spinner></div>

      </mat-tab>
      <mat-tab label="Inactive">
        <mat-table class="user-table" mat-table [dataSource]="dataSource_inactive">
          <!-- Icon -->
          <ng-container matColumnDef="icon">
            <mat-header-cell class="icon-column" *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="icon-column" *matCellDef="let element">
              <i class="material-icons">
                person
              </i>
            </mat-cell>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="bodyshop">
            <mat-header-cell *matHeaderCellDef> Bodyshop </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.currentBodyshop.id_custom}} </mat-cell>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="employee_nb">
            <mat-header-cell *matHeaderCellDef> Employee Nb. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.employee_nb}} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> eMail </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button matTooltip="Edit" class="top-nav-button button-narrow" (click)="navigate(element)">
                <i class="material-icons">
                  edit
                </i> Edit
              </button>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        
        <div class="spinner-div" *ngIf="_isRetrieving"><mat-spinner diameter="50"></mat-spinner></div>

      </mat-tab>
    </mat-tab-group>



  </div>
</div>