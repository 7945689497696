import { ColourMatch, VariantCoat } from './../../../model/model';
import { JobService } from './../../../services/job.service';
import { ActivatedRoute, Router } from '@angular/router/';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-colour-match',
  templateUrl: './job-colour-match.component.html',
  styleUrls: ['./job-colour-match.component.css']
})
export class JobColourMatchComponent implements OnInit {
  public cMatch: ColourMatch;
  public vcoat: VariantCoat;

  constructor(public route: ActivatedRoute,
    private router: Router,
    public jobService: JobService) {

    this.route.params.subscribe(params => {
      let found = false;

      for (let cm of this.jobService.currentJob.colours) {
        if (cm.colour.code == params['colourCode']) {
          if (cm.is_active) {
            this.cMatch = cm;
            found = true;
            break;
          }
        }
      }

      if (!found) {
        this.router.navigate(['../../../../'], { relativeTo: this.route });
      }
      else {
        found = false;
        for (let coat of this.cMatch.variant.coats) {
          if (coat.coat.id == params['coatId']) {
            this.vcoat = coat;
            found = true;
            break;
          }
        }

        if (!found)
          this.router.navigate(['../../'], { relativeTo: this.route });
      }
    });
  }

  ngOnInit() {
  }

  job() {
    return this.jobService.currentJob;
  }
}
