import { Bodyshop } from './../../../model/model';
import { ManageBodyshopService } from './../../../services/manage-bodyshop.service';
import { ActivatedRoute, Router } from '@angular/router/';
import { CacheService } from './../../../services/cache.service';
import { DataService } from './../../../services/data.service';
import { ManageUserService } from './../../../services/manage-user.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../../../model/model';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  public users: User[];
  public users_inactive: User[];

  public _isRetrieving = false;

  public dataSource: MatTableDataSource<User>;
  public dataSource_inactive: MatTableDataSource<User>;
  public displayedColumns = ['icon', 'name', 'email', 'employee_nb','bodyshop',  'actions']

  public bodyshops: Bodyshop[];

  constructor(public manageUserService: ManageUserService,
    public manageBodyshopService: ManageBodyshopService,
    private dataService: DataService,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  isChildrenActive() {
    return this.route.snapshot.firstChild != null;
  }

  ngOnInit() {
    this.cacheService.isFullyLoaded.subscribe(state => {
      if (state) {
        this.users = [];
        this.users_inactive = [];

        this._isRetrieving = true;
        this.manageUserService.getUsers().subscribe(users => {
          if (users) {
            this.manageBodyshopService.getBodyshops().subscribe(shops => {
              this.bodyshops = shops;

              for (let user of users) {
                //assign bodyshop
                for(let bshop of shops){
                  if(bshop.id == user.bodyshop_id){
                    user.currentBodyshop = bshop;
                  }
                }

                if (user.is_active)
                  this.users.push(user)
                else
                  this.users_inactive.push(user)
              }
              this.updateTable();
              this._isRetrieving = false;
            }, error => {
              console.log(error);
              this._isRetrieving = false;
            });
          }

        }, error => {
          console.log(error);
          this._isRetrieving = false;
        });
      }
    });
  }

  navigate(user: User) {
    this.router.navigate(['manage', 'users', user.id]);
  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource_inactive = new MatTableDataSource(this.users_inactive);
  }

}
