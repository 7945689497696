<div id="job-details" *ngIf="cMatch">
  <div id="job-detail-fields">
    <div class="job-detial-fields-group">
      <div class="heading-h3 field-section-header">
        Colour: {{cMatch.colour.code}} - {{cMatch.colour.name}}
      </div>
    </div>

    <div class="button-row" *ngIf="!cMatch.variant">
      <div class="big-button" (click)="addVariantToColour()">
        <i class="material-icons">
          search
        </i>
        <div>Search</div>
      </div>

      <div class="spacer-buttons"></div>

      <div class="big-button" (click)="addEmptyVariantToColour()">
        <i class="material-icons">
          add
        </i>
        <div>Create</div>
      </div>
    </div>

    <ng-container *ngIf="cMatch.variant">
      <ng-container *ngIf="!cMatch.variant['isNew']">
        <app-variant-detail [variant]="cMatch.variant" [hideHeader]="true"></app-variant-detail>
      </ng-container>
      <ng-container *ngIf="cMatch.variant['isNew']">
        <app-new-variant [colour]="cMatch.colour" (onVariantCreated)="updateVariant($event)"></app-new-variant>
      </ng-container>
    </ng-container>




  </div>


  <div>
   
  </div>
</div>