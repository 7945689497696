import { Toolkit } from './../../../toolkit/toolkit';
import { InventoryService } from './../../services/inventory.service';
import { MatTableDataSource } from '@angular/material';
import { ProductService } from './../../services/product.service';
import { FormulaComponent, ProductType, Product } from './../../model/model';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-formula-editor',
  templateUrl: './formula-editor.component.html',
  styleUrls: ['./formula-editor.component.css']
})
export class FormulaEditorComponent implements OnInit {
  @Input() formula: FormulaComponent[];
  @Input() productType: ProductType;

  @Output() onUpdate = new EventEmitter<FormulaComponent[]>();

  public _newFormula: FormulaComponent[];
  public _isNewFormula: boolean;
  public _isDone:boolean = false;
  public toolkit: Toolkit = new Toolkit();

  public formulaDataSource: MatTableDataSource<FormulaComponent>;
  public formulaTableColumns = ['product_code_short', 'product_name', 'ratio', 'amount_kg']

  constructor(public productService: ProductService,
    public inventoryService: InventoryService) {
    
  }

  ngOnInit() {
    this._newFormula = [];
    this._isNewFormula = (this.formula && this.formula.length > 0);

    if (this.formula) {
      for (let component of this.formula) {
        let newComponent = new FormulaComponent();
        newComponent.product = component.product;
        newComponent.ratio = component.ratio;
        newComponent.amount_kg = component.amount_kg;

        if (newComponent.amount_kg) {
          newComponent['amount_g'] = Toolkit.precision(newComponent.amount_kg * 1000, 2);
        }

        this._newFormula.push(newComponent);
      }
    }

    this.updateTable();
  }

  updateTable() {
    if (!this._isDone && this._newFormula.length == 0 || (this._newFormula.length > 0 && this._newFormula[this._newFormula.length - 1].product)) {
      //add empty component
      this._newFormula.push(new FormulaComponent());
    }


    this.formulaDataSource = new MatTableDataSource(this._newFormula);
  }

  containsProduct(product: Product) {
    for (let component of this._newFormula) {
      if (component.product) {
        if (component.product.id == product.id) {
          return true;
        }
      }
    }

    return false;
  }

  addProduct(product: Product, component: FormulaComponent) {
    if (product && product instanceof Product) {
      component.product = product;
      component.amount_kg = 0.0;

      this.recalculate();
      this.updateTable();
    }
  }

  deleteComponent(component: FormulaComponent) {
    this._newFormula.splice(this._newFormula.indexOf(component), 1);
    this.recalculate();
    this.updateTable();
  }

  submitQuantity() {
    for(let component of this._newFormula){
      component.amount_kg = Toolkit.precision(component['amount_g'] / 1000, 5);
    }
    
    this.recalculate();

    let cleanFormula: FormulaComponent[] = [];
    //clean empty products
    for(let component of this._newFormula){
      if(component.product && component.amount_kg && component.amount_kg > 0){
        cleanFormula.push(component);
      }
    }
    this.onUpdate.emit(cleanFormula);

  }

  recalculate() {
    for (let cmp of this._newFormula) {
      cmp.ratio = Toolkit.ratio(cmp, this._newFormula);
    }
  }

  done(){
    let cleanFormula: FormulaComponent[] = [];
    //clean empty products
    for(let component of this._newFormula){
      if(component.product && component.amount_kg && component.amount_kg > 0){
        cleanFormula.push(component);
      }
    }

    this._newFormula = cleanFormula;
    this._isDone = true;
    this.recalculate();    
    this.updateTable();
    this.onUpdate.emit(this._newFormula);
    console.log(this);
  }

}
