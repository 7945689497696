import { ActivatedRoute, Router } from '@angular/router/';
import { AuthService } from './../../services/auth.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    private unauthLinks = [
        '/login',
        '/register',
        '/password-reset',
        '/forgot-password'
    ];

    constructor(public authService: AuthService,
        public route: ActivatedRoute,
        public router: Router) {
    }

    isLoggedIn() {
        if (!this.authService.isLoggedIn()) {
            let url = this.router.routerState.snapshot.url;
            
            if(url){
                let found = false;
                for(let link of this.unauthLinks){
                    if(url.startsWith(link)){
                        found = true;
                        break;
                    }
                }

                if(!found){
                    this.router.navigate(['/login', { redir: url }]);
                }
            }
        }
    }
}
