import { UserService } from './../../services/user.service';
import { InventoryService } from './../../services/inventory.service';
import { VariantService } from './../../services/variant.service';
import { Router } from '@angular/router';
import { Variant, Job } from './../../model/model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-variant-detail',
  templateUrl: './variant-detail.component.html',
  styleUrls: ['./variant-detail.component.css']
})
export class VariantDetailComponent implements OnInit {
  @Input() variant: Variant;
  @Input() hideHeader: boolean;
  @Input() ignoreInventory: boolean;

  //parameter and event for the primary button (usually select)
  @Input() primaryButtonLabel: string;
  @Output() onPrimaryAction = new EventEmitter<Variant>();

  //parameter and event for the secondary button
  @Input() secondaryButtonLabel: string;
  @Output() onSecondaryAction = new EventEmitter<Variant>();



  constructor(private router: Router,
    private variantService: VariantService,
    public inventoryService: InventoryService,
    public userService: UserService) { }

  ngOnInit() {
    if (!this.variant.isWithRatios()) {
      this.variantService.getVariant(this.variant.id).subscribe(variant => {
        this.variant = variant
      });
    }
  }

  primaryAction(variant: Variant) {
    this.onPrimaryAction.emit(variant);
  }

  secondaryAction(variant: Variant) {
    this.onSecondaryAction.emit(variant);
  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }

}
