import { DialogPanelSelectForSimpleCoatComponent } from './../../dialogs/dialog-panel-select-for-simple-coat/dialog-panel-select-for-simple-coat.component';
import { BatchService } from './../../../services/batch.service';
import { DataService } from './../../../services/data.service';
import { VariantService } from './../../../services/variant.service';
import { VariantCoat, Batch, Coat } from './../../../model/model';
import { ActivatedRoute, Router } from '@angular/router/';
import { JobService } from './../../../services/job.service';
import { Component, OnInit } from '@angular/core';
import { ColourMatch } from '../../../model/model';
import { CoatService } from '../../../services/coat.service';
import { Toolkit } from '../../../../toolkit/toolkit';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-job-colour-mix',
  templateUrl: './job-colour-mix.component.html',
  styleUrls: ['./job-colour-mix.component.css']
})
export class JobColourMixComponent implements OnInit {
  public cMatch: ColourMatch;
  public vcoat: VariantCoat;

  public _selectedBatch: Batch;
  public _selection;
  public toolkit = new Toolkit();

  constructor(public route: ActivatedRoute,
    private router: Router,
    public jobService: JobService,
    public variantService: VariantService,
    public coatService: CoatService,
    public dataService: DataService,
    public dialog: MatDialog,
    public batchService: BatchService) {

    this.route.params.subscribe(params => {
      let found = false;

      for (let cm of this.jobService.currentJob.colours) {
        if (cm.colour.code == params['colourCode']) {
          if (cm.is_active) {
            this.cMatch = cm;
            found = true;
            break;
          }
        }
      }

      if (!found) {
        this.router.navigate(['../../../../'], { relativeTo: this.route });
      }
      else {
        found = false;
        for (let coat of this.cMatch.variant.coats) {
          if (coat.coat.id == params['coatId']) {
            this.vcoat = coat;
            found = true;
            break;
          }
        }

        if (!found)
          this.router.navigate(['../../'], { relativeTo: this.route });
      }
    });
  }

  ngOnInit() {
  }

  job() {
    return this.jobService.currentJob;
  }

  createBatchForCoat(colour: ColourMatch, coat: Coat) {
    if (this.job().getSelectedPanels().length > 0) {
      let dialogRef = this.dialog.open(DialogPanelSelectForSimpleCoatComponent, {
        data: {
          'job': this.job(),
          'coat': coat,
          'product_type': colour.variant.product_type
        }
      });

      dialogRef.componentInstance.onSelect.subscribe(result => {
        this.createBatch(colour, coat, result['total_kg'], result);
      });

      dialogRef.componentInstance.onCancel.subscribe(result => {
        if (result) {
          this.createBatch(colour, coat, 0.1);
        }
      });
    }
    else {
      this.createBatch(colour, coat, 0.1);
    }
  }

  createBatch(colour: ColourMatch, coat: Coat, quantity_target_kg: number, selection?:any) {
    let formula;

    for (let ct of colour.variant.coats) {
      if (ct.coat.id == coat.id) {
        formula = ct.formula;
      }
    }

    let batch = new Batch(formula, colour.variant.product_type, coat, colour.variant.system);
    batch.quantity_target_kg = quantity_target_kg;
    batch.job_id = this.job().id;
    batch.colour_id = colour.colour.id;
    batch.colourMatch_id = colour.id;
    batch.system = colour.variant.system;
    batch.applicationTechnique = this.coatService.default_applicaitonTechnique;
    batch.panelSelection = selection;

    this.job().batches.push(batch);
    this._selectedBatch = batch;
  }

  removeBatch(batch:Batch){
    if(!batch.id && this.job().batches.includes(batch)){
      this.job().batches.splice(this.job().batches.indexOf(batch), 1);
    }
  }



  selectBatch(batch) {
    if (batch == this._selectedBatch) {
      this._selectedBatch = undefined;
    }
    else {
      this._selectedBatch = batch;
    }
  }

  splitBatch(data, colourMatch_id, colour_id, variant_id, is_matchingBatch) {
    this.dataService.blockUIforLoad = true;

    let detailData = {
      'job_id': this.job().id
    }

    if (colourMatch_id)
      detailData['colourMatch_id'] = colourMatch_id;

    if (colour_id)
      detailData['colour_id'] = colour_id;

    if (is_matchingBatch)
      detailData['is_matchingBatch'] = is_matchingBatch;

    if (variant_id)
      detailData['variant_id'] = variant_id;

    if (data['batch']['system'])
      detailData['system'] = data['batch']['system'];


    //call server
    this.batchService.splitBatch(data['batch'], data['split_kg'], detailData).subscribe(batches => {
      //remove the batch from the job
      this.job().batches.splice(this.job().batches.indexOf(data['batch']), 1);

      //add new batches
      this.job().batches.push(batches[0]);
      this.job().batches.push(batches[1]);

      this._selectedBatch = batches[0];
      this.dataService.blockUIforLoad = false;
    }, error => {
      this.dataService.blockUIforLoad = false;
      throw error;
    });
  }
}
