<div id="klayout-container" *ngIf="userService.initialised | async">
  <div id="klayout-right">
    <div id="klayout-right-top" class="klayout-top">
      <!-- Actions -->
      <!--
      <button mat-button class="top-nav-button" (click)="log()">
          Log
        </button>
        <button mat-button class="top-nav-button" color="primary" *ngIf="isEdit" (click)="saveUser()">
          <i class="material-icons">
            save
          </i> Save
        </button>
        <button mat-button class="top-nav-button" *ngIf="!isEdit" (click)="isEdit = true;">
          <i class="material-icons">
            edit
          </i> Edit
        </button>
        <button mat-button class="top-nav-button" *ngIf="isEdit" (click)="isEdit = false;">
          <i class="material-icons">
            clear
          </i>
        </button>-->
    </div>
    <div id="klayout-right-bottom">
      <!-- Contents -->

      <table>
        <tr>
          <td>Name</td>
          <td>{{user.name}}</td>
        </tr>
        <tr>
          <td>eMail</td>
          <td>{{user.email}}</td>
        </tr>
        <tr>
          <td>Bodyshop</td>
          <td><span class="noedit" matTooltip="To change your bodyshop, contact your administrator.">{{user.currentBodyshop.name}}</span></td>
        </tr>
      </table>
    </div>
  </div>
</div>