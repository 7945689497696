<div id="job-details" *ngIf="job()">
  <div id="job-detail-fields">

    <!-- Vehicle Info -->
    <div class="job-detial-fields-group">
      <div class="heading-h3 field-section-header">
        Vehicle
      </div>
      <div class="job-detial-fields-group-fields">
        <!-- 1st Line -->
        <div class="flex-row">

          <!-- Empty field for glitch in Chrome -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium invisible">
            <mat-label>Make</mat-label>
            <mat-select required (selectionChange)="carManufacturerSelected()" [(ngModel)]="job().car_manufacturer"
              [disabled]="!job().isEditable() || job().colours.length > 0" #carManufacturerSelect>
              <mat-option *ngFor="let man of vehicleService.carManufacturers" [value]="man">
                {{man.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Car Manufacturer -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Make</mat-label>
            <mat-select required (selectionChange)="carManufacturerSelected()" [(ngModel)]="job().car_manufacturer"
              [disabled]="!job().isEditable() || job().colours.length > 0" #carManufacturerSelect>
              <mat-option *ngFor="let man of vehicleService.carManufacturers" [value]="man">
                {{man.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Car Model Year -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-narrow">
            <mat-label>Year</mat-label>
            <mat-select required [(ngModel)]="job().car_year" [disabled]="!job().isEditable()">
              <mat-option *ngFor="let year of availableYears" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Car Model SELECTION -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Model</mat-label>
            <mat-select required [(ngModel)]="job().car_model" #carModelSelect [disabled]="!job().isEditable() || !job().car_manufacturer"
              (selectionChange)="getCarModelDetails($event)">
              <ng-container *ngIf="job().car_manufacturer && job().car_manufacturer.models.length > 0">
                <mat-option *ngFor="let model of job().car_manufacturer.models" [value]="model">
                  {{model.name}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Car Model NAME -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium" *ngIf="job().car_model && job().car_model.is_generic">
            <mat-label>Model Name</mat-label>
            <input required matInput [(ngModel)]="job().car_model_text" [disabled]="!job().isEditable() || !job().car_manufacturer || !job().car_model">
          </mat-form-field>
        </div>


        <!-- 2nd Line -->
        <div class="flex-row">
          <!-- Car Model Country -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Built in</mat-label>
            <mat-select required [(ngModel)]="job().car_country" #carCountrySelect [disabled]="!job().isEditable()">
              <mat-option *ngFor="let country of countryService.countries" [value]="country">
                {{country.name_common}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- License Plate -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>License Plate</mat-label>
            <input matInput [(ngModel)]="job().car_license_plate" [disabled]="!job().isEditable()">
          </mat-form-field>

          <!-- Chassis Number -->
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Chassis Number</mat-label>
            <input matInput [(ngModel)]="job().car_chassis_number" [disabled]="!job().isEditable()">
          </mat-form-field>
        </div>

        <!-- Colours -->
        <mat-form-field appearance="outline" class="job-field-margin">
          <mat-label>Colours</mat-label>

          <div class="flex-row flex-wrap">
            <mat-chip-list required #colourChipList [disabled]="!job().car_manufacturer || _isCreatingColourMatch">
              <ng-container *ngFor="let colour of job().getActiveColours()">
                <mat-chip>
                  {{colour.colour.code}} - {{colour.colour.name}}
                  <mat-icon (click)="deleteColour(colour)" matChipRemove *ngIf="job().isEditable()">cancel</mat-icon>
                </mat-chip>
              </ng-container>

            </mat-chip-list>
            <input type="text" class="colours-autocomplete-input" #colourInput matInput [matAutocomplete]="colourAutoComplete"
              [matChipInputFor]="colourChipList" [disabled]="!job().isEditable() || !job().car_manufacturer || _isCreatingColourMatch">
          </div>
        </mat-form-field>
        <mat-autocomplete #colourAutoComplete="matAutocomplete" (optionSelected)="addColour($event.option.value); colourInput.value=''; "
          autoActiveFirstOption>
          <ng-container *ngFor="let colour of newJobColour_selection">
            <ng-container *ngIf="!job().containsColour(colour)">
              <mat-option [value]="colour" class="kansai-list-item" *ngIf="colour.code.toLowerCase().indexOf(colourInput.value.trim().toLowerCase()) > -1 || colour.name.toLowerCase().indexOf(colourInput.value.trim().toLowerCase()) > -1">
                {{colour.code}} - {{colour.name}}
              </mat-option>
            </ng-container>
          </ng-container>

          <mat-option value="__not-found__">
            Colour not found
          </mat-option>
        </mat-autocomplete>

      </div>
    </div>

    <!-- Job Info -->
    <div class="job-detial-fields-group">
      <div class="heading-h3 field-section-header">
        Job
      </div>
      <div class="job-detial-fields-group-fields">
        <div class="flex-row">
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Job ID</mat-label>
            <input matInput [(ngModel)]="job().id_custom" [disabled]="!job().isEditable()">
          </mat-form-field>
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Benchmark Amount</mat-label>
            <div matPrefix>{{userService.currentUser.tenant.currency.iso_4217.toUpperCase()}} &nbsp;</div>
            <input matInput type="number" min="0" [(ngModel)]="job().benchmark_amount" [disabled]="!job().isEditable()">
          </mat-form-field>
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Customer ID</mat-label>
            <input matInput [(ngModel)]="job().customer_id" [disabled]="!job().isEditable()">
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Customer Phone</mat-label>
            <input matInput [(ngModel)]="job().customer_phone" [disabled]="!job().isEditable()">
          </mat-form-field>
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>Customer Name</mat-label>
            <input matInput [(ngModel)]="job().customer_name" [disabled]="!job().isEditable()">
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="job-field-margin">
          <mat-label>Comments</mat-label>
          <textarea class="job-field-high" matInput [(ngModel)]="job().comments" [disabled]="!job().isEditable()"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>


  <div id="job-detail-panels">
    <div class="job-detial-fields-group">
      <div class="heading-h3 field-section-header">
        Panels
      </div>
      <ng-container>
        <div class="panel-placeholder" *ngIf="job().colours.length == 0">
          Please select colours...
        </div>
      </ng-container>
      <ng-container *ngIf="job().car_model && job().colours && job().colours.length > 0">
        <app-panel-selector id="panel-selector" [job]="job()"></app-panel-selector>
      </ng-container>

    </div>
  </div>
</div>