import { PanelOptionsComponent } from './../panel-options/panel-options.component';
import { MatDialog } from '@angular/material';
import { Job, JobPanel, ColourMatch, CarModel } from './../../model/model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-panel-selector',
  templateUrl: './panel-selector.component.html',
  styleUrls: ['./panel-selector.component.css']
})
export class PanelSelectorComponent implements OnInit {
  @Input() job: Job;
  @Input() carModel: CarModel;
  @Input() highlightColour: ColourMatch;
  @Input() orientation: number;
  @Input() showPanelList: boolean = true;


  constructor(
    public dialog: MatDialog) { }

  ngOnInit() {
  }

  clickOnPanel(panelId: string, element?) {
    if (this.job && this.job.car_model) {
      let dialogRef = this.dialog.open(PanelOptionsComponent, {
        data: {
          panel: this.getJobPanelByPanelId(panelId),
          job: this.job
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //do something
      });
    }

  }

  getJobPanelByPanelId(panelId: string): JobPanel {
    //console.log("getJobByPanelId(" + panelId + ")")

    let panel = undefined;

    for (let jPanel of this.job.panels) {
      if (jPanel.panel.id == panelId) {
        panel = jPanel;
        break;
      }
    }

    return panel;
  }
}
