import { InventoryService } from './../../services/inventory.service';
import { FormulaComponent, VariantCoat } from './../../model/model';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formula-detail',
  templateUrl: './formula-detail.component.html',
  styleUrls: ['./formula-detail.component.css']
})
export class FormulaDetailComponent implements OnInit, OnChanges {
  @Input() coat: VariantCoat
  @Output() onNavigated = new EventEmitter<boolean>();

  public formulaDataSource: MatTableDataSource<FormulaComponent>;
  public formulaTableColumns = ['product_code', 'product_name', 'ratio']

  constructor(private router:Router,
  public inventoryService:InventoryService) { }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges){
    if (this.coat) {
      this.formulaDataSource = new MatTableDataSource(this.coat.formula);
    }
  }

  getChartData(){
    let data = [];
    for(let component of this.coat.formula){
      if(!component.product)
        continue;

      data.push({
        'name': component.product.name,
        'value': component.ratio * 100
      });
    }

    return data;
  }
}
