import { Toolkit } from './../../toolkit/toolkit';
import { Bodyshop, Variant, VariantCoat, ProductCategory } from './../model/model';
import { ProductService } from './product.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/distinctUntilChanged';
import { Observable } from 'rxjs/Observable';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { InventoryItem } from '../model/model';

@Injectable()
export class InventoryService {
  private api_endpoint = '/inventory';
  private full_api_url;

  //public inventory = [];
  public inventory: InventoryItem[] = [];
  public bodyshop: Bodyshop;
  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  private inventoryMap = {};

  constructor(public dataService: DataService,
    public userService: UserService,
    public productService: ProductService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    //wait until the user is initialised
    this.userService.initialised.distinctUntilChanged().subscribe(state => {
      if (state) {
        this.getInventory(this.userService.currentUser.currentBodyshop.id).subscribe(inv => {
          this.inventory = inv;

          this.lastRefresh = new Date();
          this.initialised.next(true);
        },
        error => {
          console.log(error);
        });
      }
    });
  }

  getInventory(bodyshopId:number):Observable<InventoryItem[]>{
    let requestUrl = this.full_api_url + "/bodyshop/" + bodyshopId;
    
    return this.dataService.getRequest(requestUrl).map(response => {
      let inv:InventoryItem[];

      if(response['data']){
        inv = this.buildInventory(response['data']);
      }

      return inv;
    });
  }

  buildInventory(response):InventoryItem[]{
    let inv:InventoryItem[] = [];

    for (let entry of response) {
      let invItem = this.buildInventoryItem(entry);
      
      if(invItem)
        inv.push(invItem);
    }

    return inv;
  }

  buildInventoryItem(response):InventoryItem{
    let invItem = new InventoryItem();
    invItem.product = this.productService.getProductById(response['product']);
    invItem.unit_cost = response['unit_cost'];
    invItem.active = true;

    return invItem;
  }

  getByProductId(productId: string): InventoryItem {
    let ret_pt = null;

    for (let item of this.inventory) {
      if (item.product && item.product.id == productId) {
        ret_pt = item;
      }
    }

    return ret_pt;
  }

  getProductsByCategory(category:ProductCategory){
    let result = []

    for(let item of this.inventory){
      if(item.product && item.product.category.id == category.id){
        result.push(item.product);
      }
    }

    return result;
  }

  updateInventory(bodyshopId:number, itemsUpdate:InventoryItem[], itemsAdd:InventoryItem[], itemsDelete:InventoryItem[]): Observable<InventoryItem[]> {
    let requestUrl = this.full_api_url + "/bodyshop/" + bodyshopId;
    let requestBody = {};

    //update
    if(itemsUpdate.length > 0){
      requestBody['update'] = [];

      for(let item of itemsUpdate){
        requestBody['update'].push({
          'product': item.product.id,
          'unit_cost': item.unit_cost
        });
      }
    }

    //add
    if(itemsAdd.length > 0){
      requestBody['add'] = [];

      for(let item of itemsAdd){
        requestBody['add'].push({
          'product': item.product.id,
          'unit_cost': item.unit_cost
        });
      }
    }

    //delete
    if(itemsDelete.length > 0){
      requestBody['delete'] = [];

      for(let item of itemsDelete){
        requestBody['delete'].push({
          'product': item.product.id
        });
      }
    }

    return this.dataService.patchRequest(requestUrl, requestBody).map(respone => {
      return this.buildInventory(respone['data']);
    });
  }

  updateValues(oldInventory:InventoryItem[], newInventory:InventoryItem[]){
    //update existing items and add new items
    for(let newItem of newInventory){
      let found = false;

      for(let oldItem of oldInventory){
        if(newItem.product.id == oldItem.product.id){
          found = true;
          oldItem.unit_cost = newItem.unit_cost;
          break;
        }
      }

      if(!found){
        oldInventory.push(newItem);
      }
    }

    //remove items that were deleted
    for(let oldItem of oldInventory){
      let found = false;

      for(let newItem of newInventory){
        if(newItem.product.id == oldItem.product.id){
          found = true;
          break;
        }
      }

      if(!found){
        oldInventory.splice(oldInventory.indexOf(oldItem), 1);
      }
    }
  }



  allVariantComponentsInInventory(variant: Variant) {
    let allAvailable = true;

    for (let coat of variant.coats) {
      if (!this.allFormulaComponentsInInventory(coat)) {
        allAvailable = false;
        break;
      }
    }

    return allAvailable;
  }

  allFormulaComponentsInInventory(coat: VariantCoat) {
    let allAvailable = true;

    for (let component of coat.formula) {
      if (this.getByProductId(component.product.id) == null) {
        allAvailable = false;
        break;
      }
    }


    return allAvailable;
  }

  costForFormula(coat: VariantCoat, kg: number) {
    let total = 0.0;
    //this.normalise(formula, kg);

    for (let component of coat.formula) {
      if (!this.getByProductId(component.product.id)) {
        return 0;
      }
      else {
        total +=
          this.getByProductId(component.product.id).unit_cost
          * component.product.kg_conversion
          * component.ratio;
      }
    }

    return Toolkit.precision(total * kg, 5);
  }

  costForVariant(variant: Variant, kg: number) {
    let total = 0;

    for (let coat of variant.coats) {
      total += this.costForFormula(coat, kg);
    }

    return total;
  }
}
