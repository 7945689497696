import { CarManufacturer } from './../../model/model';
import { ColourService } from './../../services/colour.service';
import { VehicleService } from './../../services/vehicle.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Colour } from '../../model/model';

@Component({
  selector: 'app-new-colour',
  templateUrl: './new-colour.component.html',
  styleUrls: ['./new-colour.component.css']
})
export class NewColourComponent implements OnInit {
  @Input() carManufacturer;
  @Input() existingColours:Colour[];

  @Output() onColourCreation = new EventEmitter<Colour>();
  @Output() onCancel = new EventEmitter<boolean>();

  public colours: Colour[];

  constructor(public vehicleService: VehicleService,
    public colourService: ColourService) {
    this.log();
  }

  ngOnInit() {
    console.log(this);
    if (this.existingColours && this.existingColours.length > 0) {
      this.colours = this.existingColours;
    }
    else {
      this.carManufacturerChanged(this.carManufacturer);
    }

  }

  close(){
    this.onCancel.emit(true);
  }

  save(code:string, name:string){
    let colour = new Colour();
    colour.carManufacturer = this.carManufacturer;
    colour.code = this.removeWhiteSpace(code);
    colour.name = name.trim();

    this.colourService.crateNewColour(colour).subscribe(
      response => {
        if(response){
          let newColour = this.colourService.buildColour(response);
          this.onColourCreation.emit(newColour);
          console.log(newColour);
        }
    }, error => {
      console.log(error);
    });
  }

  removeWhiteSpace(str:string){
    let tmp_str = str.trim();
    while(tmp_str.includes(" ")){
      tmp_str = tmp_str.trim().replace(" ", "");
    }

    return tmp_str;
  }

  carManufacturerChanged(cman) {
    if (cman)
      this.colours = [];

    this.colourService.getColours(this.carManufacturer).subscribe(clrs => {
      this.colours = clrs;

      this.colours.sort((a:Colour, b:Colour) => {
        if(a.code < b.code)
          return -1;
        if(a.code > b.code)
          return 1;
        return 0;
      });
    });
  }

  exists(colourCodeInput) {
    if (!this.colours)
      return false;

    for (let c of this.colours) {
      if (this.removeWhiteSpace(c.code).toLowerCase() == this.removeWhiteSpace(colourCodeInput.toLowerCase()))
        return c;
    }
  }

  similarColours(colourCodeInput: string, colourNameInput: string): Colour[] {
    let cls: Colour[] = [];

    for (let c of this.colours) {
      if (this.removeWhiteSpace(colourCodeInput.toLowerCase()) == this.removeWhiteSpace(c.code)) {
        cls.unshift(c);
      }
      else if ((this.removeWhiteSpace(colourCodeInput).length != 0 && this.removeWhiteSpace(c.code.toLowerCase()).indexOf(this.removeWhiteSpace(colourCodeInput).toLowerCase()) >= 0) || (this.removeWhiteSpace(colourNameInput).length != 0 && this.removeWhiteSpace(c.name.toLowerCase()).indexOf(this.removeWhiteSpace(colourNameInput).toLowerCase()) >= 0)) {
        cls.push(c);
      }
    }

    return cls;
  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }

}
