import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class DataService {
  private api_url = 'https://bodyshop.kansaipaint.com/api'
  //private api_url = 'http://localhost:5000/api';

  public blockUIforLoad = false;

  constructor(private http: HttpClient) { }

  getUrl() {
    return this.api_url;
  }

  getRequest(url: string) {
    if (!environment.production) {
      console.log("GET " + url);
    }
    return this.http.get(url);
  }

  postRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log("POST " + url);
      console.log(requestBody);
    }
    return this.http.post(url, requestBody);
  }

  putRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log("PUT " + url);
      console.log(requestBody);
    }
    return this.http.put(url, requestBody);
  }

  deleteRequest(url: string) {
    if (!environment.production) {
      console.log("DELETE " + url);
    }
    return this.http.delete(url);
  }

  patchRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log("PATCH " + url);
      console.log(requestBody);
    }
    return this.http.patch(url, requestBody);
  }
}
