<div class="coat-container" *ngIf="vCoat.history">
  <div class="batch-container">
    <button mat-stroked-button color="primary" class="add-batch" (click)="start()" *ngIf="!colourMatch.batches_dev || !colourMatch.batches_dev[coat.id]" [disabled]="!job.isEditable()">Start</button>

    <div class="mix-assistant-container">
      <ng-container *ngIf="colourMatch.variant.getCoatByCoatId(coat.id).history">
        <ng-container *ngFor="let item of colourMatch.variant.getCoatByCoatId(coat.id).history">
          <div class="batch-card" (click)="log(item.batch_test); _selectedBatch = item.batch_test;">
            <div class="batch-card-amount">
              Test {{item.f_index | number:'2.0-0'}}
            </div>
            <div class="batch-card-details">
              Source
            </div>
          </div>

          <i class="material-icons">
            arrow_downward
          </i>
        </ng-container>
      </ng-container>

      <div class="batch-card" *ngIf="colourMatch.batches_dev && colourMatch.batches_dev[coat.id]" (click)="_selectedBatch = colourMatch.batches_dev[coat.id];">
        <div class="batch-card-amount">
          Dev
        </div>
        <div class="batch-card-details">
          {{toolkit.precision(colourMatch.batches_dev[coat.id].getTotalQuantityInKg() * 1000, 2) | number:'1.2-2'}}g
        </div>
      </div>
    </div>
  </div>

  <div class="mix-assistant-container" *ngIf="colourMatch.batches_dev && colourMatch.batches_dev[coat.id]">
    <app-mixer [batch]="colourMatch.batches_dev[coat.id]" [baseOnly]="true" [isFormulaEditable]="true" (onCreate)="changeDevelopmentBatch($event)"
      (onSplit)="splitDevBatch($event)" *ngIf="_selectedBatch == colourMatch.batches_dev[coat.id]"></app-mixer>

    <ng-container *ngIf="colourMatch.variant.getCoatByCoatId(coat.id).history">
      <ng-container *ngFor="let histItem of colourMatch.variant.getCoatByCoatId(coat.id).history">
        <app-mixer [batch]="histItem.batch_test" [isSplittable]="false" *ngIf="_selectedBatch == histItem.batch_test"></app-mixer>
      </ng-container>
    </ng-container>
  </div>

</div>