import { User } from './../../model/model';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  public user: User;

  constructor(public userService: UserService) {
    this.userService.initialised.distinctUntilChanged().subscribe(state => {
      this.user = this.userService.currentUser;
    });
  }

  ngOnInit() {
  }

}
