<div id="klayout-right-top" class="klayout-top">
  <!-- Actions -->
  <div class="kpage-title" *ngIf="bodyshop">
    Inventory: {{bodyshop.name}}
  </div>

  <div class="kv-spacer"></div>
  <button *ngIf="isEdit" mat-button (click)="editDiscard()">
    <i class="material-icons">
      clear
    </i> Discard Changes
  </button>
  <button *ngIf="isEdit" mat-button color="primary" (click)="save()">
    <i class="material-icons">
      done
    </i> Save Changes
  </button>
  <button *ngIf="!isEdit" mat-button (click)="editStart()">
    <i class="material-icons">
      edit
    </i> Edit
  </button>
</div>
<div id="klayout-right-bottom" class="no-padding">
  <!-- Contents -->
  <div class="table-container">
    <mat-tab-group>
      <mat-tab *ngFor="let ptype of productService.productTypes">
        <ng-template mat-tab-label>
          {{ptype.name}}
        </ng-template>

        <mat-table *ngIf="inventoryDataSources" id="inventory-table" [dataSource]="inventoryDataSources[ptype.code]">
          <!-- Column -->
          <ng-container matColumnDef="active">
            <mat-header-cell class="table-column-active" *matHeaderCellDef>
              <mat-checkbox color="primary" [checked]="isAllActive(inventoryDataSources[ptype.code])" [indeterminate]="isIndeterminateActive(inventoryDataSources[ptype.code])" [disabled]="!isEdit" (change)="changeAllSelection(inventoryDataSources[ptype.code], $event.checked)"></mat-checkbox>
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-active" *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="element.active" [disabled]="!isEdit" (change)="element.active = $event.checked;"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_type">
            <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
              Type
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
              {{element.product.type.code}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_category">
            <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
              Category
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
              {{element.product.category.name}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_code">
            <mat-header-cell class="table-column-product-code" *matHeaderCellDef>
              Code
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-code" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.code}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_code_short">
            <mat-header-cell class="table-column-product-code-short" *matHeaderCellDef>
              Code Short
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-code-short" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.code_short}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_name">
            <mat-header-cell class="table-column-product-name" *matHeaderCellDef>
              Name
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-name" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.name}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="unit">
            <mat-header-cell class="table-column-quantity" *matHeaderCellDef> Unit </mat-header-cell>
            <mat-cell class="cf-reset table-column-quantity" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.unit.id}} - {{element.product.unit.name_singular}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="unit_cost">
            <mat-header-cell class="table-column-unit-value" *matHeaderCellDef>
              Unit Cost <ng-container *ngIf="userService.initialised | async">({{userService.currentUser.tenant.currency.iso_4217}})</ng-container>
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-unit-value" *matCellDef="let element">
              <input *ngIf="isEdit" type="number" min="0" matInput [(ngModel)]="element.unit_cost" class="unit-cost-input">
              <ng-container *ngIf="!isEdit">
                {{element.unit_cost | number:'1.2-2' }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="operations">
            <mat-header-cell class="table-column-operations" *matHeaderCellDef> Operations </mat-header-cell>
            <mat-cell class="cf-reset table-column-operations" *matCellDef="let element">
              <button *ngIf="!element.isEdited && !element.isLoading" mat-button (click)="editRow(element)">Edit</button>
              <button *ngIf="element.isEdited" mat-button color="primary" (click)="cancelEditRow(element)">Cancel</button>
              <div class="loading-spinner">
                <mat-spinner *ngIf="element.isLoading" diameter="20"></mat-spinner>
              </div>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="inventoryTableColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: inventoryTableColumns;" [class.not-active]="!row.active"></mat-row>
        </mat-table>

      </mat-tab>


      <!-- CUSTOM PRODUCTS -->
      <mat-tab *ngIf="inventoryDataSources && inventoryDataSources['__custom__']">
        <ng-template mat-tab-label>
          CUSTOM PRODUCTS
        </ng-template>

        <mat-table id="inventory-table" [dataSource]="inventoryDataSources['__custom__']">
          <!-- Column -->
          <ng-container matColumnDef="active">
            <mat-header-cell class="table-column-active" *matHeaderCellDef>
                <mat-checkbox color="primary" [checked]="isAllActive(inventoryDataSources['__custom__'])" [indeterminate]="isIndeterminateActive(inventoryDataSources['__custom__'])" [disabled]="!isEdit" (change)="changeAllSelection(inventoryDataSources['__custom__'], $event.checked)"></mat-checkbox>
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-active" *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="element.active" [disabled]="!isEdit" (change)="element.active = $event.checked;"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="manufacturer">
            <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
              Manufacturer
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
              {{element.product.manufacturer}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_category">
            <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
              Category
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
              {{element.product.category.name}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_code">
            <mat-header-cell class="table-column-product-code" *matHeaderCellDef>
              Code
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-code" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.code}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_name">
            <mat-header-cell class="table-column-product-name" *matHeaderCellDef>
              Name
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-product-name" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.name}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="unit">
            <mat-header-cell class="table-column-quantity" *matHeaderCellDef> Unit </mat-header-cell>
            <mat-cell class="cf-reset table-column-quantity" *matCellDef="let element">
              <ng-container *ngIf="element.product">
                {{element.product.unit.id}} - {{element.product.unit.name_singular}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="unit_cost">
            <mat-header-cell class="table-column-unit-value" *matHeaderCellDef>
              Unit Cost <ng-container *ngIf="userService.initialised | async">({{userService.currentUser.tenant.currency.iso_4217}})</ng-container>
            </mat-header-cell>
            <mat-cell class="cf-reset table-column-unit-value" *matCellDef="let element">
              <input *ngIf="isEdit" type="number" min="0" matInput [(ngModel)]="element.unit_cost" class="unit-cost-input">
              <ng-container *ngIf="!isEdit">
                {{element.unit_cost | number:'1.2-2' }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="operations">
            <mat-header-cell class="table-column-operations" *matHeaderCellDef> Operations </mat-header-cell>
            <mat-cell class="cf-reset table-column-operations" *matCellDef="let element">
              <button *ngIf="!element.isEdited && !element.isLoading" mat-button (click)="editRow(element)">Edit</button>
              <button *ngIf="element.isEdited" mat-button color="primary" (click)="cancelEditRow(element)">Cancel</button>
              <div class="loading-spinner">
                <mat-spinner *ngIf="element.isLoading" diameter="20"></mat-spinner>
              </div>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="inventoryTableColumns_custom"></mat-header-row>
          <mat-row *matRowDef="let row; columns: inventoryTableColumns_custom;" [class.not-active]="!row.active"></mat-row>
        </mat-table>

      </mat-tab>
    </mat-tab-group>

  </div>
</div>