import { DataService } from './data.service';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeoEncodeService {
  private api_endpoint = '/geoencode';
  private full_api_url;

  constructor(private dataService: DataService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  decode(lat:number, lng:number){
    return this.dataService.getRequest(this.full_api_url + "/latlng/" + lat + "/" + lng);
  }

  encode(address:string){
    return this.dataService.getRequest(this.full_api_url + "/address/" + address);
  }
}