<div id="content">
  <ng-container *ngIf="job_panel">
    <div id="header">
      <div id="header-text">
        {{job_panel.panel.name}}
      </div>
      <div id="close-button">
        <mat-icon (click)="onNoClick()">clear</mat-icon>
      </div>
    </div>

    <div id="container">
      <div class="surface-item">
        <div class="surface-name">

        </div>
        <div class="surface-elements">
          <div class="surface-icon">
            Colour
          </div>
          <div class="surface-options">
            <mat-radio-group [(ngModel)]="job_panel.colour" class="flex-column" [disabled]="!job.isEditable()">
              <ng-container *ngFor="let colour of job.getActiveColours()">
                <mat-radio-button color="primary" [value]="colour" class="radio-button-colour" [class.selected-rb]="job_panel.colour == colour">
                  {{colour.colour.code}} - {{colour.colour.name}}
                </mat-radio-button>
              </ng-container>

            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="h-line"></div>
      <!-- ======= SUBSTRATE ======== 
      <div id="row-substrate" class="row">
        <div class="row-title">Substrate</div>
        <div id="element-substrate">
          <mat-radio-group class="flex-column" [(ngModel)]="job_panel.substrate">
            <mat-radio-button color="primary" *ngFor="let substrate of variantService.substrates" [value]="substrate" class="radio-button">
              {{substrate.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>-->

      <div class="surface-item">
        <div class="surface-name">

        </div>
        <div class="surface-elements">
          <div class="surface-icon">
            Substrate
          </div>
          <div class="surface-options">
            <mat-radio-group [(ngModel)]="job_panel.substrate" [disabled]="!job.isEditable()">
              <mat-radio-button color="primary" *ngFor="let substrate of variantService.substrates" [value]="substrate"
                class="radio-button" [class.selected-rb]="job_panel.substrate == substrate">
                {{substrate.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>


      <div class="h-line"></div>

      <div class="surface-item">
          <div class="surface-name">
            Outside
          </div>
          <div class="surface-elements">
            <div class="surface-icon">
              <div class="icon-location-out"></div>
            </div>
            <div class="surface-options">
              <mat-radio-group [(ngModel)]="job_panel.getSurfaceOutside().repair" (change)="changeRepair(false, $event.value)" [disabled]="!job.isEditable()">
                <mat-radio-button color="primary" class="radio-button" [class.selected-rb]="job_panel.getSurfaceOutside().repair == undefined" [value]="undefined">None</mat-radio-button>
                <mat-radio-button color="primary" *ngFor="let repair_type of panelService.repairTypes" [value]="repair_type" class="radio-button" [class.selected-rb]="job_panel.getSurfaceOutside().repair == repair_type" [disabled]="!isRepairTypeAvailable(job_panel.getSurfaceOutside(), repair_type)">
                  {{repair_type.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
  
        <div class="h-line"></div>

      <div class="surface-item">
        <div class="surface-name">
          Inside
        </div>
        <div class="surface-elements">
          <div class="surface-icon">
            <div class="icon-location-in"></div>
          </div>
          <div class="surface-options">
            <mat-radio-group [(ngModel)]="job_panel.getSurfaceInside().repair" (change)="changeRepair(true, $event.value)" [disabled]="!job.isEditable()">
              <mat-radio-button color="primary" class="radio-button" [class.selected-rb]="job_panel.getSurfaceInside().repair == undefined" [value]="undefined">None</mat-radio-button>
              <mat-radio-button color="primary" *ngFor="let repair_type of panelService.repairTypes" [value]="repair_type" class="radio-button" [class.selected-rb]="job_panel.getSurfaceInside().repair == repair_type" [disabled]="!isRepairTypeAvailable(job_panel.getSurfaceInside(), repair_type)">
                {{repair_type.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="h-line"></div>

      

      <!--
      

      <div id="left">
        <div class="heading">
          Surfaces
        </div>
        <mat-slide-toggle [disabled]="!job_panel.panel.surface_out" [(ngModel)]="job_panel.selected_outside">Outside</mat-slide-toggle>
        <mat-slide-toggle [disabled]="!job_panel.panel.surface_in" [(ngModel)]="job_panel.selected_inside">Inside</mat-slide-toggle>
      </div>

      <div id="right">
        <div class="heading">
          Substrate
        </div>
        <mat-select placeholder="Please Select" [(ngModel)]="job_panel.substrate">
          <mat-option value="metal">Metal</mat-option>
          <mat-option value="plastic">Plastic</mat-option>
          <mat-option value="composite">Composite</mat-option>
        </mat-select>
      </div>

    -->
    </div>



  </ng-container>
</div>