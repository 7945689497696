<div id="table">
  <mat-table #formulaTable [dataSource]="formulaDataSource" id="formula-table">
    <!-- Column -->
    <ng-container matColumnDef="product_code">
      <mat-header-cell class="formula-table-cell-product-code" *matHeaderCellDef>
        Product Code adfsdf
      </mat-header-cell>

      <mat-cell *matCellDef="let element">

        <ng-container *ngIf="element.product">
          {{element.product.code}}
        </ng-container>
        adsfadsf

      </mat-cell>
    </ng-container>


    <!-- Column -->
    <ng-container matColumnDef="product_code_short">
      <mat-header-cell class="formula-table-cell-product-code-short" *matHeaderCellDef>
        Product Code (Short)
      </mat-header-cell>
      <mat-cell class="formula-table-cell-product-code-short" *matCellDef="let element">
        <ng-container *ngIf="element.product">

          <mat-chip-list #colourChipList>
            <mat-chip *ngIf="element.product">
              {{element.product.code}}

              <!-- show if ratio is 0 -->
              <ng-container *ngIf="toolkit.ratio(element, _newFormula) == 0">
                <mat-icon (click)="deleteComponent(element)" matChipRemove>cancel</mat-icon>
              </ng-container>

            </mat-chip>
          </mat-chip-list>
        </ng-container>


        <ng-container *ngIf="!element.product">
          <mat-form-field appearance="outline" class="input-min field-short">
            <div class="flex-row">
              <input type="text" #productCodeInput matInput [matAutocomplete]="productCodeAutoComplete">
            </div>
          </mat-form-field>
          <mat-autocomplete #productCodeAutoComplete="matAutocomplete" (optionSelected)="addProduct($event.option.value, element); productCodeInput.value=''; "
            autoActiveFirstOption panelWidth="width: 400px;">

            <ng-container *ngFor="let product of productService.products">

              <!-- show only Kansai Products -->
              <ng-container *ngIf="product.is_kansai">

                <!-- show only available (inventory) product -->
                <ng-container *ngIf="inventoryService.getByProductId(product.id)">

                  <!-- only show products of productType -->
                  <ng-container *ngIf="product.isAvailableIn(productType)">

                    <!-- only show unused products -->
                    <ng-container *ngIf="!containsProduct(product)">

                      <!-- only show matching products -->
                      <ng-container *ngIf="!productCodeInput.value || (productCodeInput.value && product.code_short.indexOf(productCodeInput.value.trim()) > -1)">


                        <mat-option [value]="product">
                          {{product.type.code.toUpperCase()}}: {{product.code_short}} - {{product.name}}
                        </mat-option>
                      </ng-container>
                    </ng-container>

                  </ng-container>

                </ng-container>
              </ng-container>

            </ng-container>
          </mat-autocomplete>
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="product_name">
      <mat-header-cell class="formula-table-cell-product-name" *matHeaderCellDef>
        Product Name
      </mat-header-cell>
      <mat-cell class="formula-table-cell-product-name" *matCellDef="let element">

        <ng-container *ngIf="element.product">
          {{element.product.name}}
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="ratio">
      <mat-header-cell class="formula-table-cell-amount" *matHeaderCellDef>
        Ratio
      </mat-header-cell>
      <mat-cell class="formula-table-cell-amount monospace-number" *matCellDef="let element">
        <ng-container *ngIf="element.amount_kg">
          {{element.ratio * 100 | number:'1.2-2' }}%
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="amount_kg">
      <mat-header-cell class="formula-table-cell-amount" *matHeaderCellDef>
        Amount (g)
      </mat-header-cell>
      <mat-cell class="formula-table-cell-amount monospace-number" *matCellDef="let element">
        <ng-container *ngIf="element.product">
          <mat-form-field appearance="outline" class="input-min field-short">
            <input type="number" min="0" matInput [(ngModel)]="element.amount_g" (keydown)="submitQuantity()">
            <span matSuffix>g</span>
          </mat-form-field>
        </ng-container>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="formulaTableColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: formulaTableColumns;"></mat-row>
  </mat-table>
  <!--
  <div id="action-pane">
    <button mat-button color="primary" [disabled]="_isDone" (click)="done()">Done</button>
  </div>

-->
</div>