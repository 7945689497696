<div id="klayout-container">
  <div id="klayout-right">
    <div id="klayout-right-top" class="klayout-top">
      <!-- Actions -->
      <div class="kpage-title">
        Report: Product Consumption
      </div>

      <div class="kv-spacer"></div>

      <button mat-button color="primary" class="button-narrow" (click)="refresh()" [disabled]="!isReady()">
        <mat-icon>
          refresh
        </mat-icon>
        Refresh
      </button>
    </div>
    <div id="klayout-right-bottom">
      <!-- Contents -->



      <mat-expansion-panel class="coat-exp-panel" *ngIf="userService.initialised | async" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Settings
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>


        <mat-form-field appearance="outline">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a date" [(ngModel)]="date_from">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date" [(ngModel)]="date_to">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Group by</mat-label>
          <mat-select [(ngModel)]="frequency">
            <mat-option value="day">Day</mat-option>
            <mat-option value="month">Month</mat-option>
            <mat-option value="year">Year</mat-option>
          </mat-select>
        </mat-form-field>

        Bodyshops

        <ng-container *ngFor="let bshop of userService.currentUser.reportingBodyshops">
          <mat-checkbox color="primary" [value]="bshop" *ngIf="bshop.isEnabled()" [checked]="isBodyshopSelected(bshop)"
            (change)="clickBodyshop($event)">{{bshop.id_custom}}</mat-checkbox>
        </ng-container>

      </mat-expansion-panel>


      <div id="table-container">
        <table mat-table [dataSource]="dataSource" id="data-table">

          <ng-container matColumnDef="product" sticky>
            <th mat-header-cell *matHeaderCellDef> Product </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list>
                <mat-chip>{{element.product.code}} </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <ng-container *ngFor="let td of dynamicColumns">
            <ng-container matColumnDef="{{td}}">
              <th mat-header-cell *matHeaderCellDef class="th-date"> {{td}} </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.values['' + td]">
                  {{element.values["" + td]['kg'] | number:'1.2-2'}}
                </ng-container>
              </td>
            </ng-container>

          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>