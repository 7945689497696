import { Router } from '@angular/router/';
import { MatTableDataSource } from '@angular/material';
import { Bodyshop } from './../../../model/model';
import { ManageBodyshopService } from './../../../services/manage-bodyshop.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-bodyshops',
  templateUrl: './manage-bodyshops.component.html',
  styleUrls: ['./manage-bodyshops.component.css']
})
export class ManageBodyshopsComponent implements OnInit {
  public bodyshops: Bodyshop[];
  public bodyshop_inactive: Bodyshop[];

  public dataSource: MatTableDataSource<Bodyshop>;
  public dataSource_inactive: MatTableDataSource<Bodyshop>;
  public displayedColumns = ['icon', 'id_custom', 'name', 'city', 'actions']

  public _isRetrieving = false;

  constructor(public manageBodyshpService: ManageBodyshopService, private router: Router) {
  }

  ngOnInit() {
    this._isRetrieving = true;
    this.bodyshops = [];
    this.bodyshop_inactive = [];

    this.manageBodyshpService.getBodyshops().subscribe(shops => {
      if (shops) {


        for (let shop of shops) {
          if (!shop.is_deleted) {

            if (shop.is_active) {
              this.bodyshops.push(shop);
            }
            else {
              this.bodyshop_inactive.push(shop);
            }
          }
        }

        this.updateTables();
        this._isRetrieving = false;
      }
    },
      error => {
        this._isRetrieving = false;
        console.log(error);
      });

  }

  updateTables() {
    this.dataSource = new MatTableDataSource(this.bodyshops);
    this.dataSource_inactive = new MatTableDataSource(this.bodyshop_inactive);
  }

  navigate(shop: Bodyshop) {
    this.router.navigate(['manage', 'bodyshops', shop.id]);
  }

  navigateInventory(shop: Bodyshop){
    this.router.navigate(['manage', 'bodyshops', shop.id, 'inventory']);
  }
}
