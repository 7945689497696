import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mixer-visual',
  templateUrl: './mixer-visual.component.html',
  styleUrls: ['./mixer-visual.component.css']
})
export class MixerVisualComponent implements OnInit {
  @Input() type:string;
  @Input() target:number;
  @Input() value:number;
  @Input() error:number;

  constructor() { }

  ngOnInit() {
  }

}
