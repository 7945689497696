<mat-autocomplete #auto="matAutocomplete" panelWidth="600px">
  <ng-container *ngFor="let item of filteredOptions | async">
    <mat-option *ngIf="!jobService.currentJob.getCustomProduct(item.product.id)" (keydown.enter)="addItem(item)"
      (click)="addItem(item)">
      <div class="product">
        <div class="product-manufacturer">
          <ng-container *ngIf="!item.product.manufacturer">
            Kansai Paint
          </ng-container>
          <ng-container *ngIf="item.product.manufacturer">
            {{item.product.manufacturer}}
          </ng-container>
        </div>
        |
        <div class="product-code">
          {{item.product.code}}
        </div>
        |
        <div class="product-name">
          {{item.product.name}}
        </div>
      </div>
    </mat-option>
  </ng-container>

</mat-autocomplete>

<mat-table id="inventory-table" *ngIf="dataSource" [dataSource]="dataSource">

  <!-- Column -->
  <ng-container matColumnDef="action">
    <mat-header-cell class="table-column-product-code" *matHeaderCellDef>

    </mat-header-cell>
    <mat-cell class="cf-reset table-column-product-code" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        <button mat-icon-button (click)="deleteItem(element.jobProduct)">
          <mat-icon>clear</mat-icon>
        </button>
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Column -->
  <ng-container matColumnDef="manufacturer">
    <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
      Manufacturer
    </mat-header-cell>
    <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        <ng-container *ngIf="element.jobProduct.product.manufacturer">
          {{element.jobProduct.product.manufacturer}}
        </ng-container>
        <ng-container *ngIf="!element.jobProduct.product.manufacturer">
          Kansai Paint
        </ng-container>
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Column -->
  <ng-container matColumnDef="product_category">
    <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
      Category
    </mat-header-cell>
    <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        {{element.jobProduct.product.category.name}}
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Column -->
  <ng-container matColumnDef="product_code">
    <mat-header-cell class="table-column-product-code" *matHeaderCellDef>
      Code
    </mat-header-cell>
    <mat-cell class="cf-reset table-column-product-code" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        {{element.jobProduct.product.code}}
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Column -->
  <ng-container matColumnDef="product_name">
    <mat-header-cell class="table-column-product-name" *matHeaderCellDef>
      Name
    </mat-header-cell>
    <mat-cell class="cf-reset table-column-product-name" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        {{element.jobProduct.product.name}}
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Column -->
  <ng-container matColumnDef="quantity">
    <mat-header-cell class="table-column-unit-value" *matHeaderCellDef>
      Quantity
    </mat-header-cell>
    <mat-cell class="cf-reset table-column-unit-value" *matCellDef="let element">
      <ng-container *ngIf="element.jobProduct">
        <input type="number" min="0" matInput [(ngModel)]="element.jobProduct.quantity" class="unit-cost-input">
        {{element.jobProduct.product.unit.name_plural}}
      </ng-container>

    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="inventoryTableColumns_custom"></mat-header-row>
  <mat-row *matRowDef="let row; columns: inventoryTableColumns_custom;" [class.not-active]="!row.active"></mat-row>
</mat-table>

<div class="product-search">
  <mat-form-field appearance="outline" class="product-search-field">
    <mat-label>Search for product code or name</mat-label>
    <input type="text" matInput [matAutocomplete]="auto" [formControl]="myControl">
  </mat-form-field>
</div>