import { UserService } from './../../../services/user.service';
import { DataService } from './../../../services/data.service';
import { DialogTwoOptionsComponent } from './../../dialogs/dialog-two-options/dialog-two-options.component';
import { MatDialog } from '@angular/material';
import { Bodyshop } from './../../../model/model';
import { ManageBodyshopService } from './../../../services/manage-bodyshop.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router/';
import { ManageUserService } from './../../../services/manage-user.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../../../model/model';

@Component({
  selector: 'app-manage-users-edit',
  templateUrl: './manage-users-edit.component.html',
  styleUrls: ['./manage-users-edit.component.css']
})
export class ManageUsersEditComponent implements OnInit {
  public user: User;
  public bodyshops: Bodyshop[];
  public isError: boolean = false;
  public isEdit: boolean = false;
  public _isLoading: boolean = false;

  //saved to give warning in case it is changed
  private _initEmail: string;

  public userForm = this.fb.group({
    email: ['', Validators.email],
    name: ['', Validators.required],
    employee_nb: [''],
    bodyshop: [''],
    is_globalAdmin: ['']
  });


  constructor(public manageUserService: ManageUserService,
    public manageBodyshopService: ManageBodyshopService,
    public userService: UserService,
    public route: ActivatedRoute,
    public dataService: DataService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      //if param is found -> existing user
      if (params['userId']) {
        this.manageUserService.getUser(params['userId']).subscribe(user => {
          this.user = user;
          this._initEmail = user.email;

          //fix permissions if not set
          if (!user['permissions']) {
            user['permissions'] = {};
          }

          this.manageBodyshopService.getBodyshops().subscribe(shops => {
            this.bodyshops = shops;

            for (let bshop of shops) {
              if (bshop.id == user.bodyshop_id) {
                user.currentBodyshop = bshop;
                break;
              }
            }

            this.populateForm();
          });
        },
          error => {
            console.log(error);
            this.isError = true;
            this.router.navigate(['..'], { relativeTo: this.route });
          });
      }
      else {
        //new user to be created
        this.user = new User();
        this.isEdit = true;

        this.manageBodyshopService.getBodyshops().subscribe(shops => {
          this.bodyshops = shops;
          this.user.currentBodyshop = shops[0];

          this.populateForm();
        });
      }
    });
  }

  populateForm() {
    this.userForm.controls['name'].patchValue(this.user.name);
    this.userForm.controls['email'].patchValue(this.user.email);
    this.userForm.controls['employee_nb'].patchValue(this.user.employee_nb);
    this.userForm.controls['bodyshop'].patchValue(this.user.currentBodyshop);
  }

  saveUser() {
    if (this.userForm.valid) {
      let text = "Do you want to save these changes? (It may take up to 5 minutes to take effect!)";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Save Changes",
          'text': text,
          'buttonPrimary_text': "Yes, Save",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this.isError = false;
        this.user.name = this.userForm.controls['name'].value;
        this.user.email = this.userForm.controls['email'].value;
        this.user.employee_nb = this.userForm.controls['employee_nb'].value;
        this.user.currentBodyshop = this.userForm.controls['bodyshop'].value;

        this.dataService.blockUIforLoad = true;
        this.manageUserService.saveUser(this.user).subscribe(resUser => {
          this._isLoading = false;
          this.user = resUser;
          this.dataService.blockUIforLoad = false;

          //if current user is edited, refresh
          if (this.user.id == this.userService.currentUser.id) {
            this.userService.refreshData();
          }

          this.router.navigate(['..'], { relativeTo: this.route });
        },
          error => {
            console.log(error);
            this._isLoading = false;
            this.isError = true;
            this.dataService.blockUIforLoad = false;
          });
      });



    }

  }

  resetPassword() {
    if (this.user.id) {
      let text = "Resetting the users password will invalidate their current password and send them an email to " + this.user.email + " with instructions to choose a new password. Do you want to proceed?";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Reset Password",
          'text': text,
          'buttonPrimary_text': "Yes",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this.isError = false;

        this.dataService.blockUIforLoad = true;
        this.manageUserService.resetPassword(this.user).subscribe(result => {
          if (result) {
            this._isLoading = false;
            this.isError = false;
          }
          else {
            this._isLoading = false;
            this.isError = true;
          }

          this.dataService.blockUIforLoad = false;
        },
          error => {
            this._isLoading = false;
            this.isError = false;
            console.log(error);
            this.dataService.blockUIforLoad = false;
          })
      });
    }
  }

  changeReportingBodyshop(event) {
    if (event.checked) {
      let found = false;

      for (let bshop of this.user.reportingBodyshops) {
        if (bshop.id == event.source.value.id) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.user.reportingBodyshops.push(event.source.value);
      }
    }
    else {
      for (let bshop of this.user.reportingBodyshops) {
        if (bshop.id == event.source.value.id) {
          this.user.reportingBodyshops.splice(this.user.reportingBodyshops.indexOf(bshop), 1);
          break;
        }
      }
    }
  }

  changePermission(event) {
    let domain = event.source.value[0];
    let entity = event.source.value[1];

    if (!this.user.permissions[domain]) {
      this.user.permissions[domain] = {}
    }

    this.user.permissions[domain][entity] = event.checked;
  }

  disableUser() {
    if (this.user.id) {
      let text = "Do you want to disable this user? (It may take up to 5 minutes to take effect!)";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Disable User",
          'text': text,
          'buttonPrimary_text': "Yes, Disable",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this.isError = false;

        this.dataService.blockUIforLoad = true;
        this.manageUserService.disableUser(this.user).subscribe(user => {
          if (user) {
            this._isLoading = false;
            this.isError = false;
            this.user = user;
          }
          else {
            this._isLoading = false;
            this.isError = true;
          }

          this.dataService.blockUIforLoad = false;
        },
          error => {
            this._isLoading = false;
            this.isError = false;
            console.log(error);
            this.dataService.blockUIforLoad = false;
          })
      });
    }
  }

  enableUser() {
    if (this.user.id) {
      let text = "Do you want to enable this user? (It may take up to 5 minutes to take effect!)";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Enable User",
          'text': text,
          'buttonPrimary_text': "Yes, Enable",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this.isError = false;

        this.dataService.blockUIforLoad = true;
        this.manageUserService.enableUser(this.user).subscribe(user => {
          if (user) {
            this._isLoading = false;
            this.isError = false;
            this.user = user;
          }
          else {
            this._isLoading = false;
            this.isError = true;
          }

          this.dataService.blockUIforLoad = false;
        },
          error => {
            this._isLoading = false;
            this.isError = false;
            console.log(error);
            this.dataService.blockUIforLoad = false;
          })
      });
    }
  }


  deleteUser() {
    if (this.user.id) {
      let text = "Do you want to delete this user? This cannot be undone! (It may take up to 5 minutes to take effect!)";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Delete User",
          'text': text,
          'buttonPrimary_text': "Yes, Delete",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this.isError = false;

        this.dataService.blockUIforLoad = true;
        this.manageUserService.deleteUser(this.user).subscribe(user => {
          if (user) {
            this._isLoading = false;
            this.isError = false;
            this.router.navigate(['./..'], { relativeTo: this.route });
          }
          else {
            this._isLoading = false;
            this.isError = true;
          }

          this.dataService.blockUIforLoad = false;
        },
          error => {
            this._isLoading = false;
            this.isError = false;
            console.log(error);
            this.dataService.blockUIforLoad = false;
          })
      });
    }
  }

  tmp() {
    this.userForm.valid
  }

  log(item?) {
    if (item != undefined) {
      console.log(item);
    }
    else {
      console.log(this);
    }
  }
}
