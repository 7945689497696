<div id="container">
    <div id="title">
      {{title}}
    </div>
  <div id="text" [innerHtml]="text">
  </div>
  <div id="buttons">
    <button mat-raised-button (click)="clickSecondary()" class="large-button" *ngIf="buttonSecondary_text" #primaryButton>
      {{buttonSecondary_text}}
    </button>
    <div class="spacer" *ngIf="buttonSecondary_text"></div>
    <button mat-raised-button color="primary" (click)="clickPrimary()" class="large-button" #secondaryButton>
      {{buttonPrimary_text}}
    </button>
  </div>
</div>