import { Job, Coat, JobPanel, Product, ProductType } from './../../../model/model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-panel-select-for-simple-coat',
  templateUrl: './dialog-panel-select-for-simple-coat.component.html',
  styleUrls: ['./dialog-panel-select-for-simple-coat.component.css']
})
export class DialogPanelSelectForSimpleCoatComponent implements OnInit {
  public job:Job;
  public coat:Coat;
  public product_type:ProductType;
  public onSelect = new EventEmitter<any>();
  public onCancel = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<DialogPanelSelectForSimpleCoatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {DialogData}) { 
      this.job = data['job'];
      this.coat = data['coat'];
      this.product_type = data['product_type'];
     }

  ngOnInit() {
  }

  selected(eventObject){
    this.onSelect.emit(eventObject);
    this.dialogRef.close();
  }

  cancel(){
    this.onCancel.emit(true);
    this.dialogRef.close();
  }

}
