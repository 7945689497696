import { Batch } from './../../model/model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Toolkit } from '../../../toolkit/toolkit';

@Component({
  selector: 'app-batch-card',
  templateUrl: './batch-card.component.html',
  styleUrls: ['./batch-card.component.css']
})
export class BatchCardComponent implements OnInit {
  @Input() batch:Batch;
  @Output() onDelete = new EventEmitter();
  public toolkit = new Toolkit();

  constructor() { }

  ngOnInit() {
  }

  delete(){
    this.onDelete.emit();
  }

}
