<!--The content below is only a placeholder and can be replaced.-->

<div id="container">
    <ng-container *ngIf='authService.isLoggedIn()'>

        <mat-toolbar id="header-toolbar">
            <ng-container>
                <button mat-icon-button class="header-menu-toggle" (click)="sidenav.toggle()">
                    <mat-icon class="fa fa-bars top-user-icon" aria-hidden="true"></mat-icon>
                </button>
                <mat-menu #sidemenu="matMenu">

                </mat-menu>
            </ng-container>

            <div id="logo-kansai-text"></div>

            <span class="hor-spacer"></span>

            <div>Beta Version 0.95.2 - No Warranty</div>
            <!--<div id="logo-kansai-k"></div>-->

            <span class="hor-spacer"></span>
            <!--
            <form class="example-form">
                <mat-form-field class="header-search-field">
                    <span matPrefix>
                        <mat-icon class="fa fa-search top-search-icon" aria-hidden="true"></mat-icon> &nbsp;
                    </span>
                    <input type="text" matInput>
                </mat-form-field>
            </form>

            
            <mat-select #bodyshopSelect (change)='userService.bodyshopChange(bodyshopSelect.value); cacheService.refreshData();' class='bodyshop-select'
                *ngIf='userService.currentUser && userService.currentUser.currentBodyshop' [value]='userService.currentUser.currentBodyshop'>
                <mat-option *ngFor="let bodyshop of userService.currentUser.bodyshops" [value]="bodyshop">
                    {{ bodyshop.name }}
                </mat-option>
            </mat-select>
            -->

            <ng-container *ngIf="userService.initialised | async">
                <div class="tenant-name">
                    {{userService.currentUser.tenant.name}}
                </div>

                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon class="fa fa-user-circle-o top-user-icon" aria-hidden="true"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf='authService.isLoggedIn()' (click)="redirect('/user')">{{userService.currentUser.name}}</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item *ngIf='authService.isLoggedIn()' (click)='authService.logout()'>Logout</button>
                </mat-menu>

                <div *ngIf='!authService.isLoggedIn()'>
                    <button mat-button *ngIf='!authService.isLoggedIn()' (click)='redirectToLogin()'>Login</button>
                </div>
            </ng-container>


        </mat-toolbar>
        <mat-progress-bar *ngIf='cacheService.loadingPercentage < 100' mode="buffer" [value]="cacheService.loadingPercentage"></mat-progress-bar>
    </ng-container>

    <div id="block-ui-for-load" *ngIf="dataService.blockUIforLoad">
        <mat-spinner></mat-spinner>
    </div>

    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav mode="over" id="sidenav-menu">
            <ng-container *ngIf="userService.initialised | async">
                <button class="menu-toolbar-button" mat-menu-item (click)="redirect('/home'); sidenav.toggle();"
                    [class.selected]="router.isActive('/home', false)">
                    <mat-icon>home</mat-icon> Home
                </button>

                <mat-divider></mat-divider>

                <ng-container *ngIf="userService.currentUser.isPermitted('bodyshop', 'job')">
                    <button class="menu-toolbar-button" mat-menu-item (click)="redirect('/jobs'); sidenav.toggle();"
                        [class.selected]="router.isActive('/jobs', false)">
                        <mat-icon>view_list</mat-icon> Jobs
                    </button>

                    <mat-divider></mat-divider>
                </ng-container>


                <button class="menu-toolbar-button" mat-menu-item (click)="redirect('/formula-explorer'); sidenav.toggle();"
                    [class.selected]="router.isActive('/formula-explorer', false)">
                    <mat-icon>color_lens</mat-icon> Colours
                </button>


                <mat-divider></mat-divider>


                <ng-container *ngIf="userService.currentUser.reportingBodyshops.length > 0">
                    <button class="menu-toolbar-button" mat-menu-item (click)="redirect('/reports'); sidenav.toggle();"
                        [class.selected]="router.isActive('/reports', false)">
                        <mat-icon>insert_chart</mat-icon> Reports
                    </button>

                    <mat-divider></mat-divider>
                </ng-container>

                <ng-container *ngIf="userService.currentUser.isPermittedForDomain('tenant')">
                    <button class="menu-toolbar-button" mat-menu-item (click)="redirect('/manage/bodyshops'); sidenav.toggle();"
                        [class.selected]="router.isActive('/manage', false)">
                        <mat-icon>settings</mat-icon> Settings
                    </button>


                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>

        </mat-sidenav>

        <mat-sidenav-content class="sidenav-content">
            <!--
            <p>
                <mat-checkbox [(ngModel)]="opened">sidenav.opened</mat-checkbox>
            </p>
            <p>
                <button mat-button (click)="sidenav.toggle()">sidenav.toggle()</button>
            </p>
-->
            <div class="home-page" *ngIf="!isChildrenActive()">
                <ng-container *ngIf="userService.initialised | async">
                    &nbsp;
                    <div class="menu-pointer">

                    </div>

                    <div class="box">
                        <div id="title-box">
                            <div id="title-box-left">
                                <div class="greeting">
                                    {{userService.currentUser.name}}
                                </div>

                                <div class="message">
                                    {{userService.currentUser.tenant.name}} <br>
                                    {{userService.currentUser.currentBodyshop.name}}
                                </div>
                            </div>
                            <div id="logo-k-text-grey"></div>
                        </div>

                    </div>


                    <div class="box-row">
                        <!-- JOBS -->
                        <div class="box" *ngIf="userService.currentUser.isPermitted('bodyshop', 'job')">
                            <div class="box-title">
                                <mat-icon class="box-title-icon">view_list</mat-icon> Jobs
                            </div>

                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/jobs']">Open Job List</a>
                            </div>

                            <!--
                                    <div class="list-item">
                                        <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                        <input matInput placeholder="Job ID" class="job-search-input" #idInput>
                                        <button mat-button class="job-search-button" (click)="redirect('/jobs/id/' + idInput.value)" [disabled]="!idInput.value"><mat-icon>search</mat-icon></button>
                                    </div>-->

                        </div>
                        <!-- COLOUR -->
                        <div class="box">
                            <div class="box-title">
                                <mat-icon class="box-title-icon">color_lens</mat-icon> Colours
                            </div>


                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/formula-explorer']">Browse Colours</a>
                            </div>
                        </div>



                        <!-- REPORTS -->
                        <div class="box" *ngIf="userService.currentUser.reportingBodyshops.length > 0">
                            <div class="box-title">
                                <mat-icon class="box-title-icon">insert_chart</mat-icon> Reports
                            </div>

                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/reports']">Open Reports List</a>
                            </div>
                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/reports', 'report-consumption-by-product']">Consumption Report</a>
                            </div>
                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/reports', 'job-kpi']">Job KPI Report</a>
                            </div>
                        </div>

                        <!-- TENANT -->
                        <div class="box" *ngIf="userService.currentUser.isPermittedForDomain('tenant')">
                            <div class="box-title">
                                <mat-icon class="box-title-icon">settings</mat-icon> Settings
                            </div>

                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/manage', 'users']">Manage Users</a>
                            </div>
                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/manage', 'bodyshops']">Manage Bodyshops</a>
                            </div>
                            <div class="list-item">
                                <mat-icon class="list-item-icon">keyboard_arrow_right</mat-icon>
                                <a [routerLink]="['/manage', 'custom-products']">Manage Products</a>
                            </div>
                        </div>
                    </div>


                </ng-container>

            </div>
            <router-outlet *ngIf="isChildrenActive()"></router-outlet>

        </mat-sidenav-content>
    </mat-sidenav-container>
    <!--
    <div id="footer">
        <div id="footer-left">
            &copy; {{currentYear()}} Kansai Paint Middle East FZCO
        </div>
        <div id="footer-centre"></div>
        <div id="footer-right"></div>
    </div>-->
</div>