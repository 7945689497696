<div id="klayout-container">
  <div id="klayout-left">
    <div id="klayout-left-top" class="klayout-top">
      <!-- Section Header -->

    </div>
    <div id="klayout-left-bottom">
      <!-- Navigation Items -->
      <mat-nav-list class="nav-menu-list">
        <a mat-list-item [routerLink]="['/jobs', 'status', 'open']" class="nav-menu-item" routerLinkActive="selected">
          Open
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item [routerLink]="['/jobs', 'status', 'closed']" class="nav-menu-item" routerLinkActive="selected">
          Closed
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </div>
  </div>
  <div id="klayout-right">
    <div id="klayout-right-top" class="klayout-top">
      <!-- Actions -->
      <button mat-button color="primary" (click)="createNewJob()" [disabled]="!userService.currentUser.isPermitted('bodyshop', 'job')" *ngIf="userService.initialised | async">+ New</button>
    </div>
    <div id="klayout-right-bottom">
      <!-- Contents -->

      <ng-container *ngIf="_isLoading">
        <mat-spinner diameter="50"></mat-spinner>
      </ng-container>
      <ng-container *ngIf="!_isLoading">
        <mat-table #jobsTable [dataSource]="jobsTableDataSource" *ngIf="userService.initialised | async">
          <!-- Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              ID
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.id}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="id_custom">
            <mat-header-cell *matHeaderCellDef>
              Internal Job ID
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="highlight">{{element.id_custom}}</span>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="car_license_plate">
            <mat-header-cell *matHeaderCellDef>
              License Plate
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.car_license_plate}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="vehicle">
            <mat-header-cell *matHeaderCellDef>
              Vehicle
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="highlight">{{element.car_manufacturer.name}}</span> &nbsp;
              <ng-container *ngIf="element.car_model.is_generic">
                {{element.car_model_text}}
              </ng-container>
              <ng-container *ngIf="!element.car_model.is_generic">
                {{element.car_model.name}}
              </ng-container>
            </mat-cell>
          </ng-container>


          <!-- Column -->
          <ng-container matColumnDef="car_year">
            <mat-header-cell *matHeaderCellDef>
              Year
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.car_year }}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef>
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.customer_name }}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="ts_created">
            <mat-header-cell *matHeaderCellDef>
              Created
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div matTooltip="{{element.date_created | date:'EEEE, MMMM d, y, HH:mm:ss zzzz'}}">{{element.date_created | date:'yyyy-MMM-dd HH:mm'}}</div>
              
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="ts_closed">
            <mat-header-cell *matHeaderCellDef>
              Closed On
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.date_closed | date:'yyyy-MMM-dd HH:mm (EEE)'}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="quantity_kg">
            <mat-header-cell *matHeaderCellDef>
              Consumption
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.quantity_kg * 1000 | number:'1.2-2'}}g
            </mat-cell>
          </ng-container>


          <!-- Column -->
          <ng-container matColumnDef="cost">
            <mat-header-cell *matHeaderCellDef>
              Cost
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.cost | number:'1.2-2'}} {{userService.currentUser.tenant.currency.iso_4217}}
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <button mat-button matTooltip="Open" (click)="navigateToJob(element)">
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
              </mat-cell>
            </ng-container>

          <mat-header-row *matHeaderRowDef="jobsTableColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let row; columns: jobsTableColumns;" class="table-row"></mat-row>
        </mat-table>
      </ng-container>


    </div>
  </div>
</div>