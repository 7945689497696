import { DataService } from './../../../services/data.service';
import { CountryService } from './../../../services/country.service';
import { DialogTwoOptionsComponent } from './../../dialogs/dialog-two-options/dialog-two-options.component';
import { User } from './../../../model/model';
import { ManageBodyshopService } from './../../../services/manage-bodyshop.service';
import { ActivatedRoute, Router } from '@angular/router/';
import { MatDialog, MatTableDataSource, MatDialogRef } from '@angular/material';
import { GeoEncodeService } from './../../../services/geo-encode.service';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Bodyshop } from '../../../model/model';
import { ManageUserService } from '../../../services/manage-user.service';

//to make google api accessbile without error
declare var google: any;


@Component({
  selector: 'app-manage-bodyshops-edit',
  templateUrl: './manage-bodyshops-edit.component.html',
  styleUrls: ['./manage-bodyshops-edit.component.css']
})
export class ManageBodyshopsEditComponent implements OnInit {
  public bodyshop: Bodyshop;

  public tmpItem;

  public users: User[];
  public userDataSource: MatTableDataSource<User>;
  public userColumns = ['icon', 'employee_nb', 'name', 'email', 'actions'];

  public _isLoadingUsers = false;
  public _isMapBusy = false;
  public _isMapDataLoading = false;
  public _isLoading = false;
  public _isError = false;
  public _errorMessage;

  public loadedAddress;

  @ViewChild('map') map: ElementRef;
  private marker;

  @ViewChild('customIdInput') customIdInput: ElementRef;

  constructor(public dialog: MatDialog,
    public manageBodyshopService: ManageBodyshopService,
    public manageUserService: ManageUserService,
    public countryService: CountryService,
    public dataService:DataService,
    public route: ActivatedRoute,
    public geoencodeService: GeoEncodeService,
    public router: Router) {

    this.route.params.subscribe(params => {
      if (params['bodyshopId']) {
        this.manageBodyshopService.getBodyshop(params['bodyshopId']).subscribe(shop => {
          if (shop) {
            this.bodyshop = shop;
            this.updateLocation();

            this._isLoadingUsers = true;
            this.manageUserService.getUsers(this.bodyshop.id).subscribe(users => {
              this.users = [];

              for (let user of users) {
                if (user.is_active) {
                  this.users.push(user);
                }
              }

              this.userDataSource = new MatTableDataSource(this.users);
              this._isLoadingUsers = false;
            },
              error => {
                console.log(error);
                this._isLoadingUsers = false;
              });
          }
        },
          error => {
            console.log(error);
          });
      }
      else {
        this.bodyshop = new Bodyshop();
        this.bodyshop.is_active = true;
      }
    });
  }

  ngOnInit() {
    this.map.nativeElement = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 25.075251, lng: 55.139851 },
      zoom: 5
    });

    this.map.nativeElement.addListener('click', event => {
      if (this._isMapDataLoading)
        return;

      this._isMapBusy = true;
      this._isMapDataLoading = true;

      //without this event, angular will not recognise any changes and 
      //the newly set alues will not reflect in the UI
      this.customIdInput.nativeElement.focus();
      this.customIdInput.nativeElement.blur();



      if (this.marker) {
        this.marker.setMap(null);
      }

      this.marker = new google.maps.Marker({
        position: event.latLng,
        map: this.map.nativeElement,
        title: this.bodyshop.id_custom
      });

      this.loadedAddress = {
        'coordinates': {
          'lat': event.latLng.lat(),
          'lng': event.latLng.lng()
        }
      };

      this.geoencodeService.decode(event.latLng.lat(), event.latLng.lng()).subscribe(response => {
        if (response && response['status'] == "OK") {
          let detailedMatch = response['results'][0];


          for (let item of detailedMatch['address_components']) {
            for (let type of item['types']) {
              if (type == "country") {
                this.loadedAddress['country'] = this.countryService.getCountryById(item['short_name'].toLowerCase());
              }

              if (type == "postal_code") {
                this.loadedAddress['post_code'] = item['long_name'];
              }

              if (type == "locality") {
                this.loadedAddress['city'] = item['long_name'];
              }

              if (type == "premise") {
                this.loadedAddress['line_2'] = item['long_name'];
              }

              if (type == "route") {
                if (this.loadedAddress['line_1']) {
                  this.loadedAddress['line_1'] = item['long_name'] + this.loadedAddress['line_1'];
                }
                else {
                  this.loadedAddress['line_1'] = item['long_name'];
                }

              }

              if (type == "street_number") {
                if (!this.loadedAddress['line_1']) {
                  this.loadedAddress['line_1'] = " ";
                }

                this.loadedAddress['line_1'] += item['long_name'];
              }
            }
          }
        }

        this.bodyshop.address = this.loadedAddress;
        this._isMapDataLoading = false;

        //without this event, angular will not recognise any changes and 
        //the newly set alues will not reflect in the UI
        this.customIdInput.nativeElement.focus();
        this.customIdInput.nativeElement.blur();
      }, error => {
        this._isMapDataLoading = false;
        console.log(error);

        //without this event, angular will not recognise any changes and 
        //the newly set alues will not reflect in the UI
        this.customIdInput.nativeElement.focus();
        this.customIdInput.nativeElement.blur();
      });
    });
  }

  updateLocation() {
    if (this.bodyshop && this.bodyshop.address && this.bodyshop.address['coordinates']) {
      let coordinates = this.bodyshop.address['coordinates'];
      if (coordinates['lat'] && coordinates['lng']) {
        if (this.marker) {
          this.marker.setMap(null);
        }

        this.marker = new google.maps.Marker({
          position: { lat: coordinates['lat'], lng: coordinates['lng'] },
          map: this.map.nativeElement,
          title: this.bodyshop.id_custom
        });

        this.map.nativeElement.setCenter({ lat: coordinates['lat'], lng: coordinates['lng'] });
        this.map.nativeElement.setZoom(15);
      }
    }
  }

  navigateUser(user: User) {
    this.router.navigate(['manage', 'users', user.id]);
  }

  saveBodyshop() {
    if (this.isReadyForSave()) {
      this._errorMessage = undefined;
      let text = "Do you want to save these changes? (It may take up to 5 minutes to take effect!)";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Save Bodyshop",
          'text': text,
          'buttonPrimary_text': "Yes, Save",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        this._isLoading = true;
        this._isError = false;

        this.dataService.blockUIforLoad = true;
        this.manageBodyshopService.saveBodyshop(this.bodyshop).subscribe(shop => {
          this.bodyshop = shop;
          this.updateLocation();

          this._isLoading = false;
          this.dataService.blockUIforLoad = false;
          this.router.navigate(['..'], { relativeTo: this.route });
        }, error => {
          console.log(error);
          this._isLoading = false;
          this._isError = true;
          this._errorMessage = error.error.message;
          this.dataService.blockUIforLoad = false;
        });
      });
    }
  }

  enableBodyshop() {
    this._errorMessage = undefined;
    let text = "Do you want to activate this bodyshop? (It may take up to 5 minutes to take effect!)";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Enable Bodyshop",
        'text': text,
        'buttonPrimary_text': "Yes, Enable",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this._isLoading = true;
      this._isError = false;

      this.manageBodyshopService.enableBodyshop(this.bodyshop).subscribe(shop => {
        this.bodyshop = shop;
        this.updateLocation();

        this._isLoading = false;
      }, error => {
        console.log(error);
        this._errorMessage = error.error.message;
        this._isLoading = false;
        this._isError = true;
      });
    });
  }

  disableBodyshop() {
    this._errorMessage = undefined;
    let text = "Do you want to deactivate this bodyshop? (It may take up to 5 minutes to take effect!)";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Disable Bodyshop",
        'text': text,
        'buttonPrimary_text': "Yes, Disable",
        'buttonSecondary_text': "No"
      }
    });

    this.dataService.blockUIforLoad = true;
    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this._isLoading = true;
      this._isError = false;

      this.manageBodyshopService.disableBodyshop(this.bodyshop).subscribe(shop => {
        this.bodyshop = shop;
        this.updateLocation();

        this._isLoading = false;
        this.dataService.blockUIforLoad = false;
      }, error => {
        console.log(error);
        this._isLoading = false;
        this._isError = true;
        this._errorMessage = error.error.message;
        this.dataService.blockUIforLoad = false;
      });
    });
  }


  deleteBodyshop() {
    this._errorMessage = undefined;
    let text = "Do you want to delete this bodyshop? This cannot be undone! (It may take up to 5 minutes to take effect!)";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Delete Bodyshop",
        'text': text,
        'buttonPrimary_text': "Yes, Delete",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this._isLoading = true;
      this._isError = false;

      this.dataService.blockUIforLoad = true;
      this.manageBodyshopService.deleteBodyshop(this.bodyshop).subscribe(shop => {
        this.bodyshop = shop;
        this.updateLocation();

        this._isLoading = false;
        this.dataService.blockUIforLoad = false;
        this.router.navigate(['..'], { relativeTo: this.route });
      }, error => {
        console.log(error);
        this._isLoading = false;
        this._isError = true;
        this._errorMessage = error.error.message;
        this.dataService.blockUIforLoad = false;
      });
    });
  }

  isReadyForSave(): boolean {
    if (!this.bodyshop)
      return false;

    if (!this.bodyshop.id_custom)
      return false;

    if (!this.bodyshop.name)
      return false;

    if (!this.bodyshop.address)
      return false;

    if (!this.bodyshop.address['city'])
      return false;

    if (!this.bodyshop.address['country'])
      return false;

    if (!this.isIDClean())
      return false;

    if(!this.bodyshop.is_active)
      return false;

    return true;
  }

  isIDClean() {
    if (this.bodyshop && this.bodyshop.id_custom)
      return this.bodyshop.id_custom.match(/\s/g) == null;
    else
      return true;
  }

  openInventory(){
    this.router.navigate(['inventory'], {relativeTo: this.route});
  }
}
