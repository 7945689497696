import { DialogTwoOptionsComponent } from './../../dialogs/dialog-two-options/dialog-two-options.component';
import { ColourService } from './../../../services/colour.service';
import { DialogNewColourComponent } from './../../dialogs/dialog-new-colour/dialog-new-colour.component';
import { MatDialog } from '@angular/material';
import { ColourMatch, Colour, CarManufacturer, Batch } from './../../../model/model';
import { ColourMatchService } from './../../../services/colour-match.service';
import { UserService } from './../../../services/user.service';
import { VehicleService } from './../../../services/vehicle.service';
import { JobService } from './../../../services/job.service';
import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {
  public _isCreatingColourMatch = false;
  public newJobColour_selection: Colour[] = [];
  public availableYears = [];
  public today = new Date();

  constructor(public jobService: JobService,
    public vehicleService: VehicleService,
    public countryService: CountryService,
    public userService: UserService,
    public colourMatchService: ColourMatchService,
    public dialog_newColour: MatDialog,
    public dialog: MatDialog,
    public colourService: ColourService,
    public dataService: DataService) {
    for (let i = this.today.getFullYear(); i >= 1900; i--) {
      this.availableYears.push(i);
    }
  }

  ngOnInit() {
    if (this.job() && this.job().car_manufacturer) {
      this.fetchColours(this.job().car_manufacturer);
      this.vehicleService.getModels(this.job().car_manufacturer).subscribe();
    }
  }

  job() {
    return this.jobService.currentJob;
  }

  carManufacturerSelected() {
    this.vehicleService.getModels(this.job().car_manufacturer).subscribe();
    this.fetchColours(this.job().car_manufacturer);
    this.job().car_model = undefined;
    this.job().car_model_text = undefined;

  }

  getCarModelDetails(event) {
    console.log(event);
    this.vehicleService.getModelById(event.value.id).subscribe();
  }

  addColour(colour) {
    if (colour == '__not-found__') {
      this.openNewColourDialog();
    }
    else {
      if (!this.jobService.currentJob.containsColour(colour)) {


        let nCmatch = new ColourMatch();
        nCmatch.colour = colour;

        if (this.job().id) {
          //loading indicator
          this.dataService.blockUIforLoad = true;
          this._isCreatingColourMatch = true;

          nCmatch.job_id = this.job().id;

          this.colourMatchService.saveColourMatch(nCmatch).subscribe(resMatch => {
            resMatch.colour = colour;
            resMatch.job_id = this.job().id;
            this.job().addColourMatch(resMatch);

            //loading indicator
            this.dataService.blockUIforLoad = false;
            this._isCreatingColourMatch = false;
          },
            error => {
              //loading indicator
              this.dataService.blockUIforLoad = false;
              this._isCreatingColourMatch = false;

              throw error;
            });
        }
        else {
          this.job().addColourMatch(nCmatch);
          this._isCreatingColourMatch = false;
        }
      }
    }
  }

  openNewColourDialog() {
    let dialogRef = this.dialog_newColour.open(DialogNewColourComponent, {
      width: '550px',
      data: {
        'car_manufacturer': this.job().car_manufacturer,
        'existing_colours': this.newJobColour_selection
      }
    });

    dialogRef.componentInstance.onColourCreation.subscribe(colour => {
      this.addColour(colour);
      this.newJobColour_selection.push(colour);
    });
  }

  deleteColour(cMatch: ColourMatch) {
    let text = "Do you really want to delete " + cMatch.colour.name +
      " (" + cMatch.colour.code + ") from this job? This cannot be undone.";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Delete Colour",
        'text': text,
        'buttonPrimary_text': "Delete",
        'buttonSecondary_text': "Cancel"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      //loading indicator
      this.dataService.blockUIforLoad = true;

      this.colourMatchService.discard(cMatch).subscribe(resCm => {
        if (resCm) {
          cMatch.is_active = resCm.is_active;

          this.jobService.getJob(this.job().id).subscribe(job => {
            //fix panels
            for (let panel of this.job().panels) {
              let responsePanel = job.getJobPanel(panel.panel);

              if (panel.isSelected() != responsePanel.isSelected()) {
                panel.colour = undefined;

                for (let surface of panel.surfaces) {
                  surface.is_selected = false;
                }
              }
            }

            //fix batches
            for (let batch of this.job().batches) {
              let responseBatch: Batch = job.getBatchById(batch.id);

              if (responseBatch) {
                batch.is_locked = responseBatch.is_locked;
                batch.is_discarded = responseBatch.is_discarded;
              }
            }



            //loading indicator
            this.dataService.blockUIforLoad = false;
          }, error => {
            //loading indicator
            this.dataService.blockUIforLoad = false;
            console.log(error);
          });
        }
      }, error => {
        //loading indicator
        this.dataService.blockUIforLoad = false;
        console.log(error);
      });
    });

  }


  fetchColours(carManufacturer: CarManufacturer) {
    if (carManufacturer) {
      this.colourService.getColours(carManufacturer).subscribe(colours => {
        colours.sort((a: Colour, b: Colour) => {
          if (a.code > b.code) {
            return 1;
          }
          else if (a.code < b.code) {
            return -1;
          }
          else {
            return 0;
          }
        });

        this.newJobColour_selection = colours;
      });
    }
  }

}
