
<div id="klayout-container">
    <div id="klayout-left">
      <div id="klayout-left-top" class="klayout-top">
        <!-- Section Header -->
  
      </div>
      <div id="klayout-left-bottom">
        <!-- Navigation Items -->
        <mat-nav-list class="nav-menu-list">
          <a mat-list-item [routerLink]="['product']" class="nav-menu-item" routerLinkActive="selected">
            Products
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item [routerLink]="['product-type']" class="nav-menu-item" routerLinkActive="selected">
              Product Types
            </a>
            <mat-divider></mat-divider>
        </mat-nav-list>
      </div>
    </div>
    
    <div id="klayout-right">
      <router-outlet ></router-outlet>
    </div>
  </div>