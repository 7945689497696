<ng-container *ngIf="variant && variant.colour && variant.colour.carManufacturer">
  <div id="container">
    <div class="variant-top-header" *ngIf="!hideHeader">
      <div id="variant-top-header-left">
        <h2>Variant Information</h2>
      </div>
      <div id="variant-button">
        <button mat-button (click)="secondaryAction(variant)">Cancel</button>
        <button mat-button color="primary" (click)="saveVariant()">Save</button>
      </div>
    </div>
    <div class="variant-top-header">
      <div class="variant-info">
        <div class="variant-info-header"></div>
        <div class="variant-info-content">
          <mat-form-field appearance="outline" class="product-type-select">
            <mat-label>Product Type</mat-label>
            <mat-select [(ngModel)]="variant.product_type" [disabled]="variant.system">
              <mat-option *ngFor="let ptype of productService.productTypes" [value]="ptype">
                {{ ptype.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="variant-info">
        <div class="variant-info-header"></div>
        <div class="variant-info-content">
          <mat-form-field appearance="outline" class="system-select">
            <mat-label>System</mat-label>
            <mat-select (selectionChange)="selectSystem()" [(ngModel)]="variant.system">
              <ng-container *ngIf="variant.product_type">
                <mat-option *ngFor="let system of getSystems(variant.product_type)" [value]="system">
                  {{ system }}
                </mat-option>
              </ng-container>

            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="variant-info">
        <div class="variant-info-header">Car Make</div>
        <div class="variant-info-content">{{variant.colour.carManufacturer.name}}</div>
      </div>
      <div class="variant-info">
        <div class="variant-info-header">Colour</div>
        <div class="variant-info-content">{{ variant.colour.code }} - {{variant.colour.name}}</div>
      </div>

      <div class="variant-info">
        <div class="variant-info-header">Variant</div>
        <div class="variant-info-content">{{variant.name}}</div>
      </div>


    </div>
<!--
    <div>
      
        <mat-checkbox #cbUnderCoat>Under Coat</mat-checkbox>
        <mat-checkbox [checked]="true" [disabled]="true" #cb1Coat>1st Coat</mat-checkbox>
        <mat-checkbox #cb2Coat>2nd Coat</mat-checkbox>
        <mat-checkbox #cbClearCoat>Clear Coat</mat-checkbox>
    </div>

    <mat-accordion>
      <mat-expansion-panel *ngIf="cbClearCoat.checked">
        <mat-expansion-panel-header>
          <mat-panel-title class="coat-exp-panel-title">
            Clear Coat
          </mat-panel-title>
          <mat-panel-description>
            DESC
          </mat-panel-description>
        </mat-expansion-panel-header>

        CONTENT
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="cb2Coat.checked">
        <mat-expansion-panel-header>
          <mat-panel-title class="coat-exp-panel-title">
            2nd Coat
          </mat-panel-title>
          <mat-panel-description>
            DESC
          </mat-panel-description>
        </mat-expansion-panel-header>

        CONTENT
      </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="coat-exp-panel-title">
            1st Coat
          </mat-panel-title>
          <mat-panel-description>
            DESC
          </mat-panel-description>
        </mat-expansion-panel-header>

        CONTENT
      </mat-expansion-panel>



      <mat-expansion-panel *ngIf="cbUnderCoat.checked">
        <mat-expansion-panel-header>
          <mat-panel-title class="coat-exp-panel-title">
            Under Coat
          </mat-panel-title>
          <mat-panel-description>
            DESC
          </mat-panel-description>
        </mat-expansion-panel-header>

        CONTENT
      </mat-expansion-panel>




    </mat-accordion>-->


    <mat-tab-group *ngIf="variant.system">
      <mat-tab *ngFor="let coat of variant.coats">
        <ng-template matTabLabel>
          {{ coat.coat.code }} - {{coat.coat.name}}
        </ng-template>



        <app-formula-editor [formula]="coat.formula" [productType]="variant.product_type" (onUpdate)="updateFormula($event, coat)"></app-formula-editor>


      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>