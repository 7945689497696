import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordSetService {
  private api_endpoint = '/pwdreset';
  private full_api_url;

  constructor(
    private dataService: DataService) {
    this.full_api_url = this.dataService.getUrl() + this.api_endpoint;
  }

  checkTokenValidity(authToken: string): Observable<boolean> {
    return this.dataService.getRequest(this.full_api_url + "/" + authToken).map(response => {
      if (response && response['valid'])
        return true;
      else
        return false;
    })

  }

  resetPassword(authToken: string, password: string): Observable<boolean> {
    let requestBody = {
      'authKey': authToken,
      'password': password
    }

    return this.dataService.postRequest(this.full_api_url, requestBody).map(response => {
      if (response && response['password_reset_success']) {
        return true;
      }
      else {
        return false;
      }
    });
  }
}
