import { DialogTwoOptionsComponent } from './../dialogs/dialog-two-options/dialog-two-options.component';
import { DataService } from './../../services/data.service';
import { ProductService } from './../../services/product.service';
import { ManageBodyshopService } from './../../services/manage-bodyshop.service';
import { UserService } from './../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router/';
import { InventoryItem, Bodyshop } from './../../model/model';
import { InventoryService } from './../../services/inventory.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  public bodyshop: Bodyshop;
  public inventory: InventoryItem[];
  public inventories;

  public name = 'undefined';
  public inventoryDataSource: MatTableDataSource<InventoryItem>;
  public inventoryDataSources;
  public inventoryTableColumns = ['active', 'product_type', 'product_category', 'product_code', 'product_code_short', 'product_name', 'unit', 'unit_cost'];
  public inventoryTableColumns_custom = ['active', 'manufacturer', 'product_category', 'product_code', 'product_name', 'unit', 'unit_cost'];

  private tableFilter_productTypeCode;
  private tableFilter_productCode;
  private tableFilter_productName;

  public table_editRow = {}

  public isEdit = false;

  public param;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public inventoryService: InventoryService,
    public userService: UserService,
    public dataService: DataService,
    public manageBodyshopService: ManageBodyshopService,
    public productService: ProductService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {

    this.productService.productTypes
  }

  ngOnInit() {
    this.table_editRow = {}

    this.route.params.subscribe(params => {
      if (params['bodyshopId']) {
        this.dataService.blockUIforLoad = true;


        //find bodyshop details
        this.manageBodyshopService.getBodyshop(params['bodyshopId']).subscribe(bshop => {
          this.bodyshop = bshop;
        },
          error => {
            console.log(error);
          });

        //get inventory
        this.productService.initialised.distinctUntilChanged().subscribe(prodState => {
          if (prodState) {
            this.inventoryService.getInventory(params['bodyshopId']).subscribe(inv => {
              this.inventory = inv;

              this.inventories = {};

              for (let product of this.productService.products) {
                let exists = false;

                for (let invItem of inv) {
                  if (!invItem.product) {
                  }

                  if (product.id == invItem.product.id) {
                    exists = true;
                    break;
                  }
                }

                if (!exists) {
                  let invItem = new InventoryItem();
                  invItem.product = product;
                  invItem.active = false;

                  this.inventory.push(invItem);
                }
              }


              this.inventory = this.inventory.sort((a: InventoryItem, b: InventoryItem) => {
                if (!a.product) {
                  return -1;
                }
                if (!b.product) {
                  return 1;
                }

                if (a.product.type && b.product.type) {
                  if (a.product.type.code == b.product.type.code) {
                    if (a.product.code >= b.product.code) {
                      return 1;
                    }
                    else {
                      return -1;
                    }
                  }
                  else {
                    if (a.product.type.code >= b.product.type.code) {
                      return 1;
                    }
                    else {
                      return -1;
                    }
                  }
                }
                else if (a.product.type && !b.product.type) {
                  return 1;
                }
                else if (!a.product.type && b.product.type) {
                  return -1;
                }
                else {
                  if (a.product.code >= b.product.code) {
                    return 1;
                  }
                  else {
                    return -1;
                  }
                }

              });

              this.setTableDataSource();
              this.dataService.blockUIforLoad = false;

            });
          }
        },
          error => {
            this.dataService.blockUIforLoad = false;
            console.log(error);
          });
      }
    });
  }

  isAllActive(dsource) {
    for(let item of dsource.data){
      if(!item.active){
        return false;
      }
    }

    return true;
  }

  isIndeterminateActive(dsource) {
    let active = 0;
    let inactive = 0;

    for(let item of dsource.data){
      if(!item.active){
        active++;
      }
      else{
        inactive++;
      }
    }

    return active != 0 && inactive != 0;
  }

  changeAllSelection(dsource, status) {
    for(let item of dsource.data){
      item.active = status;
    }
  }

  countActiveSate(state: boolean) {
    let count = 0;

    if (this.inventory) {
      for (let item of this.inventory) {
        if (item.active == state)
          count++;
      }
    }

    return count;
  }

  private setTableDataSource() {
    this.inventoryDataSources = {};
    this.inventories = {};

    //split inventory
    for (let ptype of this.productService.productTypes) {
      this.inventories[ptype.code] = [];
    }
    this.inventories["__all__"] = [];
    this.inventories["__custom__"] = [];

    for (let item of this.inventory) {
      let code = item.product.is_kansai ? item.product.type.code : "__custom__";
      this.inventories[code].push(item);
      this.inventories["__all__"].push(item);
    }

    for (let key in this.inventories) {
      this.inventoryDataSources[key] = new MatTableDataSource(this.inventories[key]);

      //this.inventoryDataSources[key].paginator = this.paginator;
      this.inventoryDataSources[key].sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'active': return item.active;
          case 'product_type': return item.product.type.code;
          case 'product_code': return item.product.code;
          case 'product_code_short': return item.product.code_short;
          case 'product_name': return item.product.name;
          case 'unit': return item.product.unit;
          case 'unit_cost': return item.unit_cost;
          default: return item[property];
        }
      };
      //this.inventoryDataSources[key].sort = this.sort;

      this.inventoryDataSources[key].filter = undefined;

      //custom filtering with multiple columns
      this.inventoryDataSources[key].filterPredicate = (data: InventoryItem, filter: string) => {
        let result = true;

        if (this.tableFilter_productTypeCode) {
          result = result && data.product.type.code.toLowerCase().indexOf(this.tableFilter_productTypeCode.trim().toLowerCase()) >= 0
        }
        if (this.tableFilter_productCode) {
          result = result && data.product.code.toLowerCase().indexOf(this.tableFilter_productCode.trim().toLowerCase()) >= 0
        }
        if (this.tableFilter_productName) {
          result = result && data.product.name.toLowerCase().indexOf(this.tableFilter_productName.trim().toLowerCase()) >= 0
        }

        return result;
      };
    }
  }

  editStart() {
    this.isEdit = true;

    //set temporary variables
    for (let item of this.inventory) {
      item['_o_active'] = item.active;
      item['_o_unit_cost'] = item.unit_cost;
    }
  }

  editDiscard() {
    this.isEdit = false;

    //undo changes
    for (let item of this.inventory) {
      item.active = item['_o_active'];
      item.unit_cost = item['_o_unit_cost'];
    }
  }

  save() {
    let itemsUpdate = [];
    let itemsAdd = [];
    let itemsDelete = [];

    for (let item of this.inventory) {
      if (item.active != item['_o_active']) {
        if (item.active) {
          //if cost is not set, default is 0.0
          if (!item.unit_cost) {
            item.unit_cost = 0.0;
          }

          itemsAdd.push(item);
        }
        else {
          itemsDelete.push(item);
        }
      }
      else {
        if (item.active) {
          if (item.unit_cost != item['_o_unit_cost']) {
            itemsUpdate.push(item);
          }
        }
      }
    }

    if ((itemsUpdate.length + itemsAdd.length + itemsDelete.length) > 0) {

      let dyntext = "";
      if (itemsUpdate.length > 0) {
        dyntext += "Updated: " + itemsUpdate.length;
      }
      if (itemsAdd.length > 0) {
        if (dyntext.length > 0) {
          dyntext += ", ";
        }

        dyntext += "Added: " + itemsAdd.length;
      }
      if (itemsDelete.length > 0) {
        if (dyntext.length > 0) {
          dyntext += ", ";
        }
        dyntext += "Deleted: " + itemsDelete.length;
      }

      let text = "Do you want to save these changes (" + dyntext + ")?";

      let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
        data: {
          'title': "Update Inventory",
          'text': text,
          'buttonPrimary_text': "Yes",
          'buttonSecondary_text': "No"
        }
      });

      dialogRef.componentInstance.onPrimary.subscribe(result => {
        //loading indicator
        this.dataService.blockUIforLoad = true;

        this.inventoryService.updateInventory(this.bodyshop.id, itemsUpdate, itemsAdd, itemsDelete).subscribe(updatedInventory => {

          //upate values with actual server values
          for (let newItem of updatedInventory) {
            for (let oldItem of this.inventory) {
              if (newItem.product.id == oldItem.product.id) {
                oldItem.unit_cost = newItem.unit_cost;
                oldItem.active = true;
              }
            }
          }

          for (let oldItem of this.inventory) {
            let found = false;
            for (let newItem of updatedInventory) {
              if (newItem.product.id == oldItem.product.id) {
                found = true;
                break;
              }
            }

            if (!found) {
              oldItem.active = false;
              oldItem.unit_cost = undefined;
            }
          }

          //reset tmp values
          this.editStart();

          //in case the inventory of the current bodyshop has been updated
          if (this.bodyshop.id == this.userService.currentUser.currentBodyshop.id) {
            this.inventoryService.refreshData();
          }

          this.isEdit = false;

          //loading indicator
          this.dataService.blockUIforLoad = false;
        }, error => {
          //loading indicator
          console.log(error);
          this.dataService.blockUIforLoad = false;
        });
      });

    }
  }


  log(item?) {
    if (item)
      console.log(item)
    else
      console.log(this);
  }
}
