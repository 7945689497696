import { VehicleService } from './vehicle.service';
import { ProductType, CarManufacturer, Colour } from './../model/model';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Injectable()
export class ColourService {
  private api_endpoint = '/colour';
  private full_api_url;

  private api_delim_search_name = "/name/";
  private api_delim_search_code = "/code/";
  private api_delim_car_manufacturer = "/make/";
  private api_delim_product_type = "/product/";

  constructor(private dataService: DataService, private vehicleService: VehicleService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getColours(carManufacturer?: CarManufacturer, productType?: ProductType, search_code?: string, search_name?: string) {
    let requestUrl = this.full_api_url;

    if (carManufacturer) {
      requestUrl += this.api_delim_car_manufacturer + carManufacturer.id;
    }
    if (productType) {
      requestUrl += this.api_delim_product_type + productType.id;
    }
    if (search_code && search_code.trim().length > 0) {
      requestUrl += this.api_delim_search_code + search_code.trim();
    }
    if (search_name && search_name.trim().length > 0) {
      requestUrl += this.api_delim_search_name + search_name.trim();
    }

    return this.dataService.getRequest(requestUrl).map(response => {
      let colours: Colour[] = [];
      if (response && response['data']) {
        for (let item of response['data']) {
          colours.push(this.buildColour(item));
        }

        return colours;
      }
    });
  }

  getColour(id: number) {
    let requestUrl = this.full_api_url + '/' + id;

      return this.dataService.getRequest(requestUrl).map(response => {
        if (response) {
          return this.buildColour(response);
        }
      });
  }

  buildColour(response){
    if(!response){
      response = {};
    }
    let colour: Colour = new Colour();
    colour.id = response['id'];
    colour.name = response['name'];
    colour.code = response['code'];
    colour.carManufacturer = this.vehicleService.getCarManufacturerById(response['car_manufacturer']);

    return colour;
  }


  crateNewColour(colour:Colour){
    let requestUrl = this.full_api_url;
    let requestBody = {
      'car_manufacturer': colour.carManufacturer.id,
      'code': colour.code,
      'name': colour.name
    };

    return this.dataService.postRequest(requestUrl, requestBody);
  }


  removeWhiteSpace(str:string){
    let tmp_str = str.trim();
    while(tmp_str.includes(" ")){
      tmp_str = tmp_str.trim().replace(" ", "");
    }

    return tmp_str;
  }
}
