<div class="coat-container" *ngIf="cMatch && vcoat">
  <div class="batch-container">
    <button mat-stroked-button [disabled]="!job().isEditable()" color="primary" class="add-batch" (click)="createBatchForCoat(cMatch, vcoat.coat)">
      <i class="material-icons">
        add
      </i>
    </button>

    <div class="batch-container-substrate" *ngIf="job().getBatchesForCoatAndSubstrate(vcoat.coat, null, cMatch.colour).length > 0">
      <div class="batch-container-substrate-name">
        <div class="substrate-name">Undefined</div>
        <div class="substrate-target">
        </div>
        <div class="substrate-actual"> </div>
        <div class="substrate-diff"> </div>
      </div>
      <div class="batch-card-container">
        <ng-container *ngFor="let batch of job().getBatchesForCoatAndSubstrate(vcoat.coat, null, cMatch.colour)">
          <app-batch-card [batch]="batch" *ngIf="!batch.is_discarded && !batch.is_matchingBatch" [class.selected]="_selectedBatch == batch" (click)="selectBatch(batch)" class="batch-card"(onDelete)="removeBatch(batch)"></app-batch-card>
        </ng-container>
      </div>
    </div>


    <ng-container *ngFor="let substrate of variantService.substrates">
      <div class="batch-container-substrate" *ngIf="job().getBatchesForCoatAndSubstrate(vcoat.coat, substrate, cMatch.colour).length > 0">
        <div class="batch-container-substrate-name">
          <div class="substrate-name">{{substrate.name}}</div>
          <div class="substrate-target">
          </div>
          <div class="substrate-actual"></div>
          <div class="substrate-diff"></div>
        </div>
        <div class="batch-card-container">
          <ng-container *ngFor="let batch of job().getBatchesForCoatAndSubstrate(vcoat.coat, substrate, cMatch.colour)">
            <app-batch-card [batch]="batch" *ngIf="!batch.is_discarded && !batch.is_matchingBatch" [class.selected]="_selectedBatch == batch" (click)="selectBatch(batch)" class="batch-card"(onDelete)="removeBatch(batch)"></app-batch-card>
            
          </ng-container>
        </div>
      </div>
    </ng-container>



  </div>

  <div class="mix-assistant-container">
    <!-- create new component for each -->
    <ng-container *ngFor="let batch of job().getBatchesForCoat(vcoat.coat, cMatch.colour)">
      <app-mixer [batch]="_selectedBatch" *ngIf="_selectedBatch == batch" (onSplit)="splitBatch($event)"></app-mixer>
    </ng-container>
  </div>
</div>