import { ColourMatch, Variant } from './../../model/model';
import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-job-variant-selection',
  templateUrl: './job-variant-selection.component.html',
  styleUrls: ['./job-variant-selection.component.css']
})
export class JobVariantSelectionComponent implements OnInit {
  @Output() onVariantChange = new EventEmitter<Variant>();

  public jobColour:ColourMatch;
  constructor(
    public dialogRef: MatDialogRef<JobVariantSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
    this.jobColour = data['jobColour'];
   }

  changeVariant(variant:Variant){
    this.onVariantChange.emit(variant);
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }
}