<ng-container *ngIf="variant && variant.coats && variant.coats.length > 0">
  <div id="container">
    <div id="variant-top-header" *ngIf="!hideHeader">
      <div id="variant-top-header-left">
        <h2>Variant Information</h2>
      </div>
      <div id="variant-button">
        <!-- Secondary Button -->
        <ng-container *ngIf="secondaryButtonLabel">
          <button mat-button (click)="secondaryAction(variant)">{{secondaryButtonLabel}}</button>
        </ng-container>

        <!-- Primary Button -->
        <ng-container *ngIf="primaryButtonLabel">
          <button mat-button color="primary" (click)="primaryAction(variant)">{{primaryButtonLabel}}</button>
        </ng-container>
      </div>
    </div>
    <div id="variant-info-container">
      <div id="variant-top-header-right">
        <div class="variant-info-header">Product Type</div>
        <div class="variant-info-content">
          {{variant.product_type.code}}
          <!-- - {{variant.product_type.name}} -->
        </div>
      </div>
      <div id="variant-info-car-manufacturer" *ngIf="variant.colour && variant.colour.carManufacturer">
        <div class="variant-info-header">Car Make</div>
        <div class="variant-info-content">{{variant.colour.carManufacturer.name}}</div>
      </div>
      <div id="variant-info-colour" *ngIf="variant.colour">
        <div class="variant-info-header">Colour</div>
        <div class="variant-info-content">{{ variant.colour.code }} - {{variant.colour.name}}</div>
      </div>

      <div id="variant-info-name">
        <div class="variant-info-header">Variant</div>
        <div class="variant-info-content">{{variant.name}}</div>
      </div>


    </div>

    <ng-container *ngIf="!variant.isWithRatios()">
      <mat-spinner diameter="25"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="variant.isWithRatios()">

      <!--
      <mat-tab-group>
        <mat-tab *ngFor="let coat of variant.coats">
          <ng-template matTabLabel>
            {{ coat.coat.name }}
            <ng-container *ngIf="userService.initialised | async">
              <ng-container *ngIf="userService.currentUser.isAllowedReports(userService.currentUser.currentBodyshop.id)">
                <br> 100g: {{inventoryService.costForFormula(coat, 0.1) | number:'1.2-2' }} {{userService.currentUser.tenant.currency.iso_4217}}
              </ng-container>
            </ng-container>
            
          </ng-template>
          <app-formula-detail [coat]="coat" (onNavigated)="formulaExported()"></app-formula-detail>
        </mat-tab>
      </mat-tab-group>
      -->

      <mat-accordion>
        <mat-expansion-panel [expanded]="coat == variant.coats[0]" *ngFor="let coat of variant.coats" class="coat-exp-panel">
          <mat-expansion-panel-header>
            <mat-panel-title class="coat-exp-panel-title">
              {{ coat.coat.name }}
            </mat-panel-title>
            <mat-panel-description>
              <ng-container *ngIf="userService.initialised | async">
                <ng-container *ngIf="userService.currentUser.isAllowedReports(userService.currentUser.currentBodyshop.id)">
                  100g: {{inventoryService.costForFormula(coat, 0.1) | number:'1.2-2' }}
                  {{userService.currentUser.tenant.currency.iso_4217}}
                </ng-container>
              </ng-container>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-formula-detail [coat]="coat" (onNavigated)="formulaExported()"></app-formula-detail>
        </mat-expansion-panel>
      </mat-accordion>


    </ng-container>
  </div>
</ng-container>