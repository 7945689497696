<div class="batch-card" *ngIf="batch" [class.deleted]="batch.is_discarded" [class.locked]="batch.is_locked && !batch.is_discarded">
  <div class="batch-card-amount">
    <div class="icons">
      <mat-icon *ngIf="!batch.id" (click)="delete()" matTooltip="Remove" class="batch-icon">
        clear
      </mat-icon>
      <mat-icon *ngIf="batch.is_locked && !batch.is_discarded"  matTooltip="Done" class="batch-icon icon-done">
        brightness_1
      </mat-icon>
      <mat-icon *ngIf="!batch.is_locked && !batch.is_discarded"  matTooltip="Open" class="batch-icon icon-open">
          panorama_fish_eye
      </mat-icon>
    </div>

    {{toolkit.precision(batch.getTotalQuantityInKg() * 1000, 2) | number:'1.2-2'}}g
  </div>
  <div class="batch-card-details" *ngIf="batch.baseProduct">
    {{batch.baseProduct.name}}
  </div>
</div>