<mat-progress-bar mode="query" *ngIf="isRetrievingColours"></mat-progress-bar>
<mat-progress-bar mode="query" *ngIf="isRetrievingVariants"></mat-progress-bar>

<mat-card *ngIf="isError">
  <mat-card-header>
    <div mat-card-avatar>
      <fa name="exclamation-triangle"></fa>
    </div>
    <mat-card-title>An error has occurred </mat-card-title>
    <mat-card-subtitle>HTTP {{ error.status }}: {{ error.statusText }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      {{ error.error.message }}
    </p>
  </mat-card-content>
</mat-card>


<mat-horizontal-stepper #stepper (selectionChange)="stepperSelectionChange()">

  <!-- ======== -->
  <!-- PRODUCT TYPE STEP -->
  <!-- ======== -->
  <mat-step [completed]="false">
    <ng-template matStepLabel>
      <ng-container>
        Product Type
      </ng-container>
      <ng-container *ngIf="selected_productType">
        <br> {{ selected_productType.name }}
      </ng-container>
    </ng-template>

    <div class="card-container">
      <mat-card *ngFor='let product_type of productService.productTypes' class="product-type-card" [class.selected]="selected_productType == product_type"
        (click)="selectProductType(product_type)">
        {{ product_type.name }}
        <br> ({{product_type.code}})
      </mat-card>
    </div>
  </mat-step>

  <!-- ======== -->
  <!-- CAR MANUFACTURER STEP -->
  <!-- ======== -->
  <mat-step [completed]="false">
    <ng-template matStepLabel>
      <ng-container>
        Car Make
      </ng-container>
      <ng-container *ngIf="selected_carManufacturer">
        <br> {{ selected_carManufacturer.name }}
      </ng-container>
    </ng-template>

    <div class="filter">
      <input matInput placeholder="Filter" #cManFilter>
    </div>

    <div class="card-container">
      <ng-container *ngFor='let man of vehicleService.carManufacturers'>
        <mat-card *ngIf="man.name.toLowerCase().includes(cManFilter.value.toLowerCase())" class="car-manufacturer-card" [class.selected]="selected_carManufacturer == man"
          (click)="selectCarManufacturer(man)">
          {{ man.name }}
        </mat-card>
      </ng-container>
    </div>
  </mat-step>

  <!-- ======== -->
  <!-- COLOUR STEP -->
  <!-- ======== -->
  <mat-step [completed]="false">
    <ng-template matStepLabel>
      <ng-container>
        Colour
      </ng-container>
      <ng-container *ngIf="selected_colour">
        <br> {{ selected_colour.code }}
      </ng-container>
    </ng-template>


    <div class="filter">
      <input matInput placeholder="Filter" #colourFilter>
    </div>

    <div class="card-container" *ngIf="!isRetrievingColours">
      <ng-container *ngFor='let colour of colours'>
        <mat-card *ngIf="colour.code.toLowerCase().includes(colourFilter.value.toLowerCase()) || colour.name.toLowerCase().includes(colourFilter.value.toLowerCase())"
          class="colour-card" [class.selected]="selected_colour == colour" (click)="selectColour(colour)">
          <div class="colour-code">{{ colour.code }}</div>
          <div class="colour-name">{{ colour.name }}</div>
        </mat-card>

      </ng-container>
    </div>

    <ng-container *ngIf="!isRetrievingColours && colours.length == 0">
      nothing found
    </ng-container>

  </mat-step>

  <!-- ======== -->
  <!-- VARIANT STEP -->
  <!-- ======== -->
  <mat-step [completed]="false">
    <ng-template matStepLabel>
      <span mat-line>Variant</span>
    </ng-template>

    <ng-container *ngIf="!isRetrievingVariants && variants.length == 0">
      nothing found
    </ng-container>

    <mat-table matSort role="treegrid" #varianttable [dataSource]="variantDataSource" id="variant-table" [class.invisible]="isRetrievingVariants || variants.length == 0">
      <!-- Column -->
      <ng-container matColumnDef="variant">
        <mat-header-cell mat-sort-header class="variant-table-cell-name" *matHeaderCellDef>
          Variant
        </mat-header-cell>
        <mat-cell class="variant-table-cell-name" [class.not-available]="!inventoryService.allVariantComponentsInInventory(element)"
          *matCellDef="let element">
          {{element.name}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="coat">
        <mat-header-cell *matHeaderCellDef>
          Components
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.coats">
            <div *ngFor="let coat of element.coats" class="coat-container">
              <div class="coat-name">
                {{ coat.coat.code.toUpperCase() }}
              </div>
              <div class="component-container">
                <div *ngFor="let compoment of coat.formula" class="component-element" [class.not-available]="inventoryService.getByProductId(compoment.product.id) == null">
                  {{ compoment.product.code_short }}
                </div>
              </div>
            </div>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="variant-table-cell-actions" *matHeaderCellDef>

        </mat-header-cell>
        <mat-cell class="variant-table-cell-actions" *matCellDef="let element">
          <button mat-button (click)="openVariantDialog(element)">Details</button>
          <button mat-button color="primary">Create Job</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="variantTableColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: variantTableColumns;" [class.selected]="row == selected_variant" (click)="openVariantDialog(row)"></mat-row>
    </mat-table>

    <mat-paginator [class.invisible]="isRetrievingVariants || variants.length == 0" #variantPaginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]">
    </mat-paginator>

  </mat-step>

</mat-horizontal-stepper>