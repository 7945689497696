<div id="klayout-container">
  <div id="klayout-left">
    <div id="klayout-left-top" class="klayout-top">
      <!-- Section Header -->

    </div>
    <div id="klayout-left-bottom">
      <!-- Navigation Items -->
      <mat-nav-list class="nav-menu-list" *ngIf="userService.initialised | async">
        <a mat-list-item [routerLink]="['bodyshops']" *ngIf="userService.currentUser.isPermitted('tenant', 'bodyshops')" class="nav-menu-item" routerLinkActive="selected">
          Bodyshops
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item [routerLink]="['users']" *ngIf="userService.currentUser.isPermitted('tenant', 'users')" class="nav-menu-item" routerLinkActive="selected">
          Users
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item [routerLink]="['custom-products']" *ngIf="userService.currentUser.isPermitted('tenant', 'custom-products')" class="nav-menu-item" routerLinkActive="selected">
          Products
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </div>
  </div>

  <div id="klayout-right">
    <router-outlet></router-outlet>
  </div>
</div>