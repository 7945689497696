import { DataService } from './../../services/data.service';
import { BatchService } from './../../services/batch.service';
import { ColourMatchService } from './../../services/colour-match.service';
import { Coat, ColourMatch, VariantCoat, Batch, Job } from './../../model/model';
import { Component, OnInit, Input } from '@angular/core';
import { Toolkit } from '../../../toolkit/toolkit';

@Component({
  selector: 'app-formula-tuner',
  templateUrl: './formula-tuner.component.html',
  styleUrls: ['./formula-tuner.component.css']
})
export class FormulaTunerComponent implements OnInit {
  @Input() colourMatch: ColourMatch;
  @Input() coat: Coat;
  @Input() job: Job;

  public vCoat: VariantCoat;

  public toolkit = new Toolkit();
  public _selectedBatch:Batch;

  constructor(public colourMatchService: ColourMatchService,
    private batchService: BatchService,
    private dataService: DataService) { }

  ngOnInit() {
    this.vCoat = this.colourMatch.variant.getCoat(this.coat);
  }

  hasDevBatch() {

  }

  changeDevelopmentBatch(batch: Batch) {
    this.colourMatchService.changeDevelopmentBatch(this.colourMatch, this.coat, batch).subscribe(cm => {
      console.log(cm);
    });
  }

  splitDevBatch(splitEvent) {
    this.dataService.blockUIforLoad = true;

    let batch: Batch = splitEvent['batch'];
    let split_kg = splitEvent['split_kg'];
    let detailData = {
      'is_matchingBatch': true,
      'colour_id': batch.colour_id,
      'colourMatch_id': this.colourMatch.id,
      'system': this.colourMatch.variant.system
    };

    if (this.job)
      detailData['job_id'] = this.job.id;


    this.batchService.splitBatch(batch, split_kg, detailData).subscribe(batches => {
      if (batches && batches.length == 2) {
        let bOld = batches[0];
        let bNew = batches[1];

        this.job.replaceBatch(bOld);
        this.job.batches.push(bNew);

        this.colourMatch.batches_dev[this.coat.id] = bOld;

        this.colourMatchService.nextFormula(this.colourMatch, this.coat, bNew).subscribe(cm => {
          console.log(cm);
          //set new formula
          this.colourMatch.variant.getCoat(this.coat).formula = cm.variant.getCoat(this.coat).formula;
          this.colourMatch.variant.getCoat(this.coat).history = cm.variant.getCoat(this.coat).history;

          this.colourMatch.relinkBatches(this.job.batches);
          
          this._selectedBatch = batches[1];
          this.dataService.blockUIforLoad = false;
        }, 
        error => {
          this.dataService.blockUIforLoad = false;
          throw error;
        })
      }
      
    }, error => {
      this.dataService.blockUIforLoad = false;
      throw error;
    });
  }

  start() {
    let batch = new Batch(this.vCoat.formula, this.colourMatch.variant.product_type, this.coat, this.colourMatch.variant.system);
    batch.job_id = this.colourMatch.job_id;
    batch.colour_id = this.colourMatch.colour.id;
    batch.colourMatch_id = this.colourMatch.id;
    batch.is_matchingBatch = true;

    batch.quantity_target_kg = 0.2;

    this.colourMatch.batches_dev[this.coat.id] = batch;

    this._selectedBatch = batch;
  }


  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }

}
