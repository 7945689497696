<div id="container" *ngIf="userService.initialised | async">

    <div class="bi-section">
        <div class="bi-section-title">KPIs</div>
        <div class="bi-row">

            <div class="kpi-container" *ngIf="userService.currentUser.isAllowedReports(job().bodyshop.id) && job().benchmark_amount">
                <div class="kpi-value total" [class.nok]="job().getTotalCost() - job().benchmark_amount > 0" [class.ok]="job().getTotalCost() - job().benchmark_amount < 0">
                    {{job().benchmark_amount - job().getTotalCost() | number:'1.2-2'}}
                    {{userService.currentUser.tenant.currency.iso_4217}}
                </div>
                <div class="kpi-name">
                    Benchmark vs. Actual
                </div>
            </div>

            <div class="kpi-container">
                <div class="kpi-value total">
                    {{job().getSelectedPanels().length}}
                </div>
                <div class="kpi-name">
                    Panels
                </div>
            </div>

            <div class="kpi-container" *ngIf="userService.currentUser.isAllowedReports(job().bodyshop.id) && job().getSelectedPanels().length > 0">
                <div class="kpi-value total">
                    {{job().getTotalCost() / job().getSelectedPanels().length | number:'1.2-2'}}
                    {{userService.currentUser.tenant.currency.iso_4217}}
                </div>
                <div class="kpi-name">
                    Cost per panel
                </div>
            </div>

            <div class="kpi-container" *ngIf="userService.currentUser.isAllowedReports(job().bodyshop.id)">
                <div class="kpi-value total">
                    {{job().getCountRecalculations()}}
                </div>
                <div class="kpi-name">
                    Recalculations
                </div>
            </div>

        </div>
    </div>

    <!--
    <div class="bi-section">
            <div class="bi-section-title">Split by Status</div>
            <div class="bi-row">
                <table class="table-split">
                    <tr>
                        <th></th>
                        <th>Total</th>
                        <th></th>
                        <th>In Use</th>
                        <th></th>
                        <th></th>
                        <th>Colour Matching</th>
                        <th></th>
                        <th></th>
                        <th>Wasted</th>
                        <th></th>
                    </tr>

                    <tr>
                        <td>Consumption</td>
                        <td>{{job().getTotalConsumption() * 1000 | number:'1.2-2'}}g</td>
                        <td>=</td>
                        <td> {{job().getTotalConsumptionInUse() * 1000 | number:'1.2-2'}}g</td>
                        <td>{{job().getTotalConsumptionInUse() / job().getTotalConsumption() | number:'1.2-2'}}% </td>
                        <td>+</td>
                        <td>{{job().getConsumptionForMatchingBatches() * 1000 | number:'1.2-2'}}g</td>
                        <td>{{job().getConsumptionForMatchingBatches() / job().getTotalConsumption() | number:'1.2-2'}}%</td>
                        <td>+</td>
                        <td>{{job().getTotalConsumptionWaste() * 1000 | number:'1.2-2'}}g</td>
                        <td>{{job().getTotalConsumptionWaste() / job().getTotalConsumption() | number:'1.2-2'}}%</td>
                    </tr>
                </table>
            </div>
        </div>
-->

    <div class="bi-section">
        <div class="bi-section-title">Split by Status</div>
        <div class="bi-row">
            <div class="kpi-container-status">
                <div class="kpi-value total">
                    {{job().getTotalConsumption() * 1000 | number:'1.2-2'}}g
                </div>
                <div class="kpi-name">
                    Total (g)
                </div>
            </div>

            <div class="math-icon">
                =
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getTotalConsumptionInUse() * 1000 | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getTotalConsumptionInUse() / job().getTotalConsumption() | number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Applied (g)
                </div>
            </div>

            <div class="math-icon">
                +
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getTotalConsumptionWaste() * 1000 | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getTotalConsumptionWaste() / job().getTotalConsumption() | number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Wasted (g)
                </div>
            </div>

            <div class="math-icon">
                +
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getConsumptionForMatchingBatches() * 1000 | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getConsumptionForMatchingBatches() / job().getTotalConsumption() |
                        number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Colour Matching (g)
                </div>
            </div>
        </div>
        <div class="bi-row" *ngIf="userService.currentUser.isAllowedReports(job().bodyshop.id)">
            <div class="kpi-container-status">
                <div class="kpi-value total">
                    {{job().getTotalCost() | number:'1.2-2'}}
                    {{userService.currentUser.tenant.currency.iso_4217}}
                </div>
                <div class="kpi-name">
                    Total ({{userService.currentUser.tenant.currency.iso_4217}})
                </div>
            </div>

            <div class="math-icon">
                =
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getTotalCostInUse() | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getTotalCostInUse() / job().getTotalCost() | number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Applied ({{userService.currentUser.tenant.currency.iso_4217}})
                </div>
            </div>

            <div class="math-icon">
                +
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getTotalCostWaste() | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getTotalCostWaste() / job().getTotalCost() | number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Wasted ({{userService.currentUser.tenant.currency.iso_4217}})
                </div>
            </div>

            <div class="math-icon">
                +
            </div>

            <div class="kpi-container-status">
                <div class="kpi-value">
                    {{job().getCostForMatchingBatches() | number:'1.2-2'}}
                    <span class="kpi-value-percentage">
                        ({{100 * job().getCostForMatchingBatches() / job().getTotalCost() | number:'1.2-2'}}%)
                    </span>
                </div>
                <div class="kpi-name">
                    Colour Matching ({{userService.currentUser.tenant.currency.iso_4217}})
                </div>
            </div>
        </div>

    </div>

    <div class="bi-section">
        <div class="bi-section-title">Analysis</div>
        <div class="bi-row row-align-top">
            <div id="left">
                <div class="row" [class.selected]="_selectedData == item" [class.selected-row]="_selectedData == item"
                    *ngFor="let item of data" (click)="getChartData(item)">
                    <div class="row-heading">
                        <div class="main-heading">
                            {{item.heading}}
                        </div>
                        <div class="sub-heading" *ngIf="item.subheading">
                            {{item.subheading}}
                        </div>
                    </div>
                    <div class="row-contents">
                        <div class="consumption">
                            <div class="value">
                                {{item.consumption | number:'1.2-2'}}
                            </div>
                            <div class="name">
                                grammes
                            </div>
                        </div>
                        <div class="cost" *ngIf="userService.currentUser.isAllowedReports(job().bodyshop.id)">
                            <div class="value">
                                {{item.cost | number:'1.2-2'}}
                            </div>
                            <div class="name">
                                {{userService.currentUser.tenant.currency.iso_4217}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="right">
                <mat-tab-group mat-stretch-tabs>
                    <mat-tab label="Consumption">
                        <div class="chart-container" *ngIf="chartData_consumption">
                            <mat-expansion-panel class="chart-panel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Chart
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                    <div class="chart">
                                        <ngx-charts-bar-vertical [results]="chartData_consumption" [yAxis]="true"
                                            [showYAxisLabel]="true" yAxisLabel="Grammes">
                                        </ngx-charts-bar-vertical>
                                    </div>
                                </ng-template>

                            </mat-expansion-panel>


                            <div class="summary-table">
                                <table mat-table [dataSource]="dataSource_consumption" class="table-data">
                                    <ng-container matColumnDef="product_code">
                                        <th mat-header-cell *matHeaderCellDef> Code </th>
                                        <td mat-cell *matCellDef="let row"> {{row._product.code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="product_name">
                                        <th mat-header-cell *matHeaderCellDef> Product </th>
                                        <td mat-cell *matCellDef="let row"> {{row._product.name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="cell-value-width"> Consumption
                                            in g
                                        </th>
                                        <td mat-cell *matCellDef="let row" class="cell-value cell-value-width">
                                            {{row.value
                                            |
                                            number:'1.2-2'}} </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="['product_code', 'product_name', 'value']"
                                        class="header-row"></tr>
                                    <tr mat-row *matRowDef="let row; columns: ['product_code', 'product_name', 'value'];"></tr>
                                </table>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Cost" [disabled]="!userService.currentUser.isAllowedReports(job().bodyshop.id)">
                        <div class="chart-container" *ngIf="chartData_cost && userService.currentUser.isAllowedReports(job().bodyshop.id)">
                            <mat-expansion-panel class="chart-panel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Chart
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <ng-template matExpansionPanelContent>
                                    <div class="chart">
                                        <ngx-charts-bar-vertical [results]="chartData_cost" [yAxis]="true"
                                            [showYAxisLabel]="true" [yAxisLabel]="userService.currentUser.tenant.currency.iso_4217">
                                        </ngx-charts-bar-vertical>
                                    </div>
                                </ng-template>

                            </mat-expansion-panel>


                            <div class="summary-table">
                                <table mat-table [dataSource]="dataSource_cost" class="table-data">
                                    <ng-container matColumnDef="product_code">
                                        <th mat-header-cell *matHeaderCellDef> Code </th>
                                        <td mat-cell *matCellDef="let row"> {{row._product.code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="product_name">
                                        <th mat-header-cell *matHeaderCellDef> Product </th>
                                        <td mat-cell *matCellDef="let row"> {{row._product.name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="cell-value-width"> Cost in
                                            {{userService.currentUser.tenant.currency.iso_4217}}</th>
                                        <td mat-cell *matCellDef="let row" class="cell-value cell-value-width">
                                            {{row.value | number:'1.2-2'}}
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="['product_code', 'product_name', 'value']"
                                        class="header-row"></tr>
                                    <tr mat-row *matRowDef="let row; columns: ['product_code', 'product_name', 'value'];"></tr>
                                </table>

                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>





            </div>
        </div>
    </div>
</div>