import { UserService } from './user.service';
import { CountryService } from './country.service';
import { Bodyshop } from './../model/model';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageBodyshopService {
  private api_endpoint = '/manage/bodyshop';
  private full_api_url;

  constructor(public dataService: DataService,
    public userService:UserService,
    public countryService: CountryService) {
    this.full_api_url = this.dataService.getUrl() + this.api_endpoint;
  }

  getBodyshops(): Observable<Bodyshop[]> {
    return this.dataService.getRequest(this.full_api_url).map(response => {
      let bshops: Bodyshop[] = [];

      if (response && response['data']) {
        for (let bshop of response['data']) {
          bshops.push(this.buildBodyshop(bshop));
        }
      }

      return bshops;
    });
  }

  getBodyshop(id: number): Observable<Bodyshop> {
    return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
      if (response) {
        return this.buildBodyshop(response);
      }
    });
  }

  saveBodyshop(bshop: Bodyshop): Observable<Bodyshop> {
    let request;
    let requestBody = {
      'id_custom': bshop.id_custom,
      'name': bshop.name,
      'address': {
        'line_1': bshop.address['line_1'],
        'line_2': bshop.address['line_2'],
        'post_code': bshop.address['post_code'],
        'city': bshop.address['city'],
        'country': bshop.address['country']['id'],
        'coordinates': bshop.address['coordinates']
      }
    }

    if (bshop.address['line_1'])
      requestBody['address']['line_1'] = bshop.address['line_1'];

    if (bshop.address['line_2'])
      requestBody['address']['line_2'] = bshop.address['line_2'];

    if (bshop.address['post_code'])
      requestBody['address']['post_code'] = bshop.address['post_code'];

    if (bshop.address['coordinates'])
      requestBody['address']['coordinates'] = bshop.address['coordinates'];
      

    if (bshop.id) {
      request = this.dataService.putRequest(this.full_api_url + "/" + bshop.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(this.full_api_url, requestBody);
    }

    return request.map(response => {
      return this.buildBodyshop(response);
    });

  }

  disableBodyshop(bshop:Bodyshop): Observable<Bodyshop>{
    let requestBody = {
      'target': 'bodyshop',
      'action': 'disable'
    }

    return this.dataService.patchRequest(this.full_api_url + "/" + bshop.id, requestBody).map(response => {
      return this.buildBodyshop(response);
    });
  }

  enableBodyshop(bshop:Bodyshop): Observable<Bodyshop>{
    let requestBody = {
      'target': 'bodyshop',
      'action': 'enable'
    }

    return this.dataService.patchRequest(this.full_api_url + "/" + bshop.id, requestBody).map(response => {
      return this.buildBodyshop(response);
    });
  }

  deleteBodyshop(bshop:Bodyshop): Observable<Bodyshop>{
    return this.dataService.deleteRequest(this.full_api_url + "/" + bshop.id).map(response => {
      return this.buildBodyshop(response);
    });
  }

  buildBodyshop(response) {
    return this.userService.buildBodyshop(response);
  }
}
