import { Router } from '@angular/router';
import { ProductService } from './../../../services/product.service';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Product } from '../../../model/model';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-manage-custom-products',
  templateUrl: './manage-custom-products.component.html',
  styleUrls: ['./manage-custom-products.component.css']
})
export class ManageCustomProductsComponent implements OnInit {
  public dataSource: MatTableDataSource<Product>;
  public inventoryTableColumns_custom = ['manufacturer', 'product_category', 'product_code', 'product_name', 'unit', 'operations'];

  public products: Product[];

  constructor(public productService: ProductService,
    public dataService: DataService,
    private router: Router) { }

  ngOnInit() {
    this.dataService.blockUIforLoad = true;
    this.products = [];

    this.productService.initialised.distinctUntilChanged().subscribe(state => {
      if (state) {
        this.productService.refreshData();
        this.productService.initialised.distinctUntilChanged().subscribe(refreshSate => {
          if (refreshSate) {
            for (let product of this.productService.products) {
              if (!product.is_kansai)
                this.products.push(product);
            }

            this.products = this.products.sort((a: Product, b: Product) => {
              if (a.code > b.code) {
                return 1;
              }
              else if (a.code < b.code) {
                return -1;
              }
              return 0;
            });

            this.setTableDataSource();
            this.dataService.blockUIforLoad = false;
          }
        });


      }
    });
  }

  setTableDataSource() {
    console.log(this.products);
    this.dataSource = new MatTableDataSource(this.products);
  }

  navigate(product: Product) {
    this.router.navigate(['manage', 'custom-products', product.id]);
  }
}
