import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-product-type',
  templateUrl: './admin-product-type.component.html',
  styleUrls: ['./admin-product-type.component.css']
})
export class AdminProductTypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
