<ng-container *ngIf="carManufacturer">
  <div id="container">
    <div id="header">
      Enter the following details to create a new Colour. <br>
      Once saved, it will be immediately availalbe throghout your company.
    </div>
    <div id="fields">
      <mat-form-field id="field-code" class="spacer-right">
        <input matInput placeholder="Colour Code" #colourCodeInput required>
      </mat-form-field>
      <mat-form-field id="field-name" class="spacer-right">
        <input matInput placeholder="Colour Name" #colourNameInput required>
      </mat-form-field>
      <button mat-button color="primary" [disabled]="!colours || exists(colourCodeInput.value)" (click)="save(colourCodeInput.value, colourNameInput.value)">Save</button>
      <button mat-button (click)="close()">Cancel</button>
    </div>
  </div>

  <div id="exist-warning" class="exact-match" *ngIf="exists(colourCodeInput.value)">
    There is already a colour with code {{removeWhiteSpace(colourCodeInput.value)}}. 
  </div>

  <div id="similar-container" *ngIf="similarColours(colourCodeInput.value, colourNameInput.value).length > 0">
    <div id="similar-header">
      Similar Colours
    </div>
    <div id="similar-list">
        <mat-list>
          <ng-container *ngFor="let c of similarColours(colourCodeInput.value, colourNameInput.value)">
            <mat-list-item class="list-item" [class.exact-match]="c == exists(colourCodeInput.value)">
              <div class="item">
                <div class="item-code">{{c.code}}</div>
                <div class="item-name">{{c.name}}</div>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
    </div>
  </div>
</ng-container>
