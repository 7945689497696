import { DataService } from './../../services/data.service';
import { ErrorService } from './../../services/error.service';
import { CacheService } from './../../services/cache.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router/';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatSidenav } from '@angular/material';
import { ErrorNotifierComponent } from '../dialogs/error-notifier/error-notifier.component';

@Component({
    selector: 'app-paintshop',
    templateUrl: './paintshop.component.html',
    styleUrls: ['./paintshop.component.css']
})
export class PaintshopComponent implements OnInit {
    public sidenavToggle = true;
    private _isErrorSheetOpen = false;

    @ViewChild('sidenav') sidenav: MatSidenav;

    constructor(public authService: AuthService,
        public userService: UserService,
        public cacheService: CacheService,
        public router: Router,
        public route: ActivatedRoute,
        public errorService: ErrorService,
        private titleService: Title,
        private errorDialog: MatDialog,
        public dataService: DataService) {

        this.titleService.setTitle("Kansai Paint - Paintshop");

        if (authService.isLoggedIn()) {
            cacheService.refreshData();
        }

    }

    getGreeting(){
        let now = new Date();

        if(now.getHours() > 3 && now.getHours() <= 12){
            return "Good moring";
        }
        else if(now.getHours() > 12 && now.getHours() <= 17){
            return "Good afternoon";
        }
        else {
            return "Good evening";
        }
    }

    ngOnInit() {
    }

    isChildrenActive() {
        return this.route.snapshot.firstChild != null;
    }

    redirect(route: string) {
        this.router.navigate([route]);
    }

    currentYear() {
        return (new Date()).getFullYear();
    }

    openErrorSheet() {
        let sheetRef = this.errorDialog.open(ErrorNotifierComponent);
        sheetRef.afterClosed().subscribe(result => {
            this._isErrorSheetOpen = false;
        });
    }


    log(item?) {
        if (!item) {
          item = this;
        }
        console.log(item);
      }
}
