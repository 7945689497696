<mat-progress-bar *ngIf='progressFormula != 100' mode="buffer" [value]="progressFormula"></mat-progress-bar>



<div id="klayout-container">
  <div id="klayout-left">
    <div id="klayout-left-top" class="klayout-top">
      <!-- Section Header -->
      <a [routerLink]="['/jobs']" class="k-return-link">
        <i class="material-icons">
          arrow_back
        </i>
        &nbsp; back to Jobs
      </a>
    </div>
    <div id="klayout-left-bottom">
      <!-- Navigation Items -->
      <mat-nav-list class="nav-menu-list" *ngIf="jobService.currentJob">
        <div class="section-separator">General</div>

        <a mat-list-item [routerLink]="['detail']" class="nav-menu-item" routerLinkActive="selected">
          Details
        </a>
        <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['summary']" class="nav-menu-item"
          routerLinkActive="selected">
          Summary
        </a>
        <mat-divider></mat-divider>
        <div class="section-separator">Primer</div>

        <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['primer']" class="nav-menu-item"
          routerLinkActive="selected">
          <span matBadge="" matBadgeSize="medium" matBadgeOverlap="false" matBadgeColor="warn">Primer </span>
        </a>
        <mat-divider></mat-divider>
        <div class="section-separator">Colour</div>

        <ng-container *ngFor="let colour of jobService.currentJob.getActiveColours()">
          <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['colour', colour.colour.code]" class="nav-menu-item"
            routerLinkActive="selected">
            {{colour.colour.code}}
          </a>
          <ng-container *ngIf="colour.variant && colour.variant.coats && colour.variant.coats.length > 0">
            <ng-container *ngFor="let coat of colour.variant.coats">


              <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['colour', colour.colour.code, coat.coat.id, 'mix']"
                class="nav-menu-item half-item" routerLinkActive="selected">
                <mat-icon>arrow_right</mat-icon>

                {{coat.coat.id.toUpperCase()}}
              </a>
              <!--
                <div class="coat-item">
                  <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['colour', colour.colour.code, coat.coat.id, 'mix']"
                    class="nav-menu-item half-item" routerLinkActive="selected">
                    <mat-icon>arrow_right</mat-icon>
                    
                    {{coat.coat.id.toUpperCase()}}
                  </a>
                  <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['colour', colour.colour.code, coat.coat.id, 'match']"
                    class="nav-menu-item half-item" routerLinkActive="selected">
                    <i class="fa fa-flask" aria-hidden="true"></i>
                  </a>
                </div>
              -->

            </ng-container>
          </ng-container>


          <mat-divider></mat-divider>
        </ng-container>


        <div class="section-separator">Clear</div>

        <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['clear']" class="nav-menu-item"
          routerLinkActive="selected">
          Clear
        </a>
        <mat-divider></mat-divider>

        <!--
        <div class="section-separator">Misc</div>

        <a mat-list-item *ngIf="jobService.currentJob.id" [routerLink]="['misc']" class="nav-menu-item"
          routerLinkActive="selected">
          Other
        </a>
        <mat-divider></mat-divider>-->
      </mat-nav-list>
    </div>
  </div>
  <div id="klayout-right">
    <div id="klayout-right-top" class="klayout-top">
      <!-- Actions -->
      <div class="kpage-title" *ngIf="job() && job().id_custom">
        Job: {{job().id_custom}}
      </div>

      <div class="kv-spacer"></div>

      <ng-container *ngIf="userService.initialised | async">
        <button mat-button class="button-narrow" (click)="log()" *ngIf="userService.currentUser.is_developer">Log</button>


        <button mat-button class="button-narrow" (click)="archiveJob()" [disabled]="!job() || !job().id || !job().isEditable() || !userService.currentUser.isPermitted('bodyshop', 'job')">
          <i class="material-icons">
            archive
          </i> Close
        </button>
        <button mat-button class="button-narrow" (click)="discardJob()" [disabled]="!job() || !job().id || !job().isEditable() || !userService.currentUser.isPermitted('bodyshop', 'job')">
          <i class="material-icons">
            delete
          </i> Delete
        </button>
        <button mat-button class="button-narrow" color="primary" (click)="saveJob()" [disabled]="!readyForSave() || !job().isEditable() || !userService.currentUser.isPermitted('bodyshop', 'job')">
          <i class="material-icons">
            save
          </i> Save
        </button>
      </ng-container>
    </div>
    <div id="klayout-right-bottom">
      <!-- Contents -->
      <router-outlet *ngIf="jobService.currentJob"></router-outlet>

    </div>
  </div>
</div>