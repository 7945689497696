<div id="klayout-right-top" class="klayout-top">
  <!-- Actions -->
  <div class="kpage-title">
    Manage Custom Products
  </div>

  <div class="kv-spacer"></div>

  <a [routerLink]="['new']">
    <button mat-button class="top-nav-button" color="primary">
      <i class="material-icons">
        add
      </i> New
    </button>
  </a>
</div>
<div id="klayout-right-bottom no-padding">
  <!-- Contents -->
  <div id="container" *ngIf="products && dataSource">
    <mat-table id="inventory-table" [dataSource]="dataSource">

      <!-- Column -->
      <ng-container matColumnDef="manufacturer">
        <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
          Manufacturer
        </mat-header-cell>
        <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
          {{element.manufacturer}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="product_category">
        <mat-header-cell class="table-column-product-type" *matHeaderCellDef>
          Category
        </mat-header-cell>
        <mat-cell class="cf-reset table-column-product-type" *matCellDef="let element">
          {{element.category.name}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="product_code">
        <mat-header-cell class="table-column-product-code" *matHeaderCellDef>
          Code
        </mat-header-cell>
        <mat-cell class="cf-reset table-column-product-code" *matCellDef="let element">
          {{element.code}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="product_name">
        <mat-header-cell class="table-column-product-name" *matHeaderCellDef>
          Name
        </mat-header-cell>
        <mat-cell class="cf-reset table-column-product-name" *matCellDef="let element">
          {{element.name}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="unit">
        <mat-header-cell class="table-column-quantity" *matHeaderCellDef> Unit </mat-header-cell>
        <mat-cell class="cf-reset table-column-quantity" *matCellDef="let element">
          {{element.unit.id}} - {{element.unit.name_singular}}
        </mat-cell>
      </ng-container>

      <!-- Column -->
      <ng-container matColumnDef="operations">
        <mat-header-cell class="table-column-operations" *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell class="cf-reset table-column-operations" *matCellDef="let element">
            <button mat-button class="top-nav-button button-narrow" (click)="navigate(element)">
                <i class="material-icons">
                  edit
                </i> Edit
              </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="inventoryTableColumns_custom"></mat-header-row>
      <mat-row *matRowDef="let row; columns: inventoryTableColumns_custom;" [class.not-active]="!row.active"></mat-row>
    </mat-table>

  </div>
</div>