<div id="klayout-right-top" class="klayout-top">
  <!-- Actions -->

  <button mat-button class="top-nav-button" (click)="delete()" *ngIf="product && product.id">
    <i class="material-icons">
      delete
    </i> Delete
  </button>

  <button mat-button class="top-nav-button" [disabled]="!isReadyForSave()" (click)="save()">
    <i class="material-icons">
      save
    </i> Save
  </button>
</div>
<div id="klayout-right-bottom">
  <!-- Contents -->

  <div id="container">
    <div id="left">
      <div class="section-title heading-h3">
        Product Information
      </div>

      <div class="form" *ngIf="product">
        <mat-form-field appearance="outline" required>
          <mat-label>Category</mat-label>
          <mat-select required [(ngModel)]="product.category">
            <mat-option *ngFor="let category of productService.productCategories" [value]="category">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Prduct Code</mat-label>
          <input matInput type="text" required [(ngModel)]="product.code" [disabled]="product.id">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" required [(ngModel)]="product.name">
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>Manufacturer</mat-label>
          <input matInput type="text" required [(ngModel)]="product.manufacturer">
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>Unit</mat-label>
          <mat-select required [(ngModel)]="product.unit" [disabled]="product.id">
            <mat-option *ngFor="let unit of productService.units" [value]="unit">{{unit.id}} - {{unit.name_singular}}</mat-option>
          </mat-select>
        </mat-form-field>


      </div>

    </div>
    <div id="right">
      <div class="section-title heading-h3">
        Stock Keeping Units (SKU)
      </div>


      <table mat-table [dataSource]="dataSource_sku" class="sku-table">
        <!-- Position Column -->
        <ng-container matColumnDef="pack_size">
          <th mat-header-cell *matHeaderCellDef>Pack Size</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.__editRow">
                <input type="number" min="0" [(ngModel)]="element.pack_size" class="unit-cost-input">
            </ng-container>
            <ng-container *ngIf="!element.__editRow">
              {{element.pack_size}} {{product.unit.name_plural}}
            </ng-container>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef> Code </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.__editRow">
                <input type="text" [(ngModel)]="element.code" class="unit-cost-input">
            </ng-container>
            <ng-container *ngIf="!element.__editRow">
              {{element.code}}
            </ng-container>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.__editRow">
              <button mat-icon-button (click)="addSku()">
                <mat-icon>check</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!element.__editRow">
              <button mat-icon-button (click)="deleteSku(element)">
                <mat-icon>clear</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns_sku"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns_sku;"></tr>
      </table>
    </div>

  </div>

  <div class="error-hint" *ngIf="isInError">
    ERROR: <br>
    {{errorMessage}}
  </div>

</div>