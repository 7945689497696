
<div id="klayout-right-top" class="klayout-top">
  <button mat-button class="top-nav-button" *ngIf="bodyshop && bodyshop.id && !bodyshop.is_deleted && bodyshop.is_active"
    (click)="disableBodyshop()" [disabled]="_isLoading || _isMapDataLoading">
    <i class="material-icons">
      block
    </i> Disable
  </button>

  <button mat-button class="top-nav-button" *ngIf="bodyshop && bodyshop.id && !bodyshop.is_deleted && !bodyshop.is_active"
    (click)="enableBodyshop()" [disabled]="_isLoading || _isMapDataLoading">
    <i class="material-icons">
      unarchive
    </i> Enable
  </button>

  <button mat-button class="top-nav-button" *ngIf="bodyshop && bodyshop.id && !bodyshop.is_deleted" (click)="deleteBodyshop()"
    [disabled]="_isLoading || _isMapDataLoading">
    <i class="material-icons">
      delete
    </i> Delete
  </button>

  <button mat-button class="top-nav-button" color="primary" [disabled]="_isLoading || _isMapDataLoading || !isReadyForSave()"
    (click)="saveBodyshop()" *ngIf="bodyshop && !bodyshop.is_deleted">
    <i class="material-icons">
      save
    </i> Save
  </button>
</div>
<div id="klayout-right-bottom">
  <!-- Contents -->


  <ng-container *ngIf="bodyshop && bodyshop.id">
    <div class="status-box status-nok" *ngIf="!bodyshop.is_active">Disabled</div>
    <div class="status-box status-ok" *ngIf="bodyshop.is_active">Enabled</div>
  </ng-container>

  <div id="container">
    <div id="left">
      <div class="section-title heading-h3">
        Bodyshop Information
      </div>

      <div class="form" *ngIf="bodyshop">
        <div class="row">
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
            <mat-label>ID</mat-label>
            <input matInput type="text" [(ngModel)]="bodyshop.id_custom" #customIdInput required pattern="[^\s]*"
              matTooltip="This field canont contain any white space characters (space, tab, etc.)" [disabled]="_isLoading || !bodyshop || bodyshop.is_deleted ">
            <mat-hint class="error-hint" *ngIf="!isIDClean()">No white pace chracters!</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" class="job-field-margin job-field-medium field-grow">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="bodyshop.name" required [disabled]="_isLoading || !bodyshop || bodyshop.is_deleted ">
          </mat-form-field>
        </div>


        <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
          <mat-label>Address Line 1</mat-label>
          <input matInput type="text" [(ngModel)]="bodyshop.address.line_1" [disabled]="_isLoading || _isMapDataLoading || !bodyshop || bodyshop.is_deleted ">
        </mat-form-field>

        <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
          <mat-label>Address Line 2</mat-label>
          <input matInput type="text" [(ngModel)]="bodyshop.address.line_2" [disabled]="_isLoading || _isMapDataLoading || !bodyshop || bodyshop.is_deleted ">
        </mat-form-field>
        <div class="row">
          <mat-form-field appearance="outline" class="job-field-margin job-field-narrow">
            <mat-label>Post code</mat-label>
            <input matInput type="text" [(ngModel)]="bodyshop.address.post_code" [disabled]="_isLoading || _isMapDataLoading || !bodyshop || bodyshop.is_deleted ">
          </mat-form-field>

          <mat-form-field appearance="outline" class="job-field-margin job-field-medium field-grow">
            <mat-label>City</mat-label>
            <input matInput type="text" [(ngModel)]="bodyshop.address.city" required [disabled]="_isLoading || _isMapDataLoading || !bodyshop || bodyshop.is_deleted ">
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="bodyshop.address.country" required [disabled]="_isLoading || _isMapDataLoading || !bodyshop || bodyshop.is_deleted ">
            <mat-option *ngFor="let country of countryService.countries" [value]="country">{{country.name_common}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="users">
        <div class="section-title heading-h3">
          Users
        </div>
        <mat-table class="user-table" mat-table [dataSource]="userDataSource">
          <!-- Icon -->
          <ng-container matColumnDef="icon">
            <mat-header-cell class="icon-column" *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="icon-column" *matCellDef="let element">
              <i class="material-icons">
                person
              </i>
            </mat-cell>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="employee_nb">
            <mat-header-cell *matHeaderCellDef> Employee Nb. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.employee_nb}} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> eMail </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button matTooltip="Edit" class="top-nav-button button-narrow" (click)="navigateUser(element)">
                <i class="material-icons">
                  edit
                </i> Edit
              </button>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="userColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: userColumns;"></mat-row>
        </mat-table>

      </ng-container>


    </div>
    <div id="right">
      <div class="map-header">
        Click on the map to select a location:
      </div>
      <div id="map" #map></div>
    </div>

  </div>

  <div class="error-hint" *ngIf="_isError">
      ERROR: <br>
      {{_errorMessage}}
    </div>

</div>