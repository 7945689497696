import { CarManufacturer, CarModel, RepairType, CarPanel } from './../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class VehicleService {
  private api_endpoint_cman = '/carmanufacturer';
  private full_api_url_cman;

  private api_endpoint_model = '/model';
  private full_api_url_model;

  public carManufacturers: CarManufacturer[] = [];

  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  constructor(public dataService: DataService) {
    this.full_api_url_cman = dataService.getUrl() + this.api_endpoint_cman;
    this.full_api_url_model = dataService.getUrl() + this.api_endpoint_model;;
  }


  /////////////////////////////////////////
  // Car Manufacturer
  /////////////////////////////////////////
  getCarManufacturerById(id: number) {
    for (let cm of this.carManufacturers) {
      if (cm.id == id) {
        return cm;
      }
    }
  }

  /////////////////////////////////////////
  // Model
  /////////////////////////////////////////
  getModels(cman: CarManufacturer): Observable<CarManufacturer> {
    //if all models were loaded before do not request again
    if (cman["__lastRefresh_allModels"]) {
      return new Observable((observer) => {
        observer.next(cman);
        observer.complete();
      });
    }

    return this.dataService.getRequest(this.full_api_url_model + '/make/' + cman.id).map(response => {
      if (response && response['data']) {
        for (let response_model of response['data']) {
          this.buildModel(response_model);
        }
      }

      cman["__lastRefresh_allModels"] = new Date();
      cman.sortModelsByName();

      return cman;
    });
  }

  getModelById(id: number, noServerRequest?: boolean): Observable<CarModel> {
    //check if model has been loaded already (with consumption data)
    for (let iCarMan of this.carManufacturers) {
      for (let iModel of iCarMan.models) {
        if (iModel.id == id && iModel.consumption) {
          return new Observable((observer) => {
            observer.next(iModel);
            observer.complete();
          });
        }
      }
    }

    if (!noServerRequest) {
      return this.dataService.getRequest(this.full_api_url_model + "/" + id).map(response => {
        if (response) {
          return this.buildModel(response);;
        }
      });
    }
    else{
      return new Observable((observer) => {
        observer.next(undefined);
        observer.complete();
      });
    }
  }

  buildModel(response_model): CarModel {
    //check if model is already loaded
    let model = this.getCarManufacturerById(response_model["car_manufacturer"]).getModelById(response_model['id']);

    //create new model if not already loaded
    if (!model) {
      model = new CarModel();
      model.id = response_model['id'];
      model.name = response_model['name'];
      model.is_generic = response_model['is_generic'];
      model.car_manufacturer = this.getCarManufacturerById(response_model["car_manufacturer"]);

      model.car_manufacturer.models.push(model);
    }

    //set consumption data, if not already there
    if (!model.consumption && response_model['consumption']) {
      model.consumption = response_model['consumption'];
    }
    if (!model.estimates && response_model['estimates']) {
      model.estimates = response_model['estimates'];
    }

    return model;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url_cman).subscribe(response => {
      if (response) {
        this.carManufacturers = [];

        for (let cman of response['data']) {
          let tmp_cman = new CarManufacturer();
          tmp_cman.id = cman.id;
          tmp_cman.name = cman.name;
          tmp_cman.logo_url = cman.logo_url;

          this.carManufacturers.push(tmp_cman);
        }


        this.lastRefresh = new Date();
        this.initialised.next(true);
      }
    });
  }

}
