import { CacheService } from './../../services/cache.service';
import { VariantService } from './../../services/variant.service';
import { ColourService } from './../../services/colour.service';
import { Batch } from './../../model/model';
import { MatDialog } from '@angular/material';
import { VehicleService } from './../../services/vehicle.service';
import { Component, OnInit } from '@angular/core';
import { CoatService } from '../../services/coat.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  public batch:Batch;

  constructor(public vehicleService: VehicleService,
    public colourService: ColourService,
    public dialog: MatDialog,
    public coatService: CoatService,
    public productService: ProductService,
    public variantService: VariantService,
    public cacheService:CacheService) { }

  ngOnInit() {

    this.cacheService.isFullyLoaded.subscribe(state => {
      if(state){
        this.variantService.getVariant(4506556712353792).subscribe(variant => {
          if (variant) {
            let nBatch = new Batch(variant.coats[1].formula, variant.product_type, variant.coats[1].coat, variant.system);
            //nBatch.variant = variant;

            this.batch = nBatch;
          }
        });
      }
    });

    

    /*
        this.colourService.getColour(5705087234080768).subscribe(response => {
          if (response) {
            this.productService.initialised.subscribe(state => {
              if (state) {
                let nVar = new Variant();
                nVar.system = "2-cm";
                nVar.coats = [];
                nVar.colour = response
                this.vCoat = new VariantCoat();
                this.vCoat.coat = this.coatService.getCoatById('be');
                this.vCoat.formula = [];
    
                nVar.product_type = this.productService.getProductTypeById("pghbhs");
    
                nVar.coats.push(this.vCoat);
    
                this.variant = nVar;
                console.log(this.variant);
              }
            });
    
          }
        });
    
        */
  }
}
