import { Observable } from 'rxjs/Observable';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { User } from '../model/model';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private api_endpoint = '/auth';
  private full_api_url;

  private api_endpoint_pwdreset = '/pwdforgot';
  private full_api_url_pwdreset;

  public username;
  public userId;
  public name;
  public employeeNb;
  public bodyshop_security = [];
  public tenant_security = [];

  private helper = new JwtHelperService();

  public currentUser: User;

  constructor(public dataService: DataService, 
    private router: Router) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;    
    this.full_api_url_pwdreset = dataService.getUrl() + this.api_endpoint_pwdreset;
  }

  login(username: string, password: string) {
    //mapping observable<response> to observable<boolean>
    return this.dataService.postRequest(this.full_api_url, {
      'username': username,
      'password': password
    }).map(response => {
      if (response && response['access_token']) {
        //write received JWT into local storage
        localStorage.setItem('jwt_token', response['access_token']);
        return true;
      }
      else {
        return false;
      }
    });
  }

  logout() {
    localStorage.removeItem('jwt_token');
    //this.router.navigate(['/login', {redir: this.router.url}]);
  }

  isLoggedIn() {
    let token = localStorage.getItem('jwt_token')
    let isLoggedIn = !this.helper.isTokenExpired(token);
    if(!isLoggedIn && !this.router.isActive('/login', false)){
      this.logout();
    }
    return isLoggedIn;
  }

  getToken() {
    return localStorage.getItem('jwt_token');
  }

  resetPassword(email:string):Observable<boolean> {
    let requestBody = {
      'email': email.trim().toLowerCase()
    };

    return this.dataService.postRequest(this.full_api_url_pwdreset, requestBody).map(response => {
      if(response){
        return true;
      }
      else{
        return false;
      }
    });
  }
}
