import { ColourMatchService } from './../../services/colour-match.service';
import { CountryService } from './../../services/country.service';
import { DialogNewColourComponent } from './../dialogs/dialog-new-colour/dialog-new-colour.component';
import { PanelService } from './../../services/panel.service';
import { Toolkit } from './../../../toolkit/toolkit';
import { JobVariantSelectionComponent } from './../job-variant-selection/job-variant-selection.component';
import { BatchService } from './../../services/batch.service';
import { CacheService } from './../../services/cache.service';
import { ProductService } from './../../services/product.service';
import { InventoryService } from './../../services/inventory.service';
import { CoatService } from './../../services/coat.service';
import { UserService } from './../../services/user.service';
import { JobService } from './../../services/job.service';
import { ColourService } from './../../services/colour.service';
import { Job, JobPanel, CarPanel, Product, Coat, Colour, CarManufacturer, RepairType, ApplicationTechnique, ProductType, Batch, Substrate, ColourMatch, CarModel, FormulaComponent } from './../../model/model';
import { Router, ActivatedRoute } from '@angular/router/';
import { VehicleService } from './../../services/vehicle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from './../../services/data.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Variant } from '../../model/model';
import { VariantService } from '../../services/variant.service';
import { Location } from '@angular/common';
import { PanelOptionsComponent } from '../panel-options/panel-options.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogTwoOptionsComponent } from '../dialogs/dialog-two-options/dialog-two-options.component';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit, OnDestroy {
  //public newJobColour: JobColour;
  public newJobColour_selection: Colour[] = [];

  //public job: Job;

  public isEdit_jobDetails: boolean;

  public selectedVariant: Variant;
  public _selectedBatch: Batch;
  public _selectedPrimerProductType: ProductType;
  public _selectedClearProductType: ProductType;
  public _stats = {};
  public _isUpladingJob = false;

  public progressFormula = 100;

  public today = new Date();
  public availableYears = [];
  public toolkit = new Toolkit();

  public formGroup_jobDetails: FormGroup;

  public stepperControl: FormGroup;

  public isRetreivingData = false;


  @ViewChild('colourAutoComplete') autoComplete_colour: FormControl;

  constructor(public jobService: JobService,
    public userService: UserService,
    public vehicleService: VehicleService,
    private variantService: VariantService,
    private dataService: DataService,
    private productService: ProductService,
    public cacheService: CacheService,
    public colourService: ColourService,
    public countryService: CountryService,
    public colourMatchService: ColourMatchService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    //create array for available years for selection
    for (let i = this.today.getFullYear(); i >= 1900; i--) {
      this.availableYears.push(i);
    }
  }

  ngOnInit() {
    //wait until user service is initialised
    this.cacheService.isFullyLoaded.distinctUntilChanged().subscribe(state => {
      if (state) {
        this.userService.initialised.distinctUntilChanged().subscribe(status => {
          if (status) {
            this.route.params.subscribe(params => {
              if (params['jobId']) {
                if (!this.jobService.currentJob || this.jobService.currentJob.id != params['jobId']) {
                  this.jobService.getJob(params['jobId']).subscribe(job => {
                    //loading indicator
                    this.dataService.blockUIforLoad = true;

                    let newJob = job;
                    this.fetchColours(newJob.car_manufacturer);

                    if (newJob.car_model) {
                      this.vehicleService.getModelById(newJob.car_model.id).subscribe();
                    }


                    this.jobService.currentJob = newJob;

                    if (this.router.url.endsWith(newJob.id.toString())) {
                      this.router.navigate(['detail'], { relativeTo: this.route });
                    }

                    //this.populateForm();

                    //loading indicator
                    this.dataService.blockUIforLoad = false;
                  }, error => {
                    if (error instanceof HttpErrorResponse) {
                      console.log(error);
                      this.router.navigate(['job']);
                    }
                    console.log(error);
                  });
                }
              }
              else {
                this.jobService.currentJob = this.jobService.createEmptyJob();

                if (params['variantId']) {
                  this.variantService.getVariant(params['variantId']).subscribe(variant => {
                    if (variant) {
                      let cmatch = new ColourMatch();
                      cmatch.colour = variant.colour;
                      cmatch.variant = variant;
                      cmatch.variant_init = variant;

                      this.jobService.currentJob.colours.push(cmatch);
                      this.jobService.currentJob.car_manufacturer = variant.colour.carManufacturer;

                      this.router.navigate(['detail'], { relativeTo: this.route });
                    }
                  });
                }
                else {
                  if (this.route.children.length == 0)
                    this.router.navigate(['detail'], { relativeTo: this.route });
                }

              }
            });
          }
        });
      }
    });

  }

  ngOnDestroy() {
    this.jobService.currentJob = undefined;
  }



  deleteColour(colourMatch: ColourMatch) {
    this.job().deleteColour(colourMatch);
    for (let panel of this.job().panels) {
      if (panel.colour == colourMatch) {
        panel.colour = undefined;
      }
    }
  }

  getCarModelDetails() {
    this.vehicleService.getModelById(this.job().car_model.id).subscribe(model => {
      //this.job().car_model = model;
    });
  }

  fetchColours(carManufacturer: CarManufacturer) {
    if (carManufacturer) {
      this.colourService.getColours(carManufacturer).subscribe(colours => {
        colours.sort((a: Colour, b: Colour) => {
          if (a.code > b.code) {
            return 1;
          }
          else if (a.code < b.code) {
            return -1;
          }
          else {
            return 0;
          }
        });

        this.newJobColour_selection = colours;
      });
    }
  }

  readyForSave() {
    let result = this.job() && this.job().car_manufacturer && this.job().car_model && (this.job().car_model_text || !this.job().car_model.is_generic) && this.job().car_year && this.job().car_country && this.job().colours && this.job().colours.length > 0;

    return result;
  }

  job() {
    return this.jobService.currentJob;
  }

  saveJob() {
    this._isUpladingJob = true;
    //loading indicator
    this.dataService.blockUIforLoad = true;

    if (this.job().id) {
      this.jobService.saveJob(this.job()).subscribe(job => {
        console.log(job);

        //loading indicator
        this.dataService.blockUIforLoad = false;
      }, error => {
        this._isUpladingJob = false;

        //loading indicator
        this.dataService.blockUIforLoad = false;

        throw error;
      });
    }
    else {
      this.jobService.saveJob(this.job()).subscribe(job => {
        if (job) {
          //create colour matches if job created from formula explorer
          let toBeReplacedCmatch: ColourMatch;
          let entryCmatch: ColourMatch;

          for (let cmatch of this.jobService.currentJob.colours) {
            if (cmatch.variant && cmatch.variant.id) {
              entryCmatch = cmatch;

              //find cmatch in response
              for (let rescmatch of job.colours) {
                if (rescmatch.colour.id == cmatch.colour.id) {
                  toBeReplacedCmatch = rescmatch;
                  break;
                }
              }
              break;
            }
          }

          if (toBeReplacedCmatch) {
            this.colourMatchService.changeVariant(toBeReplacedCmatch, entryCmatch.variant).subscribe(colourMatch => {
              job.colours.splice(job.colours.indexOf(toBeReplacedCmatch), 1, colourMatch);

              this.jobService.currentJob = job;
              this.router.navigate(['job', job.id, 'detail']);

              //loading indicator
              this.dataService.blockUIforLoad = false;

            }, error => {
              this._isUpladingJob = false;

              //loading indicator
              this.dataService.blockUIforLoad = false;

              throw error;
            });
          }
          else {
            this.jobService.currentJob = job;
            this.router.navigate(['job', job.id, 'detail']);

            //loading indicator
            this.dataService.blockUIforLoad = false;
          }






        }
      }, error => {
        this._isUpladingJob = false;

        //loading indicator
        this.dataService.blockUIforLoad = false;

        throw error;
      });
    }
  }

  discardJob() {
    let text = "Do you really want to discard this job? No further changes can be made.";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Discard Job",
        'text': text,
        'buttonPrimary_text': "Delete",
        'buttonSecondary_text': "Cancel"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      //loading indicator
      this.dataService.blockUIforLoad = true;

      this.jobService.discardJob(this.job()).subscribe(job => {
        if (job) {
          this.job().date_closed = job.date_closed;
          this.job().date_discarded = job.date_discarded;
        }

        //loading indicator
        this.dataService.blockUIforLoad = false;
      }, error => {
        //loading indicator
        this.dataService.blockUIforLoad = false;
      });
    });

  }

  archiveJob() {
    let text = "Do you really want to close this job? No further changes can be made.";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Close Job",
        'text': text,
        'buttonPrimary_text': "Close",
        'buttonSecondary_text': "Cancel"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      //loading indicator
      this.dataService.blockUIforLoad = true;

      this.jobService.archiveJob(this.job()).subscribe(job => {
        if (job) {
          this.job().date_closed = job.date_closed;
          this.job().date_discarded = job.date_discarded;
        }

        //loading indicator
        this.dataService.blockUIforLoad = false;
      }, error => {
        console.log(error);
        //loading indicator
        this.dataService.blockUIforLoad = false;
      });
    });

  }

  carManufacturerSelected() {
    this.vehicleService.getModels(this.job().car_manufacturer).subscribe();
    this.fetchColours(this.job().car_manufacturer);
    this.job().car_model = undefined;
    this.job().car_model_text = undefined;

  }


  log(item?) {
    if (item != undefined) {
      console.log(item);
    }
    else {
      console.log(this);
    }
  }

  getJobPanelByPanelId(panelId: string): JobPanel {
    //console.log("getJobByPanelId(" + panelId + ")")

    let panel = undefined;

    for (let jPanel of this.job().panels) {
      if (jPanel.panel.id == panelId) {
        panel = jPanel;
        break;
      }
    }

    return panel;
  }


  getChartDataConsumptionByProductInKg() {
    let data = [];
    let rawData = this.job().getConsumptionByProduct();

    for (let productId in rawData) {
      let product = this.productService.getProductById(productId);
      let desc = product.code + " - " + product.name;

      data.push({
        'name': desc,
        'value': rawData[productId]
      });
    }

    data.sort((a, b) => {
      if (a['value'] > b['value']) {
        return -1;
      }
      if (a['value'] < b['value']) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });

    return data;
  }

  getChartDataConsumptionByProductInG() {
    let data = this.getChartDataConsumptionByProductInKg();

    for (let item of data) {
      item['value'] = Toolkit.precision(item['value'] * 1000, 2);
    }

    return data;
  }

  refreshData() {
    this._stats['consumptionByProductInG'] = this.getChartDataConsumptionByProductInG();
  }


  tmp() {

  }

}
