import { DialogTwoOptionsComponent } from './../dialogs/dialog-two-options/dialog-two-options.component';
import { MatDialog } from '@angular/material';
import { Toolkit } from './../../../toolkit/toolkit';
import { CoatService } from './../../services/coat.service';
import { ProductService } from './../../services/product.service';
import { VariantService } from './../../services/variant.service';
import { ColourService } from './../../services/colour.service';
import { Colour, Variant, ProductType, VariantCoat, FormulaComponent, Product } from './../../model/model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-new-variant',
  templateUrl: './new-variant.component.html',
  styleUrls: ['./new-variant.component.css']
})
export class NewVariantComponent implements OnInit {
  @Input() colour: Colour;
  @Output() onVariantCreated = new EventEmitter<Variant>();

  public variant: Variant = new Variant();

  constructor(private colourService: ColourService,
    private variantService: VariantService,
    public productService: ProductService,
    private coatService: CoatService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.variant.colour = this.colour;

    this.log(this.colour);
    this.log(this.variant);
  }

  selectProductType(ptype: ProductType) {
    this.variant.product_type = ptype;
  }

  selectSystem() {
    this.variant.coats = [];
    for (let coat_key of this.getCoats()) {
      let vCoat = new VariantCoat();
      vCoat.coat = this.coatService.getCoatById(coat_key);
      vCoat.formula = [];

      this.variant.coats.push(vCoat);
    }
  }

  updateFormula(formula:FormulaComponent[], coat:VariantCoat){
    coat.formula = formula;
  }

  saveVariant(){
    let text = "Are you sure you want to save this Variant?";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Variant Creation",
        'text': text,
        'buttonPrimary_text': "Yes",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(click =>{
      if(click)
        this.onVariantCreated.emit(this.variant);
    });
  


    /*
    this.variantService.storeVariant(this.variant).subscribe(responseVariant => {
      this.variant = responseVariant;
      this.log();
    });
    */
  }

  isProductInFormula(formula: FormulaComponent[], product: Product) {
    for (let component of formula) {
      if (component.product && product) {
        if (component.product.id == product.id) {
          return true;
        }
      }
    }

    return false;
  }

  getChartData(formula:FormulaComponent[]){
    let data = [];
    for(let component of formula){
      if(!component.product)
        continue;

      data.push({
        'name': component.product.name,
        'value': Toolkit.ratio(component, formula)
      });
    }

    return data;
  }

  getSystems(ptype: ProductType) {
    return Object.keys(ptype.formula);
  }

  getCoats() {
    if (this.variant.system && this.variant.product_type) {
      return Object.keys(this.variant.product_type.formula[this.variant.system]);
    }
  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }
}
