
<div id="container">
  <mat-card id="panel-drawing-card">
  <div class="details-section-header details-section-header-margin">
    Panel Selection <ng-container *ngIf="!job.car_model">(Please select a Car Model first)</ng-container>
  </div>
  <div id="panel-drawing">
    <svg id="car-panel-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1583.26 1043.23">
      <defs>
        <style>
          .cls-1,
          .cls-49 {
            fill: #fff;
          }

          .cls-1,
          .cls-10,
          .cls-11,
          .cls-12,
          .cls-13,
          .cls-14,
          .cls-15,
          .cls-16,
          .cls-17,
          .cls-18,
          .cls-19,
          .cls-2,
          .cls-20,
          .cls-21,
          .cls-22,
          .cls-23,
          .cls-24,
          .cls-25,
          .cls-26,
          .cls-27,
          .cls-28,
          .cls-29,
          .cls-3,
          .cls-30,
          .cls-31,
          .cls-32,
          .cls-33,
          .cls-34,
          .cls-35,
          .cls-36,
          .cls-37,
          .cls-38,
          .cls-39,
          .cls-4,
          .cls-40,
          .cls-41,
          .cls-42,
          .cls-43,
          .cls-44,
          .cls-45,
          .cls-46,
          .cls-47,
          .cls-48,
          .cls-5,
          .cls-50,
          .cls-51,
          .cls-52,
          .cls-53,
          .cls-54,
          .cls-6,
          .cls-7,
          .cls-8,
          .cls-9 {
            stroke: #000;
          }

          .cls-1,
          .cls-47,
          .cls-48,
          .cls-50,
          .cls-51,
          .cls-52,
          .cls-53,
          .cls-54 {
            stroke-width: 2px;
          }

          .cls-10,
          .cls-11,
          .cls-12,
          .cls-13,
          .cls-14,
          .cls-15,
          .cls-16,
          .cls-17,
          .cls-18,
          .cls-19,
          .cls-2,
          .cls-20,
          .cls-21,
          .cls-22,
          .cls-23,
          .cls-24,
          .cls-25,
          .cls-26,
          .cls-27,
          .cls-28,
          .cls-29,
          .cls-3,
          .cls-30,
          .cls-31,
          .cls-32,
          .cls-33,
          .cls-34,
          .cls-35,
          .cls-36,
          .cls-37,
          .cls-38,
          .cls-39,
          .cls-4,
          .cls-40,
          .cls-41,
          .cls-42,
          .cls-43,
          .cls-44,
          .cls-45,
          .cls-46,
          .cls-5,
          .cls-50,
          .cls-6,
          .cls-7,
          .cls-8,
          .cls-9 {
            fill: none;
          }

          .cls-3 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-4 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-5 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-6 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-7 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-8 {
            stroke-dasharray: 2 2;
          }

          .cls-9 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-10 {
            stroke-dasharray: 1.97 1.97;
          }

          .cls-11 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-12 {
            stroke-dasharray: 2 2;
          }

          .cls-13 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-14 {
            stroke-dasharray: 2 2;
          }

          .cls-15 {
            stroke-dasharray: 2.02 2.02;
          }

          .cls-16 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-17 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-18 {
            stroke-dasharray: 2.04 2.04;
          }

          .cls-19 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-20 {
            stroke-dasharray: 2 2;
          }

          .cls-21 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-22 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-23 {
            stroke-dasharray: 1.93 1.93;
          }

          .cls-24 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-25 {
            stroke-dasharray: 2.03 2.03;
          }

          .cls-26 {
            stroke-dasharray: 2 2;
          }

          .cls-27 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-28 {
            stroke-dasharray: 1.99 1.99;
          }

          .cls-29 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-30 {
            stroke-dasharray: 1.97 1.97;
          }

          .cls-31 {
            stroke-dasharray: 1.95 1.95;
          }

          .cls-32 {
            stroke-dasharray: 2 2;
          }

          .cls-33 {
            stroke-dasharray: 2 2;
          }

          .cls-34 {
            stroke-dasharray: 2.03 2.03;
          }

          .cls-35 {
            stroke-dasharray: 2 2;
          }

          .cls-36 {
            stroke-dasharray: 2.02 2.02;
          }

          .cls-37 {
            stroke-dasharray: 1.98 1.98;
          }

          .cls-38 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-39 {
            stroke-dasharray: 1.96 1.96;
          }

          .cls-40 {
            stroke-dasharray: 2.05 2.05;
          }

          .cls-41 {
            stroke-dasharray: 2.01 2.01;
          }

          .cls-42 {
            stroke-dasharray: 2 2;
          }

          .cls-43 {
            stroke-dasharray: 2 2;
          }

          .cls-44 {
            stroke-dasharray: 1.97 1.97;
          }

          .cls-45 {
            stroke-dasharray: 1.98 1.98;
          }

          .cls-46 {
            stroke-dasharray: 1.85 1.85;
          }

          .cls-47 {
            fill: #ffffff;
          }

          .cls-48 {
            fill: #ffffff;
          }

          .cls-51 {
            fill: #ffffff;
          }

          .cls-52 {
            fill: #ffffc0;
          }

          .cls-53 {
            fill: #fc7a7a;
          }

          .cls-54 {
            fill: #ffffff;
          }
        </style>
      </defs>
      <g id="svg2">

        <!-- ========================================= -->
        <!-- Panel: engine-bay -->
        <!-- ========================================= -->
        <g (click)="clickOnPanel('engine-bay')" id="panel_engine-bay">
          <polygon [class.selected-panel]="getJobPanelByPanelId('engine-bay').isSelected()" id="path" class="cls-1" points="372.37 336.61 130.5 347.36 130.5 701.59 368.99 708.22 372.37 336.61"
          />
          <line class="cls-2" x1="191.6" y1="343.92" x2="190.76" y2="344.46" />
          <line class="cls-3" x1="189.08" y1="345.56" x2="139.46" y2="377.92" />
          <line class="cls-2" x1="138.62" y1="378.46" x2="137.78" y2="379.01" />
          <line class="cls-2" x1="371.75" y1="404.31" x2="370.91" y2="404.85" />
          <line class="cls-4" x1="369.23" y1="405.95" x2="134.77" y2="558.83" />
          <line class="cls-2" x1="133.93" y1="559.38" x2="133.09" y2="559.93" />
          <line class="cls-2" x1="372.36" y1="368.33" x2="371.53" y2="368.88" />
          <line class="cls-5" x1="369.85" y1="369.97" x2="135.43" y2="522.83" />
          <line class="cls-2" x1="134.59" y1="523.38" x2="133.76" y2="523.92" />
          <line class="cls-2" x1="366.46" y1="336.61" x2="365.62" y2="337.16" />
          <line class="cls-6" x1="363.94" y1="338.25" x2="136.09" y2="486.83" />
          <line class="cls-2" x1="135.25" y1="487.37" x2="134.42" y2="487.92" />
          <line class="cls-2" x1="336.99" y1="338.04" x2="336.15" y2="338.59" />
          <line class="cls-7" x1="334.47" y1="339.69" x2="136.43" y2="468.82" />
          <line class="cls-2" x1="135.58" y1="469.37" x2="134.75" y2="469.92" />
          <line class="cls-2" x1="249.76" y1="341.57" x2="248.92" y2="342.11" />
          <line class="cls-8" x1="247.24" y1="343.21" x2="137.41" y2="414.82" />
          <line class="cls-2" x1="136.57" y1="415.37" x2="135.74" y2="415.91" />
          <line class="cls-2" x1="371.1" y1="475.87" x2="370.26" y2="476.42" />
          <line class="cls-9" x1="368.58" y1="477.52" x2="133.28" y2="630.95" />
          <line class="cls-2" x1="132.43" y1="631.5" x2="131.6" y2="632.05" />
          <line class="cls-2" x1="162.52" y1="345.09" x2="161.68" y2="345.64" />
          <line class="cls-10" x1="160.03" y1="346.72" x2="137.76" y2="361.24" />
          <line class="cls-2" x1="136.94" y1="361.77" x2="136.1" y2="362.32" />
          <line class="cls-2" x1="371.91" y1="386.41" x2="371.07" y2="386.96" />
          <line class="cls-11" x1="369.4" y1="388.06" x2="135.1" y2="540.83" />
          <line class="cls-2" x1="134.26" y1="541.38" x2="133.43" y2="541.92" />
          <line class="cls-2" x1="372.24" y1="350.63" x2="371.4" y2="351.18" />
          <line class="cls-12" x1="369.72" y1="352.27" x2="135.76" y2="504.83" />
          <line class="cls-2" x1="134.92" y1="505.38" x2="134.09" y2="505.92" />
          <line class="cls-2" x1="305.77" y1="340.61" x2="304.93" y2="341.16" />
          <line class="cls-13" x1="303.26" y1="342.25" x2="137.13" y2="450.58" />
          <line class="cls-2" x1="136.29" y1="451.12" x2="135.46" y2="451.67" />
          <line class="cls-2" x1="279.22" y1="340.14" x2="278.38" y2="340.69" />
          <line class="cls-14" x1="276.71" y1="341.78" x2="137.08" y2="432.82" />
          <line class="cls-2" x1="136.24" y1="433.37" x2="135.41" y2="433.92" />
          <line class="cls-2" x1="220.8" y1="342.66" x2="219.96" y2="343.21" />
          <line class="cls-15" x1="218.27" y1="344.31" x2="137.75" y2="396.81" />
          <line class="cls-2" x1="136.91" y1="397.37" x2="136.07" y2="397.91" />
          <line class="cls-2" x1="372.36" y1="457.26" x2="371.53" y2="457.81" />
          <line class="cls-16" x1="369.86" y1="458.9" x2="133.78" y2="612.84" />
          <line class="cls-2" x1="132.94" y1="613.38" x2="132.1" y2="613.93" />
          <line class="cls-2" x1="372.36" y1="439.48" x2="371.53" y2="440.02" />
          <line class="cls-17" x1="369.86" y1="441.11" x2="134.11" y2="594.84" />
          <line class="cls-2" x1="133.27" y1="595.38" x2="132.43" y2="595.93" />
          <line class="cls-2" x1="369.47" y1="654.79" x2="368.64" y2="655.34" />
          <line class="cls-18" x1="366.93" y1="656.45" x2="292.57" y2="704.94" />
          <line class="cls-2" x1="291.71" y1="705.5" x2="290.88" y2="706.04" />
          <line class="cls-2" x1="369.8" y1="619.01" x2="368.96" y2="619.56" />
          <line class="cls-19" x1="367.28" y1="620.65" x2="240.23" y2="703.5" />
          <line class="cls-2" x1="239.39" y1="704.04" x2="238.55" y2="704.59" />
          <line class="cls-2" x1="370.07" y1="583.26" x2="369.23" y2="583.81" />
          <line class="cls-20" x1="367.56" y1="584.9" x2="187.91" y2="702.04" />
          <line class="cls-2" x1="187.07" y1="702.59" x2="186.23" y2="703.14" />
          <line class="cls-2" x1="370.29" y1="565.33" x2="369.45" y2="565.88" />
          <line class="cls-21" x1="367.78" y1="566.97" x2="161.74" y2="701.32" />
          <line class="cls-2" x1="160.91" y1="701.86" x2="160.07" y2="702.41" />
          <line class="cls-2" x1="370.77" y1="511.66" x2="369.94" y2="512.2" />
          <line class="cls-22" x1="368.27" y1="513.29" x2="132.78" y2="666.84" />
          <line class="cls-2" x1="131.95" y1="667.39" x2="131.11" y2="667.93" />
          <line class="cls-2" x1="369.15" y1="690.58" x2="368.31" y2="691.12" />
          <line class="cls-23" x1="366.69" y1="692.18" x2="344.85" y2="706.42" />
          <line class="cls-2" x1="344.04" y1="706.95" x2="343.2" y2="707.5" />
          <line class="cls-2" x1="371.59" y1="422.2" x2="370.75" y2="422.74" />
          <line class="cls-24" x1="369.07" y1="423.84" x2="134.44" y2="576.83" />
          <line class="cls-2" x1="133.6" y1="577.38" x2="132.76" y2="577.93" />
          <line class="cls-2" x1="370.07" y1="636.62" x2="369.23" y2="637.17" />
          <line class="cls-25" x1="367.53" y1="638.27" x2="266.4" y2="704.22" />
          <line class="cls-2" x1="265.55" y1="704.77" x2="264.72" y2="705.32" />
          <line class="cls-2" x1="369.8" y1="601.23" x2="368.96" y2="601.77" />
          <line class="cls-26" x1="367.28" y1="602.86" x2="214.07" y2="702.77" />
          <line class="cls-2" x1="213.23" y1="703.32" x2="212.39" y2="703.86" />
          <line class="cls-2" x1="370.45" y1="547.44" x2="369.61" y2="547.99" />
          <line class="cls-27" x1="367.95" y1="549.07" x2="135.58" y2="700.59" />
          <line class="cls-2" x1="134.75" y1="701.13" x2="133.91" y2="701.68" />
          <line class="cls-2" x1="370.61" y1="529.55" x2="369.77" y2="530.1" />
          <line class="cls-28" x1="368.11" y1="531.18" x2="132.45" y2="684.85" />
          <line class="cls-2" x1="131.62" y1="685.39" x2="130.78" y2="685.93" />
          <line class="cls-2" x1="370.94" y1="493.77" x2="370.1" y2="494.31" />
          <line class="cls-29" x1="368.41" y1="495.41" x2="133.12" y2="648.84" />
          <line class="cls-2" x1="132.28" y1="649.39" x2="131.44" y2="649.93" />
          <line class="cls-2" x1="369.31" y1="672.69" x2="368.47" y2="673.23" />
          <line class="cls-30" x1="366.82" y1="674.31" x2="318.12" y2="706.07" />
          <line class="cls-2" x1="317.29" y1="706.6" x2="316.46" y2="707.15" />
        </g>

        <!-- ========================================= -->
        <!-- Panel: boot -->
        <!-- ========================================= -->
        <g (click)="clickOnPanel('boot')" id="panel_boot">
          <polygon [class.selected-panel]="getJobPanelByPanelId('boot').isSelected()" id="path-2" data-name="path" class="cls-1" points="1317.08 338.24 1488.05 342.37 1488.05 706.14 1317.08 710.01 1317.08 338.24"
          />
          <line class="cls-2" x1="1359.66" y1="339.27" x2="1358.82" y2="339.82" />
          <line class="cls-31" x1="1357.18" y1="340.88" x2="1318.74" y2="365.95" />
          <line class="cls-2" x1="1317.92" y1="366.49" x2="1317.08" y2="367.03" />
          <line class="cls-2" x1="1488.05" y1="433.41" x2="1487.21" y2="433.96" />
          <line class="cls-32" x1="1485.53" y1="435.05" x2="1318.76" y2="543.8" />
          <line class="cls-2" x1="1317.92" y1="544.35" x2="1317.08" y2="544.89" />
          <line class="cls-2" x1="1488.05" y1="397.84" x2="1487.21" y2="398.39" />
          <line class="cls-32" x1="1485.53" y1="399.48" x2="1318.76" y2="508.23" />
          <line class="cls-2" x1="1317.92" y1="508.77" x2="1317.08" y2="509.32" />
          <line class="cls-2" x1="1487.9" y1="362.36" x2="1487.06" y2="362.91" />
          <line class="cls-33" x1="1485.39" y1="364" x2="1318.76" y2="472.66" />
          <line class="cls-2" x1="1317.92" y1="473.2" x2="1317.08" y2="473.75" />
          <line class="cls-2" x1="1488.05" y1="344.48" x2="1487.21" y2="345.03" />
          <line class="cls-32" x1="1485.53" y1="346.12" x2="1318.76" y2="454.87" />
          <line class="cls-2" x1="1317.92" y1="455.42" x2="1317.08" y2="455.96" />
          <line class="cls-2" x1="1412.26" y1="340.54" x2="1411.43" y2="341.09" />
          <line class="cls-34" x1="1409.73" y1="342.19" x2="1318.77" y2="401.5" />
          <line class="cls-2" x1="1317.92" y1="402.06" x2="1317.08" y2="402.6" />
          <line class="cls-2" x1="1487.01" y1="505.23" x2="1486.17" y2="505.78" />
          <line class="cls-35" x1="1484.5" y1="506.87" x2="1318.12" y2="615.36" />
          <line class="cls-2" x1="1317.28" y1="615.91" x2="1316.44" y2="616.45" />
          <line class="cls-2" x1="1333.96" y1="338.24" x2="1333.12" y2="338.79" />
          <line class="cls-36" x1="1331.43" y1="339.89" x2="1318.76" y2="348.15" />
          <line class="cls-2" x1="1317.92" y1="348.7" x2="1317.08" y2="349.25" />
          <line class="cls-2" x1="1488.05" y1="415.63" x2="1487.21" y2="416.17" />
          <line class="cls-32" x1="1485.53" y1="417.27" x2="1318.76" y2="526.01" />
          <line class="cls-2" x1="1317.92" y1="526.56" x2="1317.08" y2="527.11" />
          <line class="cls-2" x1="1488.05" y1="380.05" x2="1487.21" y2="380.6" />
          <line class="cls-32" x1="1485.53" y1="381.69" x2="1318.76" y2="490.44" />
          <line class="cls-2" x1="1317.92" y1="490.99" x2="1317.08" y2="491.53" />
          <line class="cls-2" x1="1463.82" y1="342.49" x2="1462.99" y2="343.04" />
          <line class="cls-37" x1="1461.33" y1="344.12" x2="1319.33" y2="436.71" />
          <line class="cls-2" x1="1318.5" y1="437.25" x2="1317.66" y2="437.8" />
          <line class="cls-2" x1="1438.57" y1="341.17" x2="1437.73" y2="341.72" />
          <line class="cls-38" x1="1436.04" y1="342.82" x2="1318.76" y2="419.29" />
          <line class="cls-2" x1="1317.92" y1="419.84" x2="1317.08" y2="420.39" />
          <line class="cls-2" x1="1385.96" y1="339.91" x2="1385.12" y2="340.45" />
          <line class="cls-39" x1="1383.48" y1="341.52" x2="1318.74" y2="383.74" />
          <line class="cls-2" x1="1317.92" y1="384.27" x2="1317.08" y2="384.82" />
          <line class="cls-2" x1="1488.05" y1="486.77" x2="1487.21" y2="487.32" />
          <line class="cls-32" x1="1485.53" y1="488.41" x2="1318.76" y2="597.16" />
          <line class="cls-2" x1="1317.92" y1="597.7" x2="1317.08" y2="598.25" />
          <line class="cls-2" x1="1488.05" y1="468.98" x2="1487.21" y2="469.53" />
          <line class="cls-32" x1="1485.53" y1="470.62" x2="1318.76" y2="579.37" />
          <line class="cls-2" x1="1317.92" y1="579.92" x2="1317.08" y2="580.46" />
          <line class="cls-2" x1="1488.05" y1="682.42" x2="1487.21" y2="682.96" />
          <line class="cls-40" x1="1485.49" y1="684.08" x2="1452.05" y2="705.89" />
          <line class="cls-2" x1="1451.19" y1="706.45" x2="1450.35" y2="707" />
          <line class="cls-2" x1="1488.05" y1="646.84" x2="1487.21" y2="647.39" />
          <line class="cls-41" x1="1485.53" y1="648.49" x2="1395.52" y2="707.18" />
          <line class="cls-2" x1="1394.68" y1="707.73" x2="1393.84" y2="708.27" />
          <line class="cls-2" x1="1488.05" y1="611.27" x2="1487.21" y2="611.82" />
          <line class="cls-42" x1="1485.53" y1="612.91" x2="1339" y2="708.46" />
          <line class="cls-2" x1="1338.16" y1="709.01" x2="1337.32" y2="709.55" />
          <line class="cls-2" x1="1488.05" y1="593.49" x2="1487.21" y2="594.03" />
          <line class="cls-43" x1="1485.53" y1="595.13" x2="1321.91" y2="701.82" />
          <line class="cls-2" x1="1321.07" y1="702.36" x2="1320.23" y2="702.91" />
          <line class="cls-2" x1="1488.05" y1="540.13" x2="1487.21" y2="540.67" />
          <line class="cls-32" x1="1485.53" y1="541.77" x2="1318.76" y2="650.52" />
          <line class="cls-2" x1="1317.92" y1="651.06" x2="1317.08" y2="651.61" />
          <line class="cls-2" x1="1488.05" y1="451.2" x2="1487.21" y2="451.74" />
          <line class="cls-32" x1="1485.53" y1="452.84" x2="1318.76" y2="561.59" />
          <line class="cls-2" x1="1317.92" y1="562.13" x2="1317.08" y2="562.68" />
          <line class="cls-2" x1="1488.05" y1="664.63" x2="1487.21" y2="665.18" />
          <line class="cls-44" x1="1485.56" y1="666.25" x2="1423.76" y2="706.55" />
          <line class="cls-2" x1="1422.93" y1="707.09" x2="1422.09" y2="707.63" />
          <line class="cls-2" x1="1488.05" y1="629.06" x2="1487.21" y2="629.6" />
          <line class="cls-45" x1="1485.55" y1="630.68" x2="1367.25" y2="707.83" />
          <line class="cls-2" x1="1366.42" y1="708.37" x2="1365.58" y2="708.91" />
          <line class="cls-2" x1="1488.05" y1="575.7" x2="1487.21" y2="576.25" />
          <line class="cls-32" x1="1485.53" y1="577.34" x2="1318.76" y2="686.09" />
          <line class="cls-2" x1="1317.92" y1="686.63" x2="1317.08" y2="687.18" />
          <line class="cls-2" x1="1488.05" y1="557.91" x2="1487.21" y2="558.46" />
          <line class="cls-32" x1="1485.53" y1="559.55" x2="1318.76" y2="668.3" />
          <line class="cls-2" x1="1317.92" y1="668.85" x2="1317.08" y2="669.39" />
          <line class="cls-2" x1="1488.05" y1="522.34" x2="1487.21" y2="522.89" />
          <line class="cls-32" x1="1485.53" y1="523.98" x2="1318.76" y2="632.73" />
          <line class="cls-2" x1="1317.92" y1="633.28" x2="1317.08" y2="633.82" />
          <line class="cls-2" x1="1488.05" y1="700.2" x2="1487.21" y2="700.75" />
          <line class="cls-46" x1="1485.66" y1="701.76" x2="1480.22" y2="705.3" />
          <line class="cls-2" x1="1479.45" y1="705.81" x2="1478.61" y2="706.36" />
        </g>

        <!-- ========================================= -->
        <!-- Panel: bonnet -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bonnet').isSelected()" (click)="clickOnPanel('bonnet')" id="panel_bonnet"
          class="cls-1" d="M586,394.31H379A100,100,0,0,0,280,480.73l-1.37,10a35.39,35.39,0,0,1-4.23,11.68h0a24.15,24.15,0,0,0-3.67,12.8V644.79a24.15,24.15,0,0,0,2.55,10.8l2.18,4.35a32.85,32.85,0,0,1,3.09,9.73l1.71,11.2a100.17,100.17,0,0,0,99,85H581.77"
          transform="translate(-29.01 -57.31)" />
        <path id="path4143" class="cls-2" d="M272,506.55l315.3-111.2" transform="translate(-29.01 -57.31)" />
        <path id="path4145" class="cls-2" d="M270.55,650.12l315.3,115.42" transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: roof -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('roof').isSelected()" (click)="clickOnPanel('roof')" id="panel_roof" class="cls-1"
          d="M722.39,734l-21.06-60V516l21.06-83.69s94.31,5.63,152,5.63c107,0,201.29-5.63,201.29-5.63l-1.41,297s-77.42-5.63-199.88-5.63c-69,0-152,7-152,7"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: a-pillar-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('a-pillar-r').isSelected()" (click)="clickOnPanel('a-pillar-r')" id="panel_a-pillar-r"
          class="cls-1" d="M722.39,432.31l-140.76-38s-2.26,8.27-5,20.26l142.9,38.58C721.08,441.36,722.39,432.31,722.39,432.31Z"
          transform="translate(-29.01 -57.31)" />

        <path class="cls-47" d="M713.95,651.9V504.28c0-8.3,3.1-33,5.55-51.12l-142.9-38.58c-4.74,20.45-11,51.71-10.09,71.23l-2.11,185.8c0,19.87,7.09,52.45,12.17,73.27l142.61-34.7C716.79,693.48,713.95,669.85,713.95,651.9Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: a-pillar-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('a-pillar-l').isSelected()" (click)="clickOnPanel('a-pillar-l')" id="panel_a-pillar-l"
          class="cls-1" d="M722.39,730.72s-1.49-8.45-3.22-20.55l-142.61,34.7c2.85,11.66,5.07,19.63,5.07,19.63Z" transform="translate(-29.01 -57.31)"
        />

        <path class="cls-48" d="M718.82,710" transform="translate(-29.01 -57.31)" />
        <path class="cls-48" d="M575,745" transform="translate(-29.01 -57.31)" />
        <path class="cls-48" d="M719.67,709.5" transform="translate(-29.01 -57.31)" />
        <path class="cls-48" d="M627,732.5" transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: boot-door -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('boot-door').isSelected()" (click)="clickOnPanel('boot-door')" id="panel_boot-door"
          class="cls-1" d="M1072.88,432.31s-12.67,47.86-12.67,73.19V651.9c0,25.34,12.67,77.42,12.67,77.42l205.51,38h54.43a62.87,62.87,0,0,0,62.29-54.38c3-22.27,5.73-46.25,5.73-61V504.1c0-15.41-2.31-36.37-5.07-55.85a62.76,62.76,0,0,0-62.17-53.94h-55.23Z"
          transform="translate(-29.01 -57.31)" />

        <path id="path4205" class="cls-47" d="M1084.14,450.61s-8.45,40.82-8.45,56.3v145c0,19.71,7,57.71,7,57.71s94.31,26.74,123.87,26.74h42.23V423.87H1201C1175.64,423.87,1084.14,450.61,1084.14,450.61Z"
          transform="translate(-29.01 -57.31)" />
        <path id="path4207" class="cls-2" d="M1278.39,394.31s31,40.82,31,111.2V651.9c0,64.75-31,116.83-31,116.83" transform="translate(-29.01 -57.31)"
        />
        <path id="path4243" class="cls-2" d="M581.63,394.31s-42.23,78.83-42.23,111.2V651.9c0,40.82,42.23,114,42.23,114" transform="translate(-29.01 -57.31)"
        />

        <!-- ========================================= -->
        <!-- Panel: bumper-f-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-f').isSelected()" (click)="clickOnPanel('bumper-f')" id="panel_bumper-f-l"
          class="cls-1" d="M312,946.08l-.11.3S286.22,945,264.79,945s-12.49,24.87-17.14,41.43c-4.81,17.14,20.91,17.14,21.43,17.14l18.57,38.57h65.71s4.54-68.13,52.74-95h-93Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: fender-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('fender-l').isSelected()" (click)="clickOnPanel('fender-l')" id="panel_fender-l"
          class="cls-1" d="M583.25,842.23c-38.94,15.7-104.27,16.61-178.46,24.15-84.29,8.57-118.57,32.86-118.57,32.86l41.43,2.86-15.61,44,1,1h93c12.24-6.83,27.31-11,45.83-10.71,66.85,1,87.87,57.07,94.35,87.12h31.31c-8.47-28.3-10.77-70.85-10.77-88C566.82,913.2,570.7,874.12,583.25,842.23Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: door-f-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('door-f-l').isSelected()" (click)="clickOnPanel('door-f-l')" id="panel_door-f-l"
          class="cls-1" d="M854.49,842.2c2.15-21.1,4.43-79.88,5.91-98.18-46.85.36-98.48,2.07-125.61,12.36,0,0-101.43,55.72-137.14,78.57a87.27,87.27,0,0,1-14.4,7.28c-12.55,31.89-16.43,71-16.43,93.24,0,17.19,2.3,59.73,10.77,88h282.3c-5.4-26.45-11.55-76.53-11.55-96.89C849.5,891.71,850.82,860.29,854.49,842.2Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: b-pillar-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('b-pillar-l').isSelected()" (click)="clickOnPanel('b-pillar-l')" id="panel_b-pillar-l"
          class="cls-1" d="M869.13,925.13c1.17-34.9,2.49-66.32,6.16-84.41,2.1-20.6,4.32-77.09,5.8-96.79l-8.3,0L860.4,744c-1.48,18.3-3.76,77.08-5.91,98.18-3.67,18.09-5,49.51-6.16,84.41,0,20.36,6.15,70.44,11.55,96.89H881C875.52,997.49,869.13,945.87,869.13,925.13Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: door-b-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('door-b-l').isSelected()" (click)="clickOnPanel('door-b-l')" id="panel_door-b-l"
          class="cls-1" d="M1113.94,852.64C1080,803.1,1045,766.3,1024.19,749.41c-36.68-4.32-73.73-6.56-98-5.89-12.69.35-28.26.36-45.13.4-1.48,19.71-3.71,76.2-5.8,96.79-3.67,18.09-5,49.51-6.16,84.41,0,20.75,6.39,72.36,11.86,98.37H1031.4c17.73-55.46,38.88-78.79,63.44-93C1122.75,914.34,1152.13,908.46,1113.94,852.64Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: rocker-panel-l -->
        <!-- ========================================= -->
        <g (click)="clickOnPanel('rocker-panel-l')" id="panel_rocker-panel-l">
          <path class="cls-49" d="M546.28,1023.59a133,133,0,0,1,2.8,18.6h500a147.27,147.27,0,0,1,4-18.6Z" transform="translate(-29.01 -57.31)"
          />
          <path [class.selected-panel]="getJobPanelByPanelId('rocker-panel-l').isSelected()" class="cls-50" d="M546.28,1023.59a133,133,0,0,1,2.8,18.6h500a147.27,147.27,0,0,1,4-18.6Z"
            transform="translate(-29.01 -57.31)" />
        </g>

        <!-- ========================================= -->
        <!-- Panel: quarter-panel-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('quarter-panel-l').isSelected()" (click)="clickOnPanel('quarter-panel-l')"
          id="panel_quarter-panel-l" class="cls-1" d="M1349.17,943.78l5.62-51.68,30,4.29s12.86-20-25.71-32.86-57.14-12.86-80-20c-16.64-5.2-110.43-55.93-151.43-72.86-21.65-8.94-62.33-16.41-103.46-21.26,20.8,16.89,55.85,53.69,89.75,103.24,38.19,55.82,8.81,61.69-19.09,77.85-24.56,14.22-45.71,37.55-63.45,93H1053c8.13-29.77,31.32-85,91.75-87.12,16-.55,29.42,3,40.76,9.19h160.53Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: bumper-b-l -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-b').isSelected()" (click)="clickOnPanel('bumper-b')" id="panel_bumper-b-l"
          class="cls-1" d="M1390.51,960.67C1386.22,946.38,1349,945,1349,945l.13-1.24-3.09,1.79H1185.55c47.56,25.76,57.81,96.53,57.81,96.53s74.29-1.43,98.57,0,22.86-12.86,27.14-27.14,10-2.86,18.57-12.86S1394.79,975,1390.51,960.67Z"
          transform="translate(-29.01 -57.31)" />

        <path class="cls-47" d="M749.14,762.1c-26.74,7-129.37,70-144.35,88.57H840.52L847.33,755S777.27,754.69,749.14,762.1Z" transform="translate(-29.01 -57.31)"
        />
        <path class="cls-47" d="M1017.88,762.12c-13.71-10-79.53-4.33-124.77-5.76L889,850.69h198.8C1076.94,829.94,1043.07,775.78,1017.88,762.12Z"
          transform="translate(-29.01 -57.31)" />
        <path class="cls-47" d="M1155.33,796.5c-25.71-11.43-89.76-33.31-98.33-31.88,0,0,80,84.29,85.71,87.14s115.71,1.43,115.71,1.43S1181,807.93,1155.33,796.5Z"
          transform="translate(-29.01 -57.31)" />
        <path id="rect3950" class="cls-51" d="M801.81,894.44h35.42c1.62,0,2.93,3.26,2.93,7.27h0c0,4-1.31,7.27-2.93,7.27H801.81c-1.62,0-2.93-3.26-2.93-7.27h0C798.87,897.7,800.19,894.44,801.81,894.44Z"
          transform="translate(-29.01 -57.31)" />
        <path class="cls-51" d="M1094.82,909c1.62,0,2.93-3.26,2.93-7.27s-1.31-7.27-2.93-7.27H1059.4c-1.62,0-2.93,3.26-2.93,7.27s1.31,7.27,2.93,7.27Z"
          transform="translate(-29.01 -57.31)" />
        <path class="cls-52" d="M192.14,425.37s10.6-20.35-47.68-21.54-50.33,2.39-50.33,2.39l.41,54.45h96.4Z" transform="translate(-29.01 -57.31)"
        />

        <!-- ========================================= -->
        <!-- Panel: front-panel -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('front-panel').isSelected()" (click)="clickOnPanel('front-panel')" id="panel_front-panel"
          class="cls-1" d="M192.14,658.75c-23.86,0-23.89-8.56-23.89-14.75,0-14.41.25-115.48.25-126.25.85-16.06,21-14.59,21-14.59l1.45-42.49H94.53l1.82,241.91h95.79Z"
          transform="translate(-29.01 -57.31)" />

        <path class="cls-52" d="M192.14,702.58H96.34l.43,56.7h53c53,0,42.38-19.15,42.38-19.15Z" transform="translate(-29.01 -57.31)"
        />

        <!-- ========================================= -->
        <!-- Panel: bumper-f-c -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-f').isSelected()" (click)="clickOnPanel('bumper-f')" id="panel_bumper-f-c"
          class="cls-1" d="M96.76,404.07V760.73s-5.64,8.38-18.3,8.38-14.08-8.38-14.08-8.38v-1.41C56.45,758.44,30,754.27,30,738V422c0-17.41,29.07-18,34.37-18v-3.58s0-7.18,14.08-7.18S96.76,404.07,96.76,404.07Z"
          transform="translate(-29.01 -57.31)" />

        <path id="path4499" class="cls-53" d="M1488.52,413.45V475.1h57.06V405.16h0a43.72,43.72,0,0,0-42.7.18Z" transform="translate(-29.01 -57.31)"
        />
        <path id="path4501" class="cls-53" d="M1488.52,690.87h57.06v66.39h0a46.9,46.9,0,0,1-41.83,2.82l-15.24-6.37Z" transform="translate(-29.01 -57.31)"
        />
        <path id="path4505" class="cls-50" d="M1545.58,463.75V703.22" transform="translate(-29.01 -57.31)" />
        <path id="path4610" class="cls-54" d="M528.14,1023.5a76,76,0,1,1-152,.05s0,0,0-.05a76,76,0,0,1,152-.05Z" transform="translate(-29.01 -57.31)"
        />
        <path id="path4612" class="cls-1" d="M496.47,1023.5a44.34,44.34,0,1,1-44.34-44.34A44.34,44.34,0,0,1,496.47,1023.5Z" transform="translate(-29.01 -57.31)"
        />
        <path id="path4610-4" class="cls-54" d="M1222.68,1023.5a76,76,0,1,1-152,.05s0,0,0-.05a76,76,0,0,1,152-.05Z" transform="translate(-29.01 -57.31)"
        />
        <path id="path4612-3" class="cls-1" d="M1191,1023.5a44.34,44.34,0,1,1-44.34-44.34A44.34,44.34,0,0,1,1191,1023.5Z" transform="translate(-29.01 -57.31)"
        />

        <!-- ========================================= -->
        <!-- Panel: bumper-f-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-f').isSelected()" (click)="clickOnPanel('bumper-f')" id="panel_bumper-f-r"
          class="cls-1" d="M312,211.77l-.11-.3s-25.71,1.43-47.14,1.43S252.3,188,247.65,171.47c-4.81-17.14,20.91-17.14,21.43-17.14l18.57-38.57h65.71s4.54,68.13,52.74,95h-93Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: fender-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('fender-r').isSelected()" (click)="clickOnPanel('fender-r')" id="panel_fender-r"
          class="cls-1" d="M583.25,315.62C544.31,299.93,479,299,404.79,291.47c-84.29-8.57-118.57-32.86-118.57-32.86l41.43-2.86-15.61-44,1-1h93c12.24,6.83,27.31,11,45.83,10.71,66.85-1,87.87-57.07,94.35-87.12h31.31c-8.47,28.3-10.77,70.85-10.77,88C566.82,244.66,570.7,283.73,583.25,315.62Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: door-f-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('door-f-r').isSelected()" (click)="clickOnPanel('door-f-r')" id="panel_door-f-r"
          class="cls-1" d="M854.49,315.65c2.15,21.1,4.43,79.88,5.91,98.18-46.85-.36-98.48-2.07-125.61-12.36,0,0-101.43-55.72-137.14-78.57a87.27,87.27,0,0,0-14.4-7.28c-12.55-31.89-16.43-71-16.43-93.24,0-17.19,2.3-59.73,10.77-88h282.3c-5.4,26.45-11.55,76.53-11.55,96.89C849.5,266.14,850.82,297.56,854.49,315.65Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: b-pillar-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('b-pillar-r').isSelected()" (click)="clickOnPanel('b-pillar-r')" id="panel_b-pillar-r"
          class="cls-1" d="M869.13,232.72c1.17,34.9,2.49,66.32,6.16,84.41,2.1,20.6,4.32,77.09,5.8,96.79l-8.3,0-12.39-.07c-1.48-18.3-3.76-77.08-5.91-98.18-3.67-18.09-5-49.51-6.16-84.41,0-20.36,6.15-70.44,11.55-96.89H881C875.52,160.36,869.13,212,869.13,232.72Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: door-b-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('door-b-r').isSelected()" (click)="clickOnPanel('door-b-r')" id="panel_door-b-r"
          class="cls-1" d="M1113.94,305.21c-33.9,49.54-68.95,86.34-89.75,103.23-36.68,4.32-73.73,6.56-98,5.89-12.69-.35-28.26-.36-45.13-.4-1.48-19.71-3.71-76.2-5.8-96.79-3.67-18.09-5-49.51-6.16-84.41,0-20.75,6.39-72.36,11.86-98.37H1031.4c17.73,55.46,38.88,78.79,63.44,93C1122.75,243.51,1152.13,249.39,1113.94,305.21Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: rocker-panel-r -->
        <!-- ========================================= -->
        <g (click)="clickOnPanel('rocker-panel-r')" id="panel_rocker-panel-r">
          <path class="cls-49" d="M1049.08,115.66h-500a133,133,0,0,1-2.8,18.6H1053A147.27,147.27,0,0,1,1049.08,115.66Z" transform="translate(-29.01 -57.31)"
          />
          <path [class.selected-panel]="getJobPanelByPanelId('rocker-panel-r').isSelected()" class="cls-50" d="M1049.08,115.66h-500a133,133,0,0,1-2.8,18.6H1053A147.27,147.27,0,0,1,1049.08,115.66Z"
            transform="translate(-29.01 -57.31)" />
        </g>

        <!-- ========================================= -->
        <!-- Panel: quarter-panel-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('quarter-panel-r').isSelected()" (click)="clickOnPanel('quarter-panel-r')"
          id="panel_quarter-panel-r" class="cls-1" d="M1349.17,214.07l5.62,51.68,30-4.29s12.86,20-25.71,32.86-57.14,12.86-80,20c-16.64,5.2-110.43,55.93-151.43,72.86-21.65,8.94-62.33,16.41-103.46,21.26,20.8-16.89,55.85-53.69,89.75-103.24,38.19-55.82,8.81-61.69-19.09-77.85-24.56-14.22-45.71-37.55-63.45-93H1053c8.13,29.77,31.32,85,91.75,87.12,16,.55,29.42-3,40.76-9.19h160.53Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: bumper-b-r -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-b').isSelected()" (click)="clickOnPanel('bumper-b')" id="panel_bumper-b-r"
          class="cls-1" d="M1390.51,197.18c-4.29,14.29-41.47,15.65-41.47,15.65l.13,1.24-3.09-1.79H1185.55c47.56-25.76,57.81-96.53,57.81-96.53s74.29,1.43,98.57,0,22.86,12.86,27.14,27.14,10,2.86,18.57,12.86S1394.79,182.9,1390.51,197.18Z"
          transform="translate(-29.01 -57.31)" />

        <path class="cls-47" d="M749.14,395.75c-26.74-7-129.37-70-144.35-88.57H840.52l6.81,95.71S777.27,403.17,749.14,395.75Z" transform="translate(-29.01 -57.31)"
        />
        <path class="cls-47" d="M1017.88,395.73c-13.71,10-79.53,4.33-124.77,5.76L889,307.16h198.8C1076.94,327.92,1043.07,382.07,1017.88,395.73Z"
          transform="translate(-29.01 -57.31)" />
        <path class="cls-47" d="M1155.33,361.35c-25.71,11.43-89.76,33.31-98.33,31.88,0,0,80-84.29,85.71-87.14s115.71-1.43,115.71-1.43S1181,349.92,1155.33,361.35Z"
          transform="translate(-29.01 -57.31)" />
        <path id="rect3950-2" data-name="rect3950" class="cls-51" d="M798.87,256.14h0c0-4,1.31-7.27,2.93-7.27h35.42c1.62,0,2.93,3.26,2.93,7.27h0c0,4-1.31,7.27-2.93,7.27H801.81C800.19,263.41,798.87,260.15,798.87,256.14Z"
          transform="translate(-29.01 -57.31)" />
        <path class="cls-51" d="M1094.82,248.87c1.62,0,2.93,3.26,2.93,7.27s-1.31,7.27-2.93,7.27H1059.4c-1.62,0-2.93-3.26-2.93-7.27s1.31-7.27,2.93-7.27Z"
          transform="translate(-29.01 -57.31)" />
        <path id="path4610-2" data-name="path4610" class="cls-54" d="M528.14,134.4a76,76,0,0,1-152-.05s0,0,0-.05a76,76,0,1,1,152,.05Z"
          transform="translate(-29.01 -57.31)" />
        <path id="path4612-2" data-name="path4612" class="cls-1" d="M452.13,178.69a44.34,44.34,0,1,1,44.34-44.34A44.34,44.34,0,0,1,452.13,178.69Z"
          transform="translate(-29.01 -57.31)" />
        <path id="path4610-4-2" data-name="path4610-4" class="cls-54" d="M1222.68,134.4a76,76,0,0,1-152-.05s0,0,0-.05a76,76,0,1,1,152,.05Z"
          transform="translate(-29.01 -57.31)" />
        <path id="path4612-3-2" data-name="path4612-3" class="cls-1" d="M1146.67,178.69A44.34,44.34,0,1,1,1191,134.35,44.34,44.34,0,0,1,1146.67,178.69Z"
          transform="translate(-29.01 -57.31)" />

        <!-- ========================================= -->
        <!-- Panel: bumper-b-c -->
        <!-- ========================================= -->
        <path [class.selected-panel]="getJobPanelByPanelId('bumper-b').isSelected()" (click)="clickOnPanel('bumper-b')" id="panel_bumper-b-c"
          class="cls-1" d="M1544.52,404.38V761s5.64,8.38,18.3,8.38S1576.9,761,1576.9,761v-1.41c7.93-.88,34.37-5,34.37-21.33v-316c0-17.41-29.07-18-34.37-18v-3.58s0-7.18-14.08-7.18S1544.52,404.38,1544.52,404.38Z"
          transform="translate(-29.01 -57.31)" />

        <rect id="path-3" data-name="path" class="cls-1" x="1459.51" y="417.79" width="56" height="215.76" />

        <!-- ========================================= -->
        <!-- Panel: grill -->
        <!-- ========================================= -->
        <g (click)="clickOnPanel('grill')" id="panel_grill">
          <path [class.selected-panel]="getJobPanelByPanelId('grill').isSelected()" id="path-4" data-name="path" class="cls-1" d="M190.16,483.35H119v194h71.16l2-18.58-12.77-3A13.08,13.08,0,0,1,169.31,643L169,522.95a16.92,16.92,0,0,1,2.64-9.12l1.86-2.92c2.47-2.86,4.56-4.82,7.78-5.64l8.2-2.1Z"
            transform="translate(-29.01 -57.31)" />
          <path id="path4281-8" class="cls-2" d="M133.33,483.35v194" transform="translate(-29.01 -57.31)" />
          <path id="path4281-8-2" data-name="path4281-8" class="cls-2" d="M143.33,483.35v194" transform="translate(-29.01 -57.31)"
          />
          <path id="path4281-8-3" data-name="path4281-8" class="cls-2" d="M155.33,483.35v194" transform="translate(-29.01 -57.31)"
          />
        </g>
      </g>
    </svg>
  </div>
</mat-card>
<div id="panel-list-container">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="details-section-header">
        Panel List
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <div id="panel-list" *ngIf="showPanelList">
      <ng-container *ngFor="let panel of job.panels">
        <mat-checkbox [disabled]="!job.isEditable()" class="panel-checkbox" *ngIf="panel.panel" [checked]="getJobPanelByPanelId(panel.panel.id).isSelected()" (change)="$event.source.checked = !$event.source.checked; clickOnPanel(panel.panel.id)">{{panel.panel.name}}</mat-checkbox>
      </ng-container>

    </div>

  </mat-expansion-panel>
</div>
</div>
