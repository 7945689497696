import { Toolkit } from './../../../../toolkit/toolkit';
import { Batch } from './../../../model/model';
import { MatDialogRef, MAT_DIALOG_DATA, MatSlider, MatInput } from '@angular/material';
import { Component, OnInit, Inject, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dialog-batch-split',
  templateUrl: './dialog-batch-split.component.html',
  styleUrls: ['./dialog-batch-split.component.css']
})
export class DialogBatchSplitComponent implements OnInit {
  public batch: Batch;
  public split_value_g: number;
  public toolkit = new Toolkit();

  public onSubmit = new EventEmitter<number>();

  @ViewChild('slider') slider: MatSlider;
  @ViewChild('newBatchInput') newBatchInput: MatInput;
  @ViewChild('currentBatchInput') currentBatchInput: MatInput;

  constructor(public dialogRef: MatDialogRef<DialogBatchSplitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { DialogData }) {
    this.batch = data['batch'];
    this.split_value_g = Toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2);
  }

  ngOnInit() {
    console.log(this);
  }

  changeCurrentBatchValue(event) {
    let enteredValue = event.srcElement.value;
    if (!isNaN(enteredValue)) {
      this.split_value_g = Toolkit.precision(enteredValue, 2);
    }
  }

  changeNewBatchValue(event) {
    let enteredValue = event.srcElement.value;
    if (!isNaN(enteredValue)) {
      this.split_value_g = Toolkit.precision((this.batch.getTotalQuantityInKg() * 1000) - enteredValue, 2);
    }
  }

  isReadyToSubmit(){
    if(this.currentBatchInput && this.newBatchInput){
      console.log(this.currentBatchInput);
      console.log(this.newBatchInput);
      console.log(this.currentBatchInput.type);
      console.log(this.newBatchInput.value);
      console.log(this.split_value_g);

      console.log(Toolkit.precision(+this.currentBatchInput.value, 2) - Toolkit.precision(+this.split_value_g, 2));
      console.log(Toolkit.precision(+this.newBatchInput.value, 2) - Toolkit.precision((this.batch.getTotalQuantityInKg() * 1000) - this.split_value_g, 2))

      return (Toolkit.precision(+this.currentBatchInput.value, 2) == Toolkit.precision(+this.split_value_g, 2)) && (Toolkit.precision(+this.newBatchInput.value, 2) == Toolkit.precision((this.batch.getTotalQuantityInKg() * 1000) - this.split_value_g, 2))
    }
    
  }

  submit(){
    let split_kg = Toolkit.precision(this.batch.getTotalQuantityInKg() - (this.split_value_g / 1000), 5);
    this.onSubmit.emit(split_kg);
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close();
  }
}
