import { ErrorService } from './../../../services/error.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-error-notifier',
  templateUrl: './error-notifier.component.html',
  styleUrls: ['./error-notifier.component.css']
})
export class ErrorNotifierComponent implements OnInit {

  constructor(private bottomSheetRef: MatDialogRef<ErrorNotifierComponent>,
    public errorService:ErrorService) {
     }

  ngOnInit() {
  }

  dismiss(){
    this.errorService.dismissAll();
    this.bottomSheetRef.close();
  }

}
