<div id="container" *ngIf="batch">
  <ng-container *ngIf="batch.is_matchingBatch || batch.formula.length > 0">
    <div id="table">
      <div id="params">
        <mat-form-field appearance="outline" class="input-min field-short">
          <mat-label>Target</mat-label>
          <input type="number" min="0" class="monospace-number align-right" matInput (keydown.enter)="setTargetAmount(targetQuantityInput.value)"
            #targetQuantityInput [value]="toolkit.precision(batch.quantity_target_kg * 1000, 2)" [disabled]="_isCreating || !batch.isEditable() || isAncillariesConsumed()">
          <span matSuffix>g</span>
        </mat-form-field>

        <div id="creation-indicator" *ngIf="_isCreating">
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>

        <button mat-button class="button-narrow" matTooltip="Set Amount" (click)="setTargetAmount(targetQuantityInput.value)"
          [disabled]="_isCreating || !batch.isEditable() || isAncillariesConsumed()" *ngIf="!_isCreating">
          <i class="material-icons">
            done
          </i>
        </button>


        <div class="spacer-10"></div>


        <mat-form-field appearance="outline" class="input-min field-short" *ngIf="batch.id && !baseOnly">
          <mat-label>Substrate</mat-label>
          <mat-select [value]="batch.substrate" (selectionChange)="changeSubstrate($event.value)" [disabled]="!batch.isEditable() || isAncillariesConsumed()">
            <mat-option [value]="undefined" *ngIf="getSubstrateList().length > 1">

            </mat-option>
            <mat-option *ngFor="let substrate of getSubstrateList()" [value]="substrate">
              {{substrate.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="spacer-10"></div>

        <mat-form-field appearance="outline" class="input-min field-short" *ngIf="batch && getApplicationTechniqueList() && getApplicationTechniqueList().length > 1">
          <mat-label>Technique</mat-label>
          <mat-select (selectionChange)="changeApplicationTechnique($event)" [disabled]="!batch.isEditable() || isAncillariesConsumed()"
            [value]="batch.applicationTechnique">
            <mat-option *ngFor="let appTech of getApplicationTechniqueList()" [value]="appTech">
              {{appTech.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="spacer"></div>

        <ng-container *ngIf="batch.id">
          <button mat-button class="button-narrow" (click)="recalculate()" matTooltip="Re-Calculate" [disabled]="!batch.isEditable() || isAncillariesConsumed() || toolkit.precision(batch.quantity_target_kg, 5) >= batch.getMinimumAmountTotal() ">
            <i class="material-icons">
              replay
            </i>
          </button>
          
          <button mat-button class="button-narrow" matTooltip="From Pre-Mix" (click)="consumeFromPreMix()" *ngIf="batch.variant && batch.variant.id"
            [disabled]="!batch.isEditable() || (batch.variant && !batch.variant.id) || isAncillariesConsumed()">
            <i class="material-icons">
              save_alt
            </i>
          </button>
          <button mat-button class="button-narrow" matTooltip="Split" [disabled]="!isSplittable || !batch.isEditable() || isUploading() || batch.getTotalQuantityInKg() <= 0.01"
            (click)="split()">
            <i class="material-icons">
              call_split
            </i>
          </button>
          <button mat-button class="button-narrow" matTooltip="Discard" [disabled]="batch.is_discarded || isUploading()"
            (click)="discard()">
            <i class="material-icons">
              delete_forever
            </i>
          </button>
          <button mat-button class="button-narrow" color="primary" matTooltip="Done" [disabled]="!batch.isEditable() || isUploading()"
            (click)="done()">
            Done
          </button>
        </ng-container>
      </div>
      <div id="save-indicator">
        <ng-container *ngIf="isUploading()">
          <div id="spinner-container">
            <i id="spinning-indicator" class="fa fa-spinner" aria-hidden="true"></i>
          </div> Saving...
        </ng-container>
      </div>

      <div id="components" *ngIf="batch.id">
        <mat-table #formulaTable [dataSource]="formulaDataSource" id="formula-table">
          <!-- Column -->
          <ng-container matColumnDef="product_code_short">
            <mat-header-cell class="formula-table-cell-product-code" *matHeaderCellDef>
              Short Code
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.component.product">
                {{element.component.product.code_short}}
              </ng-container>
            </mat-cell>
          </ng-container>


          <!-- Column -->
          <ng-container matColumnDef="product_code">
            <mat-header-cell class="formula-table-cell-product-code-short" *matHeaderCellDef>
              Product Code
            </mat-header-cell>
            <mat-cell class="formula-table-cell-product-code-short" *matCellDef="let element">
              <ng-container *ngIf="!element.hide && element.component.product">

                <mat-chip-list #colourChipList>
                  <mat-chip *ngIf="element.component.product">
                    {{element.component.product.code}}

                    <!-- Trace Tinter Icon -->
                    <ng-container *ngIf="element.component.product.tracers && element.component.product.tracers.length > 0">
                      <i matChipRemove class="fa fa-tint tracer-icon" aria-hidden="true"></i>
                    </ng-container>
                    <ng-container *ngIf="element.isAncillary && batch.getComponentByProductId(element.component.product.id).quantity_kg == 0">
                      <mat-icon (click)="deleteComponent(element.component)" matChipRemove *ngIf="batch.isEditable()">cancel</mat-icon>

                    </ng-container>


                  </mat-chip>
                </mat-chip-list>
              </ng-container>


              <ng-container *ngIf="!element.hide && !element.component.product">
                <mat-form-field appearance="outline" class="input-min field-short">
                  <div class="flex-row">
                    <input type="text" #productCodeInput matInput [matAutocomplete]="productCodeAutoComplete"
                      [placeholder]="element.placeholder" [disabled]="!batch.isEditable()">
                  </div>
                </mat-form-field>
                <mat-autocomplete #productCodeAutoComplete="matAutocomplete" (optionSelected)="addProduct($event.option.value, element.component, element.isAncillary); productCodeInput.value=''; "
                  autoActiveFirstOption panelWidth="width: 400px;">
                  <ng-container *ngIf="!element.options">
                    <ng-container *ngFor="let product of productService.products">


                      <!-- show only available (inventory) product -->
                      <ng-container *ngIf="inventoryService.getByProductId(product.id)">

                        <!-- only show products of productType -->
                        <ng-container *ngIf="product.isAvailableIn(batch.product_type)">

                          <!-- only show tinters -->
                          <ng-container *ngIf="product.category.id == 'tinter'">

                            <!-- only show unused products -->
                            <ng-container *ngIf="!containsProduct(product)">

                              <!-- only show matching products -->
                              <ng-container *ngIf="!productCodeInput.value || (productCodeInput.value && product.code_short.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1) || (productCodeInput.value && product.code.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1) || (productCodeInput.value && product.name.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1)">


                                <mat-option [value]="product">
                                  {{product.type.code.toUpperCase()}}: {{product.code_short}} - {{product.name}}
                                </mat-option>
                              </ng-container>
                            </ng-container>

                          </ng-container>
                        </ng-container>

                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="element.options">
                    <ng-container *ngFor="let product of element.options">
                      <!-- show only available (inventory) product -->
                      <ng-container *ngIf="inventoryService.getByProductId(product.id)">

                        <!-- only show matching products -->
                        <ng-container *ngIf="!productCodeInput.value || (productCodeInput.value && product.code_short.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1) || (productCodeInput.value && product.code.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1) || (productCodeInput.value && product.name.toLowerCase().indexOf(productCodeInput.value.toLowerCase().trim()) > -1)">
                          <mat-option [value]="product">
                            {{product.type.code.toUpperCase()}}: {{product.code_short}} - {{product.name}}
                          </mat-option>
                        </ng-container>

                      </ng-container>


                    </ng-container>
                  </ng-container>
                </mat-autocomplete>
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="product_name">
            <mat-header-cell class="formula-table-cell-product-name" *matHeaderCellDef>
              Product Name
            </mat-header-cell>
            <mat-cell class="formula-table-cell-product-name" *matCellDef="let element">

              <ng-container *ngIf="element.component.product">
                {{element.component.product.name}}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="ratio">
            <mat-header-cell class="formula-table-cell-amount align-right" *matHeaderCellDef>
              Ratio
            </mat-header-cell>
            <mat-cell class="formula-table-cell-amount align-right monospace-number" *matCellDef="let element">
              <ng-container *ngIf="element.component.amount_kg">
                {{element.component.ratio * 100 | number:'1.2-2' }}%
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="amount_kg">
            <mat-header-cell class="formula-table-cell-amount align-right" *matHeaderCellDef>
              Target (g)
            </mat-header-cell>
            <mat-cell class="formula-table-cell-amount align-right monospace-number" *matCellDef="let element">
              <ng-container *ngIf="element.purpose && element.purpose == '__base-sum__' && batch.quantity_target_kg">
                <span class="sum">{{toolkit.precision(batch.quantity_target_kg * 1000, 2) | number:'1.2-2'}}</span>
              </ng-container>

              <ng-container *ngIf="element.component.product && batch.getTarget(element.component.product) > 0">
                {{toolkit.precision(batch.getTarget(element.component.product) * 1000, 2) | number:'1.2-2' }}
              </ng-container>
              <ng-container *ngIf="element.component.product && batch.getTarget(element.component.product) == 0">
                -
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="target_amount_kg">
            <mat-header-cell class="formula-table-cell-amount align-right" *matHeaderCellDef>
              &Delta; (g)
            </mat-header-cell>
            <mat-cell class="formula-table-cell-amount align-right monospace-number" *matCellDef="let element">

              <ng-container *ngIf="element.component.product && batch.quantity_target_kg">
                <ng-container *ngIf="toolkit.precision(batch.getDelta(element.component.product) * 1000, 2) < 0">
                  {{toolkit.precision(batch.getDelta(element.component.product) * 1000, 2) | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="toolkit.precision(batch.getDelta(element.component.product) * 1000, 2) == 0">
                  -
                </ng-container>
                <ng-container *ngIf="toolkit.precision(batch.getDelta(element.component.product) * 1000, 2) > 0">
                  <span class="alert-overshoot">
                    +{{toolkit.precision(batch.getDelta(element.component.product) * 1000, 2) | number:'1.2-2'}}
                  </span>
                </ng-container>

              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Column -->
          <ng-container matColumnDef="actual">
            <mat-header-cell class="formula-table-cell-amount align-right" *matHeaderCellDef>
              Consumed (g)
            </mat-header-cell>
            <mat-cell class="formula-table-cell-amount align-right monospace-number" *matCellDef="let element">
              <ng-container *ngIf="element.purpose && element.purpose == '__base-sum__'">
                <span class="sum">{{toolkit.precision(batch.getTotalBaseQuantityInKg() * 1000, 2) | number:'1.2-2'}}</span>
              </ng-container>

              <ng-container *ngIf="element.component.product">
                {{toolkit.precision(batch.getComponentByProductId(element.component.product.id).quantity_kg * 1000, 2)
                | number:'1.2-2' }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="formulaTableColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let row; columns: formulaTableColumns;" [class.component-row]="row.purpose != '__base-sum__'"
            [class.selected]="row.component.product && row.component.product == selected_product" [class.disabled]="isSelectable(row.component)"
            [class.noedit]="!batch.isEditable()" [class.sum-row]="row.purpose && row.purpose == '__base-sum__'" (click)="selectComponent(row.component)"></mat-row>
        </mat-table>
      </div>



    </div>

    <div id="sidepanel-r" *ngIf="batch.id && selected_product && selected_product.id">
      <mat-form-field appearance="outline" class="input-min" #additionField>
        <mat-label>Addition</mat-label>
        <input type="number" min="0" class="user-amount-input monospace-number" matInput (keydown.enter)="setQuantity($event.srcElement.value)" (keydown.Tab)="selectNext($event)" (keydown.shift.Tab)="selectNext($event)"
          value="{{getTareDiff(selected_product) == 0 ? '' : toolkit.precision(getTareDiff(selected_product) * 1000, 2)}}"
          #additionInput [disabled]="!batch.isEditable()">
        <span matSuffix>g</span>
        <div class="target-indicator">
          Target = {{toolkit.precision(getTareTargetDiff(selected_product) * 1000, 2) | number:'1.2-2'}}
        </div>
      </mat-form-field>
      <div id="scale-buttons">
        <button mat-button class="button-narrow button-short" (click)="tare(additionInput)" matTooltip="Tare">
          0.00
        </button>
        <button mat-button class="button-narrow button-short" matTooltip="Set Amount" (click)="setQuantity(additionInput.value)"
          [disabled]="!batch.isEditable()">
          <i class="material-icons">
            done
          </i>
        </button>
      </div>
      <app-mixer-visual id="visualiser" type="bar" [target]="toolkit.precision(batch.getTarget(selected_product) * 1000, 2)"
        [value]="toolkit.precision(batch.getComponentByProductId(selected_product.id).quantity_kg * 1000, 2)" [error]="batch.getFormulaComponentByProductId(selected_product.id).error * 100"></app-mixer-visual>

    </div>
  </ng-container>

  <ng-container *ngIf="!batch.is_matchingBatch && batch.formula.length == 0">
    No Formula found.
  </ng-container>

</div>