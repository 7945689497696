<div id="container">
  <h2>Formula Explorer</h2>
  <app-formula-search 
    primaryButtonLabel="Create Job" 
    (onPrimaryAction)="createJob($event)"
    secondaryButtonLabel="Open in Mix Assistant" 
    (onSecondaryAction)="launchMixAssistant($event)"
    ></app-formula-search>
</div>

