import { DialogPanelSelectForSimpleCoatComponent } from './../dialogs/dialog-panel-select-for-simple-coat/dialog-panel-select-for-simple-coat.component';
import { MatDialog } from '@angular/material';
import { BatchService } from './../../services/batch.service';
import { DataService } from './../../services/data.service';
import { Toolkit } from './../../../toolkit/toolkit';
import { VariantService } from './../../services/variant.service';
import { Job, FormulaComponent, Coat, Batch, ProductType } from './../../model/model';
import { ProductService } from './../../services/product.service';
import { InventoryService } from './../../services/inventory.service';
import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../model/model';
import { CoatService } from '../../services/coat.service';

@Component({
  selector: 'app-simple-coat-mix',
  templateUrl: './simple-coat-mix.component.html',
  styleUrls: ['./simple-coat-mix.component.css']
})
export class SimpleCoatMixComponent implements OnInit {
  @Input() productSelection: Product[];
  @Input() job: Job;
  @Input() coatId: string;

  public _selectedBatch: Batch;
  public toolkit = new Toolkit();

  constructor(public coatService: CoatService,
    public inventoryService: InventoryService,
    public productService: ProductService,
    public variantService: VariantService,
    public dataService: DataService,
    public batchService: BatchService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  getProductsByType(ptype: ProductType) {
    let products: Product[] = [];

    let prods: Product[] = this.inventoryService.getProductsByCategory(this.productService.getProductCategoryById(this.coatId));

    for (let product of prods) {
      if (product.type.id == ptype.id) {
        products.push(product);
      }
    }


    products.sort((a: Product, b: Product) => {
      if (a.name > b.name)
        return 1;

      if (a.name < b.name)
        return -1;

      return 0;
    });

    return products;
  }

  addBatch(coat: Coat, product: Product) {
    let dialogRef = this.dialog.open(DialogPanelSelectForSimpleCoatComponent, {
      data: {
        coat: coat,
        job: this.job
      }
    });
  }

  removeBatch(batch:Batch){
    if(!batch.id && this.job.batches.includes(batch)){
      this.job.batches.splice(this.job.batches.indexOf(batch), 1);
    }
  }

  createBatchFromProduct(coat: Coat, product: Product) {
    if (this.job.getSelectedPanels().length > 0) {
      let dialogRef = this.dialog.open(DialogPanelSelectForSimpleCoatComponent, {
        data: {
          'job': this.job,
          'coat': coat,
          'product_type': product.type
        }
      });

      dialogRef.componentInstance.onSelect.subscribe(result => {
        console.log(result);
        this.createBatch(coat, product, result['total_kg'], result);
      });

      dialogRef.componentInstance.onCancel.subscribe(result => {
        if (result) {
          this.createBatch(coat, product, 0.1);
        }
      });
    }
    else {
      this.createBatch(coat, product, 0.1);
    }
  }

  createBatch(coat: Coat, product: Product, quantity_target_kg: number, selection?:any) {
    let fComponent = new FormulaComponent();
    fComponent.product = product;
    fComponent.ratio = 1.0;

    let batch = new Batch([fComponent], product.type, coat);
    batch.baseProduct = product;
    batch.job_id = this.job.id;
    batch.quantity_target_kg = quantity_target_kg;
    batch.applicationTechnique = this.coatService.default_applicaitonTechnique;
    batch.panelSelection = selection;

    this.job.batches.push(batch);

    this._selectedBatch = batch;
  }

  selectBatch(batch) {
    if (batch == this._selectedBatch) {
      this._selectedBatch = undefined;
    }
    else {
      this._selectedBatch = batch;
    }
  }

  splitBatch(data, colourMatch_id, colour_id, variant_id, is_matchingBatch) {
    this.dataService.blockUIforLoad = true;

    let detailData = {
      'job_id': this.job.id
    }

    if (colourMatch_id)
      detailData['colourMatch_id'] = colourMatch_id;

    if (colour_id)
      detailData['colour_id'] = colour_id;

    if (is_matchingBatch)
      detailData['is_matchingBatch'] = is_matchingBatch;

    if (variant_id)
      detailData['variant_id'] = variant_id;

    if (data['batch']['system'])
      detailData['system'] = data['batch']['system'];


    //call server
    this.batchService.splitBatch(data['batch'], data['split_kg'], detailData).subscribe(batches => {
      //remove the batch from the job
      this.job.batches.splice(this.job.batches.indexOf(data['batch']), 1);

      //add new batches
      this.job.batches.push(batches[0]);
      this.job.batches.push(batches[1]);

      this._selectedBatch = batches[0];
      this.dataService.blockUIforLoad = false;
    }, error => {
      this.dataService.blockUIforLoad = false;
      throw error;
    });
  }
}
