import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Country } from './../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private api_endpoint = '/country';
  private full_api_url;
  public countries: Country[] = []
  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  constructor(private dataService: DataService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);
    this.countries = [];

    this.dataService.getRequest(this.full_api_url).subscribe(response => {
      if (response && response['data']) {

        for (let country of response['data']) {
          this.countries.push(this.buildCountry(country));
        }

        this.countries.sort((a: Country, b: Country) => {
          if (a.name_common < b.name_common) {
            return -1;
          }
          if (a.name_common > b.name_common) {
            return 1;
          }

          return 0;
        });

        this.lastRefresh = new Date();
        this.initialised.next(true);
      }
    });
  }

  getCountryById(id:string){
    for(let ctry of this.countries){
      if(ctry.id == id){
        return ctry;
      }
    }
  }

  buildCountry(response) {
    let country: Country = undefined;
    
    if (response) {
      country = new Country();
      country.id = response['id'];
      country.iso_3166 = response['iso_3166'];
      country.name_common = response['name_common'];
      country.name_full = response['name_full'];
    }

    return country;
  }
}
