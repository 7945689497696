<div id="container">
  <div id="header">
    Error
  </div>
  <div id="messages">
    <div class="message" *ngFor="let error of errorService.errors">
      {{error.message}}
    </div>
  </div>

  <div id="actions">
    <button mat-stroked-button color="primary" (click)="dismiss()" class="full-width">Dismiss</button>
  </div>
</div>