import { Toolkit } from './../../toolkit/toolkit';
import { ColourService } from './colour.service';
import { ProductService } from './product.service';
import { Colour, ProductType, FormulaComponent, Variant, VariantCoat, Substrate, VariantCoatAttempt, Batch } from './../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { CoatService } from './coat.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  private api_endpoint_variant = '/variant';
  private full_api_url_variant;

  private api_endpoint_substrate = '/substrate';
  private full_api_url_substrate;

  private api_variant_delim_colour = "/colour/";
  private api_variant_delim_productType = "/product/";

  public substrates: Substrate[] = [];
  public initialised = new BehaviorSubject(false); //for caching substrates

  constructor(private dataService: DataService,
    private productService: ProductService,
    private coatService: CoatService,
    private colourService: ColourService) {

    this.full_api_url_variant = dataService.getUrl() + this.api_endpoint_variant;
    this.full_api_url_substrate = dataService.getUrl() + this.api_endpoint_substrate;
  }

  getVariants(colour: Colour, productType?: ProductType) {
    let requestUrl = this.full_api_url_variant;
    requestUrl += this.api_variant_delim_colour + colour.id;


    if (productType) {
      requestUrl += this.api_variant_delim_productType + productType.id
    }

    return this.dataService.getRequest(requestUrl).map(response => {
      let variants: Variant[] = [];

      if (response && response['data']) {
        for (let item of response['data']) {
          variants.push(this.buildVariant(item));
        }
      }

      return variants;
    });
  }

  getVariant(id: number) {
    let requestUrl = this.full_api_url_variant;
    requestUrl += "/" + id;

    return this.dataService.getRequest(requestUrl).map(response => {
      if (response) {
        return this.buildVariant(response);
      }
    });
  }

  storeVariant(variant: Variant) {
    let requestUrl = this.full_api_url_variant;
    let requestBody = {
      'colour': variant.colour.id,
      'product_type': variant.product_type.id,
      'system': variant.system,
      'coats': {}
    };

    console.log(variant);

    for (let coat of variant.coats) {
      if (coat.formula && coat.formula.length > 0) {
        let formula = [];

        for (let component of coat.formula) {
          formula.push({
            'product': component.product.id,
            'ratio': Toolkit.precision(component.ratio, 4)
          });
        }

        requestBody['coats'][coat.coat.id] = formula;
      }
    }

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      if (response) {
        console.log(response);
        return this.buildVariant(response);
      }
    });
  }


  buildVariant(response: object) {
    let variant = new Variant();
    variant.id = response['id'];
    variant.name = response['code'];
    variant.version = response['version'];
    variant.is_active = response['is_active'];
    variant.product_type = this.productService.getProductTypeById(response['product_type']);
    variant.colour = this.colourService.buildColour(response['colour']);
    variant.system = response['system'];

    if (response['coats']) {
      variant.coats = [];

      for (let coatKey in response['coats']) {
        let vCoat = new VariantCoat();
        vCoat.coat = this.coatService.getCoatById(coatKey);
        if (response['coats'][coatKey]) {
          vCoat.formula = this.buildFormula(response['coats'][coatKey]);
        }
        else {
          vCoat.formula = [];
        }
        variant.coats.push(vCoat);
      }

      variant.sortFormulasByCoat();
    }

    //history is necessary for variants coming from colour matches
    if (response['history']) {
      for (let coatKey in response['history']) {
        let coat = variant.getCoatByCoatId(coatKey);
        coat.history = [];

        for (let attempt of response['history'][coatKey]) {
          let vcattempt = new VariantCoatAttempt();
          vcattempt.formula = this.buildFormula(attempt['formula']);
          vcattempt.timestamp = new Date(attempt['timestamp']);
          vcattempt.f_index = attempt['f_index'];

          let batch = new Batch();
          batch.id = attempt['batch_test']
          vcattempt.batch_test = batch;

          coat.history.push(vcattempt);
        }
      }
    }

    return variant;
  }

  private buildFormula(responseFormula) {
    let formula = [];

    for (let responseCmp of responseFormula) {
      let fComponent = new FormulaComponent();
      fComponent.ratio = responseCmp['ratio'];
      fComponent.product = this.productService.getProductById(responseCmp['product']);

      formula.push(fComponent);
    }

    return formula;
  }


  buildRequestBody(variant: Variant) {
    let requestBody = {};
    if (variant.id)
      requestBody['id'] = variant.id;

    requestBody['name'] = variant.name;
    requestBody['version'] = variant.version;
    requestBody['is_active'] = variant.is_active;
    requestBody['colour'] = variant.colour ? variant.colour.id : null;
    requestBody['product_type'] = variant.product_type ? variant.product_type.id : null;
    requestBody['system'] = variant.system;


    requestBody['coats'] = {};
    for (let coat of variant.coats) {
      requestBody['coats'][coat.coat.id] = {}

      requestBody['coats'][coat.coat.id] = [];
      for (let component of coat.formula) {
        let nComp = {};
        nComp['product'] = component.product.id;
        nComp['ratio'] = Toolkit.precision(component.ratio, 4);
        nComp['error'] = component.error ? Toolkit.precision(component.error, 4) : 0.0;
        requestBody['coats'][coat.coat.id].push(nComp);
      }
    }

    //history
    /*
    requestBody['coats'][coat.coat.id]['history'] =[];
    for(let attempt of coat.history){
      let nAttempt = {};
      nAttempt['timestamp'] = attempt.timestamp.toISOString();

      for(let component of coat.formula){
        let nComp = {};
        nComp['product'] = component.product.id;
        nComp['ratio'] = Toolkit.precision(component.ratio, 2);
        nComp['error'] = component.error ? Toolkit.precision(component.error, 2) : 0.0;
        nAttempt['formula'].push(nComp);
      }

      requestBody['coats'][coat.coat.id]['history'].push(nAttempt);
    }
    */


    return requestBody;
  }

  getSubstrate(id: string): Observable<Substrate> {
    for (let cachedSubstrate of this.substrates) {
      if (cachedSubstrate.id == id) {
        return new Observable((observer) => {
          observer.next(cachedSubstrate);
          observer.complete();
        });
      }
    }

    //if not found in cache, get from server
    let requestUrl = this.full_api_url_substrate;
    requestUrl += "/" + id;

    this.dataService.getRequest(requestUrl).map(response => {
      return this.buildSubstrate(response);
    });
  }

  getSubstrateOffline(id: string): Substrate {
    for (let cachedSubstrate of this.substrates) {
      if (cachedSubstrate.id == id) {
        return cachedSubstrate;
      }
    }
  }


  buildSubstrate(response): Substrate {
    let substrate = new Substrate();

    substrate.id = response['id'];
    substrate.name = response['name'];

    return substrate;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url_substrate).subscribe(response => {
      if (response && response['data']) {
        this.substrates = [];

        for (let substrate of response['data']) {
          this.substrates.push(this.buildSubstrate(substrate));
        }

        this.initialised.next(true);
      }
    });
  }
}
