import { JobService } from './../../../services/job.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-primer',
  templateUrl: './job-primer.component.html',
  styleUrls: ['./job-primer.component.css']
})
export class JobPrimerComponent implements OnInit {

  constructor(public jobService:JobService) { }

  ngOnInit() {
  }

  job(){
    return this.jobService.currentJob;
  }

}
