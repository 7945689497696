import { CoatService } from './../../services/coat.service';
import { Coat, Job, JobPanel, JobPanelSurface, Variant, JobPanelCoat, CarModel, Colour, ColourMatch, RepairType } from './../../model/model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VehicleService } from '../../services/vehicle.service';
import { VariantService } from '../../services/variant.service';
import { PanelService } from '../../services/panel.service';

@Component({
  selector: 'app-panel-options',
  templateUrl: './panel-options.component.html',
  styleUrls: ['./panel-options.component.css']
})
export class PanelOptionsComponent implements OnInit {
  public job_panel: JobPanel;
  public job: Job;
  //public colours:JobColour[];
  public carModel: CarModel;

  constructor(public vehicleService: VehicleService,
    public coatService: CoatService,
    public variantService: VariantService,
    public panelService: PanelService,
    public dialogRef: MatDialogRef<PanelOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.job_panel = data.panel;
    this.job = data.job;
    //this.colours = data.colours;
    //this.carModel = data.carModel;

    //create empty surfaces
    if (!this.job_panel.getSurface(true)) {
      this.job_panel.surfaces.push(this.resetSurface(true));
    }

    if (!this.job_panel.getSurface(false)) {
      this.job_panel.surfaces.push(this.resetSurface(false));
    }

    //set colour on default if none is set
    if (!this.job_panel.colour && this.job.colours.length > 0) {
      this.job_panel.colour = this.job.colours[0];
    }
  }

  ngOnInit() {

  }

  isRepairTypeAvailable(surface: JobPanelSurface, repair:RepairType) {
    let surface_id = surface.is_inside ? 'inside' : 'outside';
    if (this.job.car_model.estimates[this.job_panel.panel.id][surface_id] && this.job.car_model.estimates[this.job_panel.panel.id][surface_id][repair.id])
      return true;
    else
      return false;

  }

  changeRepair(isInside: boolean, selectedRepair: RepairType) {
    let surface: JobPanelSurface;

    if (isInside) {
      surface = this.job_panel.getSurfaceInside();
    }
    else {
      surface = this.job_panel.getSurfaceOutside();
    }

    surface.repair = selectedRepair;

    if (surface.repair)
      surface.is_selected = true;
    else
      surface.is_selected = false;
  }

  resetSurface(inside: boolean): JobPanelSurface {
    let surface = new JobPanelSurface();
    //surface.repair = this.panelService.default_repair;
    surface.coats = [];
    surface.is_inside = inside;

    if (this.job_panel.colour) {
      //add PRIMER coat
      for (let coat of this.coatService.getCoatsByTypeId('primer')) {
        let coat_primer = new JobPanelCoat();
        coat_primer.application_technique = this.coatService.default_applicaitonTechnique;
        coat_primer.coat = coat;
        coat_primer.is_selected = true;
        surface.coats.push(coat_primer);
      }


      //add other coats
      console.log(this.job_panel.colour);
      if (this.job_panel.colour.variant) {
        for (let vCoat of this.job_panel.colour.variant.coats) {
          let coat = new JobPanelCoat();
          coat.application_technique = this.coatService.default_applicaitonTechnique;
          coat.coat = vCoat.coat;
          coat.is_selected = true;

          surface.coats.push(coat);
        }
      }

      //add CLEAR coat
      for (let coat of this.coatService.getCoatsByTypeId('clear')) {
        let coat_clear = new JobPanelCoat();
        coat_clear.application_technique = this.coatService.default_applicaitonTechnique;
        coat_clear.coat = coat;
        coat_clear.is_selected = true;
        surface.coats.push(coat_clear);
      }
    }

    return surface;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}