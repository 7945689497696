import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-dialog-two-options',
  templateUrl: './dialog-two-options.component.html',
  styleUrls: ['./dialog-two-options.component.css']
})
export class DialogTwoOptionsComponent {
  public title:string;
  public text:string;
  public buttonPrimary_text:string;
  public buttonSecondary_text:string;

  public onPrimary = new EventEmitter<boolean>();
  public onSecondary = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<DialogTwoOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {DialogData}) { 
      this.title = this.data['title'];
      this.text = this.data['text'];
    this.buttonPrimary_text = this.data['buttonPrimary_text'];
    this.buttonSecondary_text = this.data['buttonSecondary_text'];

    }

  clickPrimary(){
    this.onPrimary.emit(true);
    this.dialogRef.close();
  }

  clickSecondary(){
    this.onSecondary.emit(true);
    this.dialogRef.close();
  }
}
