import { JobVariantSelectionComponent } from './../../job-variant-selection/job-variant-selection.component';
import { MatDialog } from '@angular/material';
import { JobService } from './../../../services/job.service';
import { ColourMatchService } from './../../../services/colour-match.service';
import { ActivatedRoute, Router } from '@angular/router/';
import { Component, OnInit } from '@angular/core';
import { ColourMatch, Variant } from '../../../model/model';

@Component({
  selector: 'app-job-colour',
  templateUrl: './job-colour.component.html',
  styleUrls: ['./job-colour.component.css']
})
export class JobColourComponent implements OnInit {
  public cMatch: ColourMatch;

  constructor(public route: ActivatedRoute,
    private router:Router,
    public colourMatchService: ColourMatchService,
    public jobService: JobService,
    public dialog: MatDialog) {

    this.route.params.subscribe(params => {
      let found = false;
  
        for (let cm of this.jobService.currentJob.colours) {
          if (cm.colour.code == params['colourCode']) {
            if (cm.is_active) {
              this.cMatch = cm;
              found = true;
              break;
            }
          }
        }
  
        if (!found)
          this.router.navigate(['../../'], { relativeTo: this.route });
    });
  }

  ngOnInit() {
  }

  job() {
    return this.jobService.currentJob;
  }

  addVariantToColour() {
    let dialogRef = this.dialog.open(JobVariantSelectionComponent, {
      data: { 'jobColour': this.cMatch }
    });

    dialogRef.componentInstance.onVariantChange.subscribe(evt_variant => {
      this.colourMatchService.changeVariant(this.cMatch, evt_variant).subscribe(cm => {
        this.cMatch.variant = cm.variant;
      });
    });
  }

  addEmptyVariantToColour() {
    this.cMatch.variant = new Variant();
    this.cMatch.variant['isNew'] = true;
  }

  updateVariant(newVariant: Variant) {
    this.colourMatchService.changeVariant(this.cMatch, newVariant).subscribe(cm => {
      this.cMatch.variant = cm.variant;
    });
  }
}
