import { CountryService } from './country.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User, Bodyshop, Tenant, Currency, Country } from '../model/model';

@Injectable()
export class UserService {
  private api_endpoint = '/user';
  private full_api_url;

  public currentUser: User;

  public initialised = new BehaviorSubject(false);
  public lastRefresh = null;


  constructor(public dataService: DataService, public countryService:CountryService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  public refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url).subscribe(response => {
      if (response) {
        this.currentUser = this.buildUser(response);

        this.currentUser.bodyshops = [];

        //tenant
        this.currentUser.tenant = new Tenant();
        this.currentUser.tenant.id = response['tenant']['id'];
        this.currentUser.tenant.name = response['tenant']['name'];

        this.currentUser.tenant.currency = new Currency();
        this.currentUser.tenant.currency.id = response['tenant']['currency']['id'];
        this.currentUser.tenant.currency.iso_4217 = response['tenant']['currency']['iso_4217'];
        this.currentUser.tenant.currency.name = response['tenant']['currency']['name'];

        this.currentUser.tenant.country = new Country();
        this.currentUser.tenant.country.id = response['tenant']['country']['id'];
        this.currentUser.tenant.country.iso_3166 = response['tenant']['country']['iso_3166'];
        this.currentUser.tenant.country.name_common = response['tenant']['country']['name_common'];
        this.currentUser.tenant.country.name_full = response['tenant']['country']['name_full'];

        //set current bodyshop if specified
        let bshop = this.buildBodyshop(response['bodyshop']);

        this.currentUser.currentBodyshop = bshop;

        this.lastRefresh = new Date();
        this.initialised.next(true);
      }

    });
  }

  buildUser(response): User {
    let user: User;

    if (response) {
      user = new User();

      user.id = response['id'];
      user.email = response['email'];
      user.employee_nb = response['employee_nb'];
      user.name = response['full_name'];
      user.bodyshop_id = response['bodyshop'];
      user.is_active = response['is_active'];
      user.is_kansai = response['is_kansai'];
      user.is_developer = response['is_developer'];
      user.is_tenantAdmin = response['is_tenantAdmin'];
      user.permissions = response['permissions'];
      user.reportingBodyshops = [];

      if (response['reporting_bodyshops']) {
        for (let item of response['reporting_bodyshops']) {
          let bshop;
          
          if(typeof item == 'number'){
            bshop = new Bodyshop();
            bshop.id = item;
          }
          else{
            bshop = this.buildBodyshop(item);
          }
           

          user.reportingBodyshops.push(bshop);
        }
      }

    }
    
    return user;
  }

  buildBodyshop(response) {
    let bshop = new Bodyshop();

    bshop.id = response['id'];
    bshop.id_custom = response['id_custom'];
    bshop.name = response['name'];
    bshop.is_active = response['is_active'];
    bshop.is_deleted = response['is_deleted'];
    bshop.address = {};

    if (response['address']) {
      let address = response['address'];

      if (address['line_1'])
        bshop.address['line_1'] = address['line_1'];

      if (address['line_2'])
        bshop.address['line_2'] = address['line_2'];

      if (address['post_code'])
        bshop.address['post_code'] = address['post_code'];

      if (address['city'])
        bshop.address['city'] = address['city'];

      if (address['country'])
        this.countryService.initialised.subscribe(state => {
          if (state) {
            bshop.address['country'] = this.countryService.getCountryById(address['country']);
          }
        });


      if (address['coordinates'])
        bshop.address['coordinates'] = address['coordinates'];
    }
    
    return bshop;
  }

  private findBodyshopById(id: number) {
    let returnBs: Bodyshop = undefined;

    for (let bs of this.currentUser.bodyshops) {
      if (bs.id == id) {
        returnBs = bs;
        break;
      }
    }

    return returnBs;
  }


  bodyshopChange(newBodyshop) {
    this.currentUser.currentBodyshop = newBodyshop;
  }
}
