import { DataService } from './../../../services/data.service';
import { Bodyshop } from './../../../model/model';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { Toolkit } from '../../../../toolkit/toolkit';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-report-product-consumption',
  templateUrl: './report-product-consumption.component.html',
  styleUrls: ['./report-product-consumption.component.css']
})
export class ReportProductConsumptionComponent implements OnInit {
  public date_to: Date = new Date();
  public date_from: Date = new Date();
  public frequency = "day";
  public bodyshops: Bodyshop[] = [];

  public _isLoading = false;
  public _frequencyOnDisplay;

  public dataSource = new MatTableDataSource<any>();
  public data = [];
  public data_total_rows = {};
  public data_total_cols = {};
  public displayedColumns = [];
  public dynamicColumns = [];
  public dyanicColumns_labels = {};


  constructor(public userService: UserService,
    private dataService: DataService,
    private productService: ProductService) {
    //default span t-30d 
    this.date_from.setDate(this.date_to.getDate() - 30);

  }

  ngOnInit() {
  }

  isReady() {
    if (!this.date_to)
      return false;

    if (!this.date_from)
      return false;

    if (!this.frequency)
      return false;

    if (this.bodyshops.length > 0)
      return true;
    else
      return false;
  }

  refresh() {
    if (this.isReady()) {
      this._isLoading = true;

      let url = this.dataService.getUrl() + "/report";
      let requestBody = {
        'report': 'consumption_by_product_timeseries',
        'params': {
          'bodyshops': [],
          'start': Toolkit.getDateString(this.date_from),
          'end': Toolkit.getDateString(this.date_to),
          'frequency': this.frequency
        }
      };

      for (let bshop of this.bodyshops) {
        requestBody['params']['bodyshops'].push(bshop.id);
      }

      this.dataService.postRequest(url, requestBody).subscribe(response => {
        if (response && response['report'] && response['report']['data']) {
          console.log(response);

          let tmpData = {};
          this.dynamicColumns = [];
          this._frequencyOnDisplay = requestBody['params']['frequency'];

          let first_date;
          let last_date;

          for (let row of response['report']['data']) {
            //get earliest and latest date in response
            if (!first_date || first_date > row[this._frequencyOnDisplay]) {
              first_date = row[this._frequencyOnDisplay];
            }

            if (!last_date || last_date < row[this._frequencyOnDisplay]) {
              last_date = row[this._frequencyOnDisplay];
            }

            //columns
            if (!this.dynamicColumns.includes(row[this._frequencyOnDisplay])) {
              this.dynamicColumns.push(row[this._frequencyOnDisplay]);
            }

            //rows
            if (!tmpData[row['product']]) {
              tmpData[row['product']] = {
                'product': this.productService.getProductById(row['product']),
                'values': {}
              }
            }

            tmpData[row['product']]['values'][row[this._frequencyOnDisplay]] = {
              'cost': row['cost'],
              'kg': row['kg']
            }
          }

          //adjust columns
          this.displayedColumns = ['product'];
          
          if(this._frequencyOnDisplay == "day"){
            this.dyanicColumns_labels = {};
            this.dynamicColumns = Toolkit.getTimeseriesItemsDays(this.date_from, this.date_to);

            for(let item of this.dynamicColumns){
            }
          }

          this.displayedColumns = this.displayedColumns.concat(this.dynamicColumns);

          console.log(this._frequencyOnDisplay);
          console.log(this.displayedColumns);
          console.log(Toolkit.getTimeseriesItemsDays(this.date_from, this.date_to));


          //convert to list
          this.data = [];
          for (let key in tmpData) {
            this.data.push(tmpData[key]);
          }

          this.dataSource = new MatTableDataSource(this.data);

          console.log(this);

          console.log(first_date);
          console.log(last_date);

        }

        this._isLoading = false;
      }, error => {
        console.log(error);
        this._isLoading = false;
      });
    }
  }

  isBodyshopSelected(bshop: Bodyshop) {
    return this.bodyshops.includes(bshop);
  }

  clickBodyshop(event) {
    if (event.checked) {
      if (!this.bodyshops.includes(event.source.value))
        this.bodyshops.push(event.source.value);
    }
    else {
      if (this.bodyshops.includes(event.source.value))
        this.bodyshops.splice(this.bodyshops.indexOf(event.source.value), 1);
    }
  }


  ngForIn(dict) {
    return Object.keys(dict);
  }
}
