import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { ColourService } from './colour.service';
import { VariantService } from './variant.service';
import { ColourMatch, Variant, Batch, Coat } from './../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColourMatchService {
  private api_endpoint = '/colourmatch';
  private full_api_url;

  constructor(public dataService: DataService,
    public variantService: VariantService,
    public colourService: ColourService,
    public userService: UserService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getColourMatch(id: number) {
    if (id) {
      return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
        if (response) {
          return this.buildColourMatch(response);
        }
      });
    }
  }

  saveColourMatch(cMatch: ColourMatch): Observable<ColourMatch> {
    let request;
    let requestBody = this.buildRequestBody(cMatch);

    if (cMatch.id) {
      request = this.dataService.putRequest(this.full_api_url + "/" + cMatch.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(this.full_api_url, requestBody);
    }

    return request.map(response => {
      return this.buildColourMatch(response);
    });
  }

  changeVariant(cMatch: ColourMatch, variant: Variant): Observable<ColourMatch> {
    let requestBody = {
      'target': 'variant',
      'action': 'switch_variant',
      'params': {
        'variant': this.variantService.buildRequestBody(variant)
      }
    }

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      return this.buildColourMatch(response);
    });
  }

  changeDevelopmentBatch(cMatch: ColourMatch, coat: Coat, batch: Batch): Observable<ColourMatch> {
    let requestBody = {
      'target': 'coat',
      'action': 'add_batch_dev',
      'params': {
        'coat': coat.id,
        'batch': batch.id
      }
    }

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      console.log(response);
      return this.buildColourMatch(response);
    });
  }

  nextFormula(cMatch: ColourMatch, coat: Coat, batch: Batch): Observable<ColourMatch> {
    let requestBody = {
      'target': 'coat',
      'action': 'next_formula',
      'params': {
        'coat': coat.id,
        'batch_test': batch.id
      }
    }
  
    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      console.log(response);
      return this.buildColourMatch(response);
    });
  }

  discard(cMatch:ColourMatch){
    return this.dataService.deleteRequest(this.full_api_url + "/" + cMatch.id).map(response => {
      console.log(response);
      return this.buildColourMatch(response);
    });
  }

  buildRequestBody(cMatch: ColourMatch) {
    let requestBody = {};
    console.log(cMatch);

    if (cMatch.id)
      requestBody['id'] = cMatch.id;

    requestBody['colour'] = cMatch.colour.id;
    requestBody['bodyshop'] = this.userService.currentUser.currentBodyshop.id;
    requestBody['job'] = cMatch.job_id ? cMatch.job_id : null;
    requestBody['is_active'] = cMatch.is_active;
    requestBody['is_locked'] = cMatch.is_locked;
    requestBody['variant'] = cMatch.variant ? this.variantService.buildRequestBody(cMatch.variant) : null;
    requestBody['variant_init'] = cMatch.variant_init ? this.variantService.buildRequestBody(cMatch.variant_init) : null;

    requestBody['history'] = [];
    if (cMatch.history) {
      for (let item of cMatch.history) {
        let histItem = {};
        histItem['timestamp'] = item.timestamp.toISOString();
        histItem['variant'] = this.variantService.buildRequestBody(item.variant);

        requestBody['history'].push(histItem);
      }
    }

    return requestBody;
  }


  buildColourMatch(response) {
    let cMatch = new ColourMatch();
    cMatch.id = response['id'];
    cMatch.job_id = response['job'];
    cMatch.is_active = response['is_active'];
    cMatch.is_locked = response['is_locked'];
    cMatch.variant = response['variant'] ? this.variantService.buildVariant(response['variant']) : undefined;
    cMatch.colour = this.colourService.buildColour(response['colour']);

    cMatch.batches_dev = response['dev_batches'];
    if (!cMatch.batches_dev)
      cMatch.batches_dev = {};

    return cMatch;
  }
}
