<div class="coat-container">
  <div class="batch-container">
    <button mat-stroked-button color="primary" class="add-batch" [matMenuTriggerFor]="primerProductMenu" [disabled]="!job.isEditable()">
      <i class="material-icons">
        add
      </i>
    </button>
    <mat-menu #primerProductMenu="matMenu">
      <ng-container *ngFor="let ptype of productService.productTypes">
        <ng-container *ngIf="getProductsByType(ptype).length > 0">
          <div class="ptype-name">
            {{ptype.name}}
          </div>

          <ng-container *ngFor="let product of getProductsByType(ptype)">
            <button mat-menu-item (click)="createBatchFromProduct(productService.getProductCategoryById(coatId), product)">
              {{product.name}}
            </button>
          </ng-container>

          <div class="h-spacer-20"></div>
        </ng-container>

      </ng-container>

    </mat-menu>

    <div class="batch-container-substrate" *ngIf="job.getBatchesForCoatAndSubstrate(coatService.getCoatById(coatId), null).length > 0">
      <div class="batch-container-substrate-name">
        <div class="substrate-name">Undefined</div>
        <div class="substrate-target">
        </div>
        <div class="substrate-actual"> </div>
        <div class="substrate-diff"> </div>
      </div>
      <div class="batch-card-container">
        <ng-container *ngFor="let batch of job.getBatchesForCoatAndSubstrate(coatService.getCoatById(coatId), null)">
            <app-batch-card [batch]="batch" *ngIf="!batch.is_discarded" [class.selected]="_selectedBatch == batch" (click)="selectBatch(batch)" class="batch-card"(onDelete)="removeBatch(batch)"></app-batch-card>
        </ng-container>
      </div>
    </div>


    <ng-container *ngFor="let substrate of variantService.substrates">
      <ng-container *ngFor="let appTech of coatService.applicationTechniques">
        <div class="batch-container-substrate" *ngIf="job.getBatchesForCoatAndSubstrateAndAppTech(coatService.getCoatById(coatId), substrate, appTech).length > 0">
          <div class="batch-container-substrate-name">
            <div class="substrate-name">{{substrate.name}} - {{appTech.id.toUpperCase()}}</div>
            <div class="substrate-target">
            </div>
            <div class="substrate-actual"></div>
            <div class="substrate-diff"></div>
          </div>
          <div class="batch-card-container">
            <ng-container *ngFor="let batch of job.getBatchesForCoatAndSubstrateAndAppTech(coatService.getCoatById(coatId), substrate, appTech)">
              
              <app-batch-card [batch]="batch" *ngIf="!batch.is_discarded" [class.selected]="_selectedBatch == batch" (click)="selectBatch(batch)" class="batch-card"(onDelete)="removeBatch(batch)"></app-batch-card>

            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>



  </div>

  <div class="mix-assistant-container">
    <!-- create new component for each -->
    <ng-container *ngFor="let batch of job.getBatchesForCoat(coatService.getCoatById(coatId))">
      <app-mixer [batch]="_selectedBatch" *ngIf="_selectedBatch == batch" (onSplit)="splitBatch($event)"></app-mixer>
    </ng-container>
  </div>
</div>