import { ActivatedRoute, Router } from '@angular/router/';
import { JobService } from './../../services/job.service';
import { MatTableDataSource } from '@angular/material';
import { Job, Bodyshop } from './../../model/model';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  public jobs: Job[];
  public jobsTableDataSource: MatTableDataSource<Job>;
  public jobsTableColumns = [];

  public _isLoading = false;

  constructor(private jobService: JobService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router) {
    this.route.params.subscribe(params => {
      this._isLoading = true;
      this.jobsTableColumns = ['ts_created', 'id_custom', 'vehicle', 'car_license_plate', 'customer_name'];
      let status = params['jobStatus'] ? params['jobStatus'] : 'open';

      this.userService.initialised.distinctUntilChanged().subscribe(state => {
        if (state) {
          this.getJobs(status);
        }
      });
    });

  }

  ngOnInit() {
  }

  createNewJob() {
    this.router.navigate(['/job', 'detail']);
  }

  

  getJobs(status: string, ts_from?: Date) {
    let bodyshops = [this.userService.currentUser.currentBodyshop];

    this.jobService.queryJobs(bodyshops, status).subscribe(jobs => {
      jobs.sort((a:Job, b:Job) => {
        return a.date_created.getTime() - b.date_created.getTime();
      });


      this.jobs = jobs;
      this.jobsTableDataSource = new MatTableDataSource(this.jobs);
      this.jobsTableColumns.push('actions');
      this._isLoading = false;
    },
    error => {
      console.log(error);
      this._isLoading = false;
    });
  }



  navigateToJob(job: Job) {
    this.router.navigate(['/job', job.id, 'detail']);
  }

}