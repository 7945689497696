import { CarManufacturer, Colour } from './../../../model/model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-new-colour',
  templateUrl: './dialog-new-colour.component.html',
  styleUrls: ['./dialog-new-colour.component.css']
})
export class DialogNewColourComponent implements OnInit {
  carManufactuer:CarManufacturer;
  existingColours:Colour[] = [];

  onColourCreation = new EventEmitter<Colour>();
  onCancel = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<DialogNewColourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.carManufactuer = data['car_manufacturer'];

      if(data['existing_colours']){
        this.existingColours = data['existing_colours'];
      }
  }


  ngOnInit() {

  }

  newColourCreated(colour){
    console.log(colour);
    this.onColourCreation.emit(colour);
    this.dialogRef.close();
  }

  cancel(){
    this.onCancel.emit(true);
    this.dialogRef.close();
  }
}
