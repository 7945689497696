<div id="container">
  <div id="title">
    Splitting a Batch
  </div>
  <div id="text">
    Choose how the current batch of {{toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2) | number:'1.2-2'}}g should be split.
  </div>

  <div id="slider-container" *ngIf="batch">
    <div id="left-value" class="side-panel">
      <div class="value-name">Current Batch</div>
      <div class="value-g">
        <mat-form-field appearance="outline" class="form-field input-min" *ngIf="slider.value">
          <input matInput 
          type="number" 
          [min]="0"
          [max]="toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2)"
          value="{{slider.value}}" 
          class="input-field" 
          (keydown.enter)="changeCurrentBatchValue($event)"
          (keydown.arrowup)="changeCurrentBatchValue($event)"
          (keydown.arrowdown)="changeCurrentBatchValue($event)"
          #currentBatchInput
          >
          <span matSuffix>g</span>
        </mat-form-field>
      </div>
      <div class="value-percent">
        {{100 * slider.value / (this.batch.getTotalQuantityInKg() * 1000) | number:'1.2-2'}}%
      </div>
    </div>
      <mat-slider 
      id="slider"
      [min]="10" 
      [max]="toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2) - 10" 
      [(ngModel)]="split_value_g"
      [thumbLabel]="true"
      #slider
      ></mat-slider>
    <div id="right-value" class="side-panel">
      <div class="value-name">New Batch</div>
      <div class="value-g">
          <mat-form-field appearance="outline" class="form-field input-min">
              <input matInput 
              type="number" 
              [min]="0"
              [max]="toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2)"
              value="{{toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2) - slider.value}}" 
              class="input-field" 
              (keydown.enter)="changeNewBatchValue($event)"
              (keydown.arrowup)="changeNewBatchValue($event)"
              (keydown.arrowdown)="changeNewBatchValue($event)"
              #newBatchInput
              >
              <span matSuffix>g</span>
            </mat-form-field>
          </div>
      <div class="value-percent">
        {{100 * (toolkit.precision(this.batch.getTotalQuantityInKg() * 1000, 2) - slider.value) / (this.batch.getTotalQuantityInKg()
        * 1000) | number:'1.2-2'}}%
      </div>
    </div>
  </div>

  <div id="actions">
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button color="primary" (click)="submit()">
        Split
      </button>
  </div>
</div>