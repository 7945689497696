<div id="klayout-right-top" class="klayout-top">
  <!-- Actions -->
  <button mat-button class="top-nav-button" [disabled]="userForm.invalid || _isLoading" (click)="resetPassword()">
    Reset Password
  </button>
  <button mat-button class="top-nav-button" [disabled]="userForm.invalid || _isLoading" (click)="disableUser()" *ngIf="user && user.id && user.is_active">
    <i class="material-icons">
      block
    </i> Disable
  </button>

  <button mat-button class="top-nav-button" [disabled]="userForm.invalid || _isLoading" (click)="enableUser()" *ngIf="user && user.id && !user.is_active">
    <i class="material-icons">
      how_to_reg
    </i> Enable
  </button>

  <button mat-button class="top-nav-button" [disabled]="userForm.invalid || _isLoading" (click)="deleteUser()" *ngIf="user && user.id && user.is_active">
    <i class="material-icons">
      delete
    </i> Delete
  </button>



  <button mat-button class="top-nav-button" color="primary" [disabled]="userForm.invalid || _isLoading" (click)="saveUser()">
    <i class="material-icons">
      save
    </i> Save
  </button>
</div>
<div id="klayout-right-bottom">
  <!-- Contents -->
  <div id="container" *ngIf="user">
    <ng-container *ngIf="user.id">
      <div class="status-box status-nok" *ngIf="!user.is_active">Disabled</div>
      <div class="status-box status-ok" *ngIf="user.is_active">Enabled</div>
    </ng-container>


    <div class="section-title heading-h3">
      User Information
    </div>
    <form [formGroup]="userForm" id="basic-info-fields">
      <mat-form-field appearance="outline" class="job-field-margin job-field-medium" *ngIf="!user.id">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email">
      </mat-form-field>
      <ng-container *ngIf="user.id">
        {{user.email}}
      </ng-container>
      <div class="spacer-20"></div>
      <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <div class="spacer-20"></div>
      <mat-form-field appearance="outline" class="job-field-margin job-field-medium" *ngIf="bodyshops">
        <mat-label>Bodyshop</mat-label>
        <mat-select formControlName="bodyshop" #bodyshopSelect>
          <ng-container *ngFor="let bshop of bodyshops">
            <mat-option *ngIf="bshop.isEnabled()" [value]="bshop">{{bshop.id_custom}}: {{bshop.name}}</mat-option>
          </ng-container>

        </mat-select>
      </mat-form-field>
      <div class="spacer-20"></div>
      <mat-form-field appearance="outline" class="job-field-margin job-field-medium">
        <mat-label>Employee Nb.</mat-label>
        <input matInput formControlName="employee_nb">
      </mat-form-field>
    </form>

    <div class="spacer-20"></div>

    <ng-container *ngIf="user.is_active">
      <div class="section-title heading-h3">
        Permissions
      </div>

      <div id="permission-container">
        <div class="permission-column">
          <div class="permission-column-header">Bodyshop</div>
          <div class="permission-column-desc">
            Which sections of bodyshop does this user have access to?
          </div>

          <mat-checkbox color="primary" [value]="['bodyshop', 'job']" (change)="changePermission($event)" [checked]="user.isPermitted('bodyshop', 'job')">Jobs
            (create/edit)</mat-checkbox>
          <mat-checkbox color="primary" [value]="['bodyshop', 'colour']" (change)="changePermission($event)" [checked]="user.isPermitted('bodyshop', 'colour')">Colours
            (create)</mat-checkbox>
          <mat-checkbox color="primary" [value]="['bodyshop', 'variant']" (change)="changePermission($event)" [checked]="user.isPermitted('bodyshop', 'variant')">Variants
            (create)</mat-checkbox>
        </div>


        <div class="v-spacer"></div>
        <div class="permission-column">
          <div class="permission-column-header">Reporting</div>
          <div class="permission-column-desc">Select for which bodyshops this users can extract reports from.</div>

          <ng-container *ngFor="let bodyshop of bodyshops">
            <mat-checkbox color="primary" matTooltip="{{bodyshop.name}}" [value]="bodyshop" (change)="changeReportingBodyshop($event)"
              *ngIf="bodyshop.isEnabled()" [checked]="user.isAllowedReports(bodyshop.id)">{{bodyshop.id_custom}}</mat-checkbox>
          </ng-container>

        </div>
        <div class="v-spacer"></div>
        <div class="permission-column">
          <div class="permission-column-header">Account</div>
          <div class="permission-column-desc">Select which sections this user is to administer for all bodyshops and
            users.</div>
          <mat-checkbox color="primary" [value]="['tenant', 'tenant']" (change)="changePermission($event)" [checked]="user.isPermitted('tenant', 'tenant')">Manage
            Account</mat-checkbox>
          <mat-checkbox color="primary" [value]="['tenant', 'bodyshops']" (change)="changePermission($event)" [checked]="user.isPermitted('tenant', 'bodyshops')">Manage
            Bodyshops</mat-checkbox>
          <mat-checkbox color="primary" [value]="['tenant', 'users']" (change)="changePermission($event)" [checked]="user.isPermitted('tenant', 'users')">Manage
            Users</mat-checkbox>
          <mat-checkbox color="primary" [value]="['tenant', 'custom-products']" (change)="changePermission($event)"
            [checked]="user.isPermitted('tenant', 'custom-products')">Manage
            Custom Products</mat-checkbox>
        </div>
      </div>
    </ng-container>


    <div class="error-message nok" *ngIf="isError">
      An Error has occured while trying to save the user!
    </div>
  </div>
</div>