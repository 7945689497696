import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mixer-visual-bar',
  templateUrl: './mixer-visual-bar.component.html',
  styleUrls: ['./mixer-visual-bar.component.css']
})
export class MixerVisualBarComponent implements OnInit {
  @Input() target: number;
  @Input() value: number;
  @Input() error: number = 0.0;

  constructor() { }

  ngOnInit() {
  }

  //============= max ================
  getMax() {
    let max_value = 1.1 * (this.target + this.getAbsError());

    if (this.value > max_value) {
      max_value = this.value;
    }

    return max_value;
  }


  //============= OVER ================
  getHeightOver() {
    let percent = 100 * (this.getMax() - this.target - this.getAbsError()) / this.getMax();
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  getValueOver() {
    let percent = 100 * (this.value - (this.target + this.getAbsError())) / (this.getMax() - this.target - this.getAbsError());
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  //============= ERROR ================
  getHeightError() {
    let percent = 100 * this.getAbsError() / this.getMax();
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  getAbsError() {
    let err = this.target * (this.error / 100);
    return err ? err : 0.0;
  }

  //============= ERROR - TOP ================
  getValueErrorTop() {
    let percent = 100 * (this.value - this.target) / this.getAbsError();
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  //============= ERROR - BOTTOM ================
  getValueErrorBottom() {
    let percent = 100 * (this.value - (this.target - this.getAbsError())) / this.getAbsError();
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  //============= UNDER ================
  getHeightUnder() {
    let percent = 100 * (this.target - this.getAbsError()) / this.getMax();


    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }

  getValueUnder() {
    let percent = 100 * this.value / (this.target - this.getAbsError());
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0 ? 0 : percent;
    let style = percent + "%";
    return style;
  }





}
