import { ActivatedRoute, Router } from '@angular/router/';
import { DataService } from './../../../services/data.service';
import { DialogTwoOptionsComponent } from './../../dialogs/dialog-two-options/dialog-two-options.component';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ProductSku } from './../../../model/model';
import { ProductService } from './../../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { Product } from '../../../model/model';

@Component({
  selector: 'app-manage-custom-products-edit',
  templateUrl: './manage-custom-products-edit.component.html',
  styleUrls: ['./manage-custom-products-edit.component.css']
})
export class ManageCustomProductsEditComponent implements OnInit {
  public product: Product;
  public sku: ProductSku = new ProductSku();

  public dataSource_sku = new MatTableDataSource<ProductSku>();
  public displayedColumns_sku = ['pack_size', 'code', 'action'];

  public isInError = false;
  public errorMessage;

  constructor(public productService: ProductService,
    private dialog: MatDialog,
    public dataService: DataService,
    public route: ActivatedRoute,
    public router: Router) {

    this.route.params.subscribe(params => {
      if (params['productId']) {
        //wait in case of page refresh
        this.dataService.blockUIforLoad = true;
        this.productService.initialised.distinctUntilChanged().subscribe(globalSate => {
          if (globalSate) {

            //fetch latest data
            this.productService.refreshData();
            this.productService.initialised.distinctUntilChanged().subscribe(state => {
              if (state) {
                this.product = this.productService.getProductById(parseInt(params['productId']));

                this.updateSkuTable();
                this.dataService.blockUIforLoad = false;
              }
            });
          }
        });
      }
      else {
        this.product = new Product();
        this.product.is_kansai = false;
        this.updateSkuTable();
      }
    });


  }

  ngOnInit() {

  }

  updateSkuTable() {
    this.product.skus.sort((a: ProductSku, b: ProductSku) => {
      return a.pack_size - b.pack_size;
    });

    let tmp = [];

    for (let iSku of this.product.skus) {
      tmp.push(iSku);
    }

    this.sku['__editRow'] = true;

    tmp.push(this.sku)

    this.dataSource_sku = new MatTableDataSource(tmp);
  }

  isReadyForSave() {
    if (!this.product)
      return false;

    if (!this.product.category)
      return false;

    if (!this.product.unit)
      return false;

    if (!this.product.code || this.product.code.trim().length == 0)
      return false;

    if (!this.product.name || this.product.name.trim().length == 0)
      return false;

    if (!this.product.manufacturer || this.product.manufacturer.trim().length == 0)
      return false;

    return true;
  }

  save() {
    this.isInError = false;
    let text = "Do you want to save these changes?";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Save Product",
        'text': text,
        'buttonPrimary_text': "Yes",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this.dataService.blockUIforLoad = true;

      this.productService.saveProduct(this.product).subscribe(product => {
        console.log(product);

        this.productService.refreshData();
        this.dataService.blockUIforLoad = false;

        this.router.navigate(['..'], { relativeTo: this.route });
      },
        error => {
          console.log(error);
          this.isInError = true;
          this.dataService.blockUIforLoad = false;
        });
    });
  }

  delete() {
    this.isInError = false;
    let text = "Do you want to delete this product?";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Delete Product",
        'text': text,
        'buttonPrimary_text': "Yes, Delete",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this.dataService.blockUIforLoad = true;

      this.productService.deleteProduct(this.product).subscribe(() => {
        this.router.navigate(['..'], { relativeTo: this.route });
        this.dataService.blockUIforLoad = false;
      },
        error => {
          this.errorMessage = error.error.message;

          if (!this.errorMessage)
            this.errorMessage = "An Error has occurred.";

          this.isInError = true;

          console.log(error);
          this.dataService.blockUIforLoad = false;
        });
    });
  }


  addSku() {
    //avoid duplicate
    for (let existingSku of this.product.skus) {
      if (existingSku.pack_size == this.sku.pack_size) {
        return;
      }
    }

    delete this.sku['__editRow'];
    this.product.skus.push(this.sku);
    this.sku = new ProductSku();

    this.updateSkuTable();
  }

  deleteSku(sku: ProductSku) {
    this.product.skus.splice(this.product.skus.indexOf(sku), 1);
    this.updateSkuTable();
  }

  log(item?) {
    if (item)
      console.log(item)
    else
      console.log(this);
  }
}
