<app-mixer-visual-bar class="viz" [target]="target" [value]="value" [error]="error" *ngIf="type=='bar'">

</app-mixer-visual-bar>

<!--
                <div id="visualiser" *ngIf="selected_component">
                  <round-progress id="scale-spinner-normal" *ngIf="batch.getTargetForProduct(selected_component.product) >= batch.getComponentByProductId(selected_component.product.id).quantity_kg"
                    [current]="batch.getComponentByProductId(selected_component.product.id).quantity_kg" [max]="batch.getTargetForProduct(selected_component.product)"
                    responsive="true" color="#a626aa" stroke="25"></round-progress>
              
                  <round-progress id="scale-spinner-ok" *ngIf="batch.getTargetForProduct(selected_component.product) == batch.getComponentByProductId(selected_component.product.id).quantity_kg"
                    [current]="batch.getComponentByProductId(selected_component.product.id).quantity_kg" [max]="batch.getTargetForProduct(selected_component.product)"
                    responsive="true" color="#37b505" stroke="25"></round-progress>
              
                  <round-progress id="scale-spinner-over" *ngIf="batch.getTargetForProduct(selected_component.product) < batch.getComponentByProductId(selected_component.product.id).quantity_kg"
                    current="{{batch.getComponentByProductId(selected_component.product.id).quantity_kg - batch.getTargetForProduct(selected_component.product)}}"
                    [max]="batch.getTargetForProduct(selected_component.product)" responsive="true" background="#e58de8" color="#e01818"
                    stroke="25"></round-progress>
              
                  <div id="scale-hover-info-container">
                    <div class="spacer"></div>
                    <div id="scale-hover-info">
                      <mat-form-field id="input-quantity-field">
                        <input (keydown.enter)="updateConsumedQuantity(quantityInput.value, $event)" (keydown.arrowleft)="previousComponent()" (keydown.arrowright)="nextComponent()"
                          #quantityInput id="input-quantity" matInput value="{{(batch.getComponentByProductId(selected_component.product.id).quantity_kg * 1000).toFixed(2) | number:'1.2-2'}}"
                          [disabled]="batch.is_discarded">
                        <mat-icon (click)="updateConsumedQuantity(quantityInput.value)" matSuffix class="fa fa-arrow-right" aria-hidden="true"></mat-icon>
                      </mat-form-field>
                      <br> {{ toolkit.precision(batch.getTargetForProduct(selected_component.product) * 1000, 2) | number:'1.2-2' }}
                      <br>
                    </div>
                    <div class="spacer"></div>
                  </div>
                </div>
                -->