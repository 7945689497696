import { DialogBatchSplitComponent } from './../dialogs/dialog-batch-split/dialog-batch-split.component';
import { BatchService } from './../../services/batch.service';
import { VariantService } from './../../services/variant.service';
import { CoatService } from './../../services/coat.service';
import { DialogTwoOptionsComponent } from './../dialogs/dialog-two-options/dialog-two-options.component';
import { Toolkit } from './../../../toolkit/toolkit';
import { InventoryService } from './../../services/inventory.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ProductService } from './../../services/product.service';
import { FormulaComponent, ProductType, Product, Batch, BatchComponent, Variant, ApplicationTechnique, Substrate, ProductCategory, BatchOvershot } from './../../model/model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-mixer',
  templateUrl: './mixer.component.html',
  styleUrls: ['./mixer.component.css']
})
export class MixerComponent implements OnInit, OnDestroy {
  @Input() batch: Batch;
  @Input() baseOnly: boolean;
  @Input() isFormulaEditable: boolean = false;
  @Input() isSplittable: boolean = true;

  @Output() onUpdate = new EventEmitter<Batch>();
  @Output() onFormulaUpdate = new EventEmitter<FormulaComponent[]>();
  @Output() onCreate = new EventEmitter<Batch>();
  @Output() onDiscard = new EventEmitter<Batch>();
  @Output() onSplit = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<Batch>();

  @ViewChild('additionInput') additionInput: ElementRef;

  public toolkit: Toolkit = new Toolkit();
  public selected_product: Product;

  private _isChanged = false;
  private _isUploading = false;
  public _isCreating = false;

  public queuer;

  public formulaDataSource: MatTableDataSource<any>;
  public formulaTableColumns = ['product_code', 'product_name', 'amount_kg', 'actual', 'target_amount_kg']
  //public formulaTableColumns = ['product_code', 'product_code_short', 'product_name', 'amount_kg', 'actual', 'target_amount_kg']

  constructor(public productService: ProductService,
    public inventoryService: InventoryService,
    public coatService: CoatService,
    public variantService: VariantService,
    public batchService: BatchService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    if (!this.batch.components || (this.batch.components && this.batch.components.length == 0)) {
      this.batch.initComponents();
    }

    if (!this.batch.quantity_target_kg) {
      this.batch.quantity_target_kg = 0.1;
    }

    if (!this.selected_product && this.batch.components.length > 0) {
      this.selected_product = this.batch.components[0].product
    }

    this.updateTable();

    //get queue element from servcie
    this.queuer = this.batchService.getUploadQueuer();
  }

  ngOnDestroy() {
    //make sure the upload queue does not run forever
    if (this.queuer) {
      this.queuer['killSwitch'] = true;
    }
  }


  //////////////////////////////////////////////////////////////////////////////
  // View Methods
  //////////////////////////////////////////////////////////////////////////////
  isUploading() {
    return this.queuer && this.queuer['queue'] && this.queuer['queue'].length > 0
  }

  isSelectable(component: FormulaComponent) {

  }

  isEditable(component: FormulaComponent) {

  }


  //////////////////////////////////////////////////////////////////////////////
  // Controller
  //////////////////////////////////////////////////////////////////////////////
  setTargetAmount(amount_g: number) {
    this.batch.quantity_target_kg = Toolkit.precision(amount_g / 1000, 5);
    this.saveUpdates();
  }

  setQuantity(amount_g) {

    if (amount_g) {
      if (amount_g > 0) {
        let component = this.batch.getComponentByProductId(this.selected_product.id);
        let new_kg = component.tare + Toolkit.precision(amount_g / 1000, 5)

        if (component.quantity_kg != new_kg) {
          component.quantity_kg = new_kg;
          this.saveUpdates();
        }

        //tare all other components
        for (let cmp of this.batch.getAllComponents()) {
          if (cmp && cmp != component)
            cmp.tare = cmp.quantity_kg;
        }
      }
    }
  }

  selectComponent(row) {
    if (row && this.batch.isEditable()) {
      if ((this.batch.formula.indexOf(row) > -1 && !this.isAncillariesConsumed()) || this.batch.formula_ancillary.indexOf(row) > -1) {
        this.selected_product = row.product;
      }
    }
  }

  selectNext(event: KeyboardEvent) {
    let index = 0;

    for (let component of this.batch.getAllComponents()) {
      if (component && component.product && component.product == this.selected_product) {
        index = this.batch.getAllComponents().indexOf(component);
      }
    }

    console.log(event);

    if (event.shiftKey) {
      console.log("with shirt");
      if (index > 0 && this.batch.getAllComponents()[index - 1] && this.batch.getAllComponents()[index - 1].product) {
        this.selected_product = this.batch.getAllComponents()[index - 1].product;
      }
      else {

        //find last component with product set
        let cmp;
        for (let component of this.batch.getAllComponents()) {
          if (component && component.product) {
            cmp = component;
          }
          else {
            break;
          }
        }


        if (cmp) {
          this.selected_product = cmp.product
        }
      }
    }
    else {
      if (index < this.batch.getAllComponents().length - 1 && this.batch.getAllComponents()[index + 1] && this.batch.getAllComponents()[index + 1].product) {
        this.selected_product = this.batch.getAllComponents()[index + 1].product;
      }
      else {
        if (this.batch.getAllComponents()[0] && this.batch.getAllComponents()[0].product) {
          this.selected_product = this.batch.getAllComponents()[0].product
        }
      }
    }


    if (this.additionInput) {
      //this timeout is necessary, because the normal function of the tab button is triggered
      //after change detetion takes place, which in this case is after setting the focus.
      setTimeout(() => {
        this.additionInput.nativeElement.focus();
      }, 150)

    }
  }

  recalculate() {
    let minimum = this.batch.getMinimumAmountTotal();
    console.log(minimum);

    let newOvershot = new BatchOvershot();

    for (let component of this.batch.components) {
      if (component.quantity_kg >= this.batch.getTarget(component.product)) {
        newOvershot.product = component.product;
        newOvershot.target_kg = this.batch.getTarget(component.product);
        newOvershot.actual_kg = component.quantity_kg;
      }
    }

    console.log(newOvershot);

    this.batch.recalculations.push(newOvershot);

    this.batch.quantity_target_kg = this.batch.getMinimumAmountTotal();

    this.saveUpdates();
  }

  tare(additionInputRef) {
    additionInputRef.value = '';

    for (let component of this.batch.getAllComponents()) {
      component.tare = component.quantity_kg;
    }
  }

  getTareDiff(product: Product) {
    let component = this.batch.getComponentByProductId(product.id);

    if (component) {
      return component.quantity_kg - component.tare;
    }
  }

  getTareTargetDiff(product: Product) {
    let fComponent = this.batch.getFormulaComponentByProductId(product.id);
    let bComponent = this.batch.getComponentByProductId(product.id);

    return this.batch.getTarget(product) - bComponent.tare;
  }

  updateTable() {
    let rows = [];

    for (let cmp of this.batch.formula) {
      rows.push({
        'isAncillary': false,
        'component': cmp
      });
    }

    if (this.isFormulaEditable) {
      let cmp = new FormulaComponent();
      cmp.ratio = 0.0;
      cmp.amount_kg = 0.0;

      rows.push({
        'isAncillary': false,
        'component': cmp
      });
    }


    rows.push({
      'hide': true,
      'purpose': '__base-sum__',
      'component': {}
    });

    if (!this.baseOnly) {
      for (let cmp of this.batch.formula_ancillary) {
        let newRow = {
          'isAncillary': true,
          'component': cmp
        }

        if (!cmp.product) {
          newRow['placeholder'] = cmp.product_category.name;

          for (let item of this.getRequiredAncillaries()) {
            if (item['category'].id == cmp.product_category.id) {
              newRow['options'] = item['products'];

              break;
            }
          }
        }

        rows.push(newRow);
      }

    }


    //this.batch.variant.product_type.formula[this.batch.variant.system]['']

    this.formulaDataSource = new MatTableDataSource(rows);
  }

  containsProduct(product: Product) {
    for (let component of this.batch.formula) {
      if (component.product) {
        if (component.product.id == product.id) {
          return true;
        }
      }
    }

    return false;
  }

  addProduct(product: Product, component: FormulaComponent, isAncillary?: boolean) {
    if (product && product instanceof Product && this.inventoryService.getByProductId(product.id)) {
      component.product = product;
      component.amount_kg = 0.0;

      if (!isAncillary) {
        //this.recalculateRatio();
      }

      if (!this.batch.getComponentByProductId(product.id)) {
        let newComp = new BatchComponent();
        newComp.product = component.product;
        newComp.quantity_kg = 0.0;
        newComp.cost_per_kg = Toolkit.precision(this.inventoryService.getByProductId(product.id).unit_cost * this.productService.getProductById(product.id).kg_conversion, 4);

        if (isAncillary) {
          this.batch.components_ancillary.push(newComp);
        }
        else {
          this.batch.formula.push(component);
          this.onFormulaUpdate.emit(this.batch.formula);
          this.batch.components.push(newComp);
        }
      }

      this.updateTable();
    }


    this.saveUpdates();
  }

  isAncillariesConsumed() {
    for (let component of this.batch.components_ancillary) {
      if (component.quantity_kg > 0) {
        return true;
      }
    }

    return false;
  }

  deleteComponent(component: FormulaComponent) {
    if (this.selected_product && (this.selected_product.id == component.product.id)) {
      this.selected_product = undefined;
    }

    if (this.batch.formula.indexOf(component) > -1) {
      this.batch.formula.splice(this.batch.formula.indexOf(component), 1);
      this.batch.components.splice(this.batch.components.indexOf(this.batch.getComponentByProductId(component.product.id)), 1);

      //this.recalculateRatio();
    }
    else if (this.batch.formula_ancillary.indexOf(component) > -1) {
      this.batch.components_ancillary.splice(this.batch.components_ancillary.indexOf(this.batch.getComponentByProductId(component.product.id)), 1);

      component.product = undefined;
    }

    this.updateTable();
    this.saveUpdates();
  }

  submitQuantity() {
    for (let component of this.batch.formula) {
      component.amount_kg = Toolkit.precision(component['amount_g'] / 1000, 5);
    }

    this.recalculateRatio();
    this.saveUpdates();
  }

  consumeFromPreMix() {
    this.batch.is_fromPreMix = true;

    for (let fComponent of this.batch.formula) {
      this.batch.getComponentByProductId(fComponent.product.id).quantity_kg = fComponent.ratio * this.batch.quantity_target_kg;
    }

    this.updateTable();
    this.saveUpdates();
  }

  getSubstrateList() {
    let list: Substrate[];

    if (this.batch.coat.type == 'colour') {
      //for colours, get substrates from productType
      list = [];
      for (let sub in this.batch.product_type.formula[this.batch.system][this.batch.coat.id]) {
        list.push(this.variantService.getSubstrateOffline(sub));
      }
    }
    else if (this.batch.baseProduct) {
      //for others, get substrates from baseProduct
      list = [];
      for (let sub in this.batch.baseProduct.formula) {
        list.push(this.variantService.getSubstrateOffline(sub));
      }
    }

    if (list) {
      list.sort((a: Substrate, b: Substrate) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
    }

    return list;
  }

  getApplicationTechniqueList() {
    let list: ApplicationTechnique[] = undefined;

    if (this.batch.coat.type != 'colour') {
      if (this.batch.substrate && this.batch.baseProduct) {
        list = [];
        for (let appTech in this.batch.baseProduct.formula[this.batch.substrate.id]) {
          list.push(this.coatService.getApplicationTechniqueById(appTech));
        }

        list.sort((a: Substrate, b: Substrate) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
      }
    }

    return list;
  }

  changeApplicationTechnique(changeEvent) {
    if (!this.isAncillariesConsumed()) {
      this.batch.formula_ancillary = [];
      this.batch.components_ancillary = [];

      this.batch.applicationTechnique = changeEvent.value;

      for (let anc of this.getRequiredAncillaries()) {
        let newComp = new FormulaComponent();
        newComp.product_category = anc['category'];
        newComp.ratio = anc['ratio'];
        newComp.error = anc['error'];

        this.batch.formula_ancillary.push(newComp);

        if (anc['products'] && anc['products'].length == 1) {
          this.addProduct(anc['products'][0], newComp, true);
        }
      }
    }


    this.saveUpdates();
    this.updateTable();
  }

  changeSubstrate(substrate) {
    if (!this.isAncillariesConsumed()) {
      this.batch.formula_ancillary = [];
      this.batch.components_ancillary = [];

      this.batch.substrate = substrate;

      //set applicaiton technique
      let appTechList = this.getApplicationTechniqueList();
      if (appTechList && appTechList.length == 1) {
        this.batch.applicationTechnique = appTechList[0];
      }

      for (let anc of this.getRequiredAncillaries()) {
        let newComp = new FormulaComponent();
        newComp.product_category = anc['category'];
        newComp.ratio = anc['ratio'];
        newComp.error = anc['error'];

        this.batch.formula_ancillary.push(newComp);

        if (anc['products'] && anc['products'].length == 1) {
          this.addProduct(anc['products'][0], newComp, true);
        }
      }
    }


    this.saveUpdates();
    this.updateTable();
  }

  getRequiredAncillaries() {
    let aList = [];
    let ptFormula;

    if (this.batch.baseProduct) {
      if (this.batch.substrate && this.batch.applicationTechnique) {
        ptFormula = this.batch.baseProduct.formula[this.batch.substrate.id][this.batch.applicationTechnique.id];
      }
    }
    else {
      if (this.batch && this.batch.system && this.batch.coat && this.batch.substrate) {
        ptFormula = this.batch.product_type.formula[this.batch.system][this.batch.coat.id][this.batch.substrate.id];
      }
    }

    if (ptFormula) {
      aList = [];

      for (let component of ptFormula['components']) {
        aList.push(component);
      }
    }

    return aList;
  }

  getRequiredAncillaryCategories() {
    let aList: ProductCategory[] = [];
    if (this.batch && this.batch.system && this.batch.coat && this.batch.substrate) {
      let ptFormula = this.batch.product_type.formula[this.batch.system][this.batch.coat.id][this.batch.substrate.id];

      for (let component of ptFormula['components']) {
        let anc = component['category'];

        if (anc) {
          aList.push(anc);
        }
      }
    }

    aList.sort((a: ProductCategory, b: ProductCategory) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });

    return aList;
  }

  getOptionsForAncillary(category: ProductCategory) {
    if (this.batch && this.batch.system && this.batch.coat && this.batch.substrate) {
      let ptFormula = this.batch.product_type.formula[this.batch.system][this.batch.coat.id][this.batch.substrate.id];

      for (let component of ptFormula['components']) {
        if (component['category'].id == category.id) {
          return component['products'];
        }
      }
    }
  }



  swapForTraceTinter(component: FormulaComponent) {
    let text = "Product" + "<br>" +
      "<span class='bold'>" + component.product.id + "</span>" + ": " + component.product.name + "<br>" +
      "<br>" +
      "will be replaced with" + "<br>" +
      "<span class='bold'>" + "___TO-DO___" + "</span>" + ": " + "___TO-DO___";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Trace Tinter",
        'text': text,
        'buttonPrimary_text': "OK",
        'buttonSecondary_text': "Cancel"
      }
    });


    this.saveUpdates();
  }

  recalculateRatioForDilution(componentToChange: FormulaComponent, dilutingComponent: FormulaComponent, dilutionFactor: number) {
    componentToChange.ratio = componentToChange.ratio / (1 + dilutingComponent.ratio * (dilutionFactor - 1));
    componentToChange.amount_kg = componentToChange.ratio * this.batch.quantity_target_kg;
  }

  recalculateRatio() {
    for (let cmp of this.batch.formula) {
      cmp.ratio = Toolkit.precision(Toolkit.ratio(cmp, this.batch.formula), 4);
    }
  }

  split() {
    if (this.isSplittable) {
      let dialogRef = this.dialog.open(DialogBatchSplitComponent, {
        data: {
          batch: this.batch
        }
      });

      dialogRef.componentInstance.onSubmit.subscribe(split_kg => {
        if (split_kg) {
          this.onSplit.emit({
            'batch': this.batch,
            'split_kg': split_kg
          });
        }
      });
    }
  }

  discard() {
    let text = "Do you want to discard this batch and flag it as waste? This cannot be undone.";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Discard Batch",
        'text': text,
        'buttonPrimary_text': "Yes",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this._isUploading = true;
      this.batchService.deleteBatch(this.batch).subscribe(resBatch => {
        this.batch.is_discarded = resBatch.is_discarded;
        this.selected_product = undefined;
        this.onDiscard.emit(this.batch);
        this._isUploading = false;
      },
        error => {
          console.log(error);
          this._isUploading = false;
        });
    });
  }

  done() {
    let text = "Do you want to mark this batch as done and prevent changes?";

    let dialogRef = this.dialog.open(DialogTwoOptionsComponent, {
      data: {
        'title': "Finalise Batch",
        'text': text,
        'buttonPrimary_text': "Yes",
        'buttonSecondary_text': "No"
      }
    });

    dialogRef.componentInstance.onPrimary.subscribe(result => {
      this.onDone.emit(this.batch);
      this.batch.is_locked = true;
      this.selected_product = undefined;
      this.saveUpdates();
    });
  }

  saveUpdates() {
    if (this.batch.id) {
      this.batchService.updateBatch(this.batch, this.queuer);
    }
    else {
      this._isCreating = true;

      //set substrate if only one is available
      let substrateList = this.getSubstrateList();
      if (!this.batch.substrate && substrateList && substrateList.length == 1) {
        this.batch.substrate = this.getSubstrateList()[0];
      }

      //set applicaiton technique if only one is available
      //if app technique is not selected, set to default
      if (!this.batch.applicationTechnique) {
        this.batch.applicationTechnique = this.coatService.default_applicaitonTechnique
      }

      this.batchService.createBatch(this.batch).subscribe(resBatch => {
        if (resBatch) {
          this.batch.id = resBatch.id;
          this._isCreating = false;

          this.onCreate.emit(this.batch);
        }
      });
    }
  }


  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }
}
