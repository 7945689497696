import { FormulaComponent } from './../app/model/model';
export class Toolkit {
    public static precision(num: number, digits: number) {
        return Number((Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits));
    }

    public precision(num: number, digits: number) {
        return Toolkit.precision(num, digits);
    }

    public static ratio(component: FormulaComponent, formula: FormulaComponent[]) {
        let total = 0.0;

        for (let cmp of formula) {
            if(cmp.product && cmp.amount_kg)
                total += cmp.amount_kg;
        }

        if (total == 0)
            return 0.0;
        else{
            return component.amount_kg / total;
        }
            
    }

    public ratio(component: FormulaComponent, formula: FormulaComponent[]) {
        return Toolkit.ratio(component, formula);
    }

    public static getDateString(date:Date){
        let year = "" + date.getFullYear();
        let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

        return year + "-" + month + "-" + day;
    }


    public static getTimeseriesItemsDays(start:Date, end:Date):string[]{
        let range:string[] = [];

        range.push(Toolkit.getDateString(start));

        let iDate = new Date(start);
        while(iDate < end){
            iDate.setDate(iDate.getDate() + 1);
            range.push(Toolkit.getDateString(iDate));
        }

        return range;
    }
}