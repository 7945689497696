import { Router, ActivatedRoute } from '@angular/router/';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public invalidLogin = false;
  public _isAuthenticating = false;

  public _isForgotPassword = false;
  public _requestSent = false;

  public _password: string;
  public _email: string;

  constructor(public authService: AuthService,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this._isForgotPassword = params['loginAction'] ? true : false;
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  login() {
    if (!this._email || !this._password)
      return;

    if (this._email.trim().length == 0)
      return;

    if (this._password.trim().length == 0)
      return;

    this._isAuthenticating = true;

    if (this._email && this._password) {
      this.authService.login(this._email, this._password).subscribe(
        result => {
          this._isAuthenticating = false;
          this.invalidLogin = !result;
          //this.cacheService.refreshData();

          this._password = ""; //clear from memory
          this.route.params.subscribe(params => {
            if (params['redir']) {
              this.router.navigate([params['redir']]);
            }
            else {
              this.router.navigate(['/']);
            }
          });
        },
        error => {
          this._isAuthenticating = false;
          this.invalidLogin = true;
          this.authService.logout();
        }
      )
    }
  }

  resetPassword() {
    if (this._email && this._email.trim().length > 0) {
      this._isAuthenticating = true;
      this._requestSent = true;

      this.authService.resetPassword(this._email).subscribe(response => {
        this._isAuthenticating = false;
      },
        error => {
          console.log(error);
          this._isAuthenticating = false;
        });
    }
  }
}
