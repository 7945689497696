import { Router } from '@angular/router/';
import { Variant } from './../../model/model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formula-explorer',
  templateUrl: './formula-explorer.component.html',
  styleUrls: ['./formula-explorer.component.css']
})
export class FormulaExplorerComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  createJob(variant:Variant){
    this.router.navigate(['/job', { variantId: variant.id }]);
  }

  launchMixAssistant(variant:Variant){
    this.router.navigate(['/mix-standalone', { variantId: variant.id }]);
  }
}
