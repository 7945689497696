import { Job, Coat, CarPanel, ApplicationTechnique, JobPanel, Product, Substrate, ProductType } from './../../model/model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoatService } from '../../services/coat.service';

@Component({
  selector: 'app-panel-select-for-simple-coat',
  templateUrl: './panel-select-for-simple-coat.component.html',
  styleUrls: ['./panel-select-for-simple-coat.component.css']
})
export class PanelSelectForSimpleCoatComponent implements OnInit {
  @Input() job: Job;
  @Input() coat: Coat;
  @Input() product_type: ProductType;

  @Output() onCancel = new EventEmitter<any>();
  @Output() onSubmit = new EventEmitter<any>();

  public selectedPanels: JobPanel[] = [];
  public selection = {};


  public estimates;

  constructor(
    public coatService: CoatService
  ) {
  }

  ngOnInit() {
    this.estimates = this.job.car_model.estimates;
  }

  selectionChange(event, panel: JobPanel) {
    if (event.checked) {
      this.selectedPanels.push(panel);
    }
    else {
      this.selectedPanels.splice(this.selectedPanels.indexOf(panel), 1);
    }
  }

  getSubstrates() {
    let subs = {};
    let substrates = [];

    for (let panel of this.job.panels) {
      if (panel.isSelected()) {
        subs[panel.substrate.id] = panel.substrate;
      }
    }

    for (let subId in subs) {
      substrates.push(subs[subId]);
    }

    return substrates;
  }

  getAppTechs() {
    let appTechSet = new Set();
    let appTechs = [];

    for (let panel of this.job.panels) {
      if (panel.isSelected()) {
        for (let surface of panel.surfaces) {
          if (surface.repair) { //if selected
            let surface_id = surface.is_inside ? 'inside' : 'outside';

            if (this.estimates[panel.panel.id][surface_id][surface.repair.id] && this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id]) {
              for (let appTechId in this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id][this.product_type.id]) {
                appTechSet.add(appTechId);
              }
            }
          }
        }
      }
    }

    for (let appTechId of Array.from(appTechSet)) {
      appTechs.push(this.coatService.getApplicationTechniqueById(appTechId));
    }

    appTechs.sort((a: ApplicationTechnique, b: ApplicationTechnique) => {
      if (a.id > b.id)
        return -1;

      if (a.id < b.id)
        return -1;

      return 0;
    });

    return appTechs;
  }

  getTargetForPanel(panel: JobPanel, substrate: Substrate, appTech: ApplicationTechnique) {
    let target = 0.0;

    if (panel.substrate != substrate)
      return;

    for (let surface of panel.surfaces) {
      if (surface.repair) { //if selected
        let surface_id = surface.is_inside ? 'inside' : 'outside';


        if (!this.estimates[panel.panel.id][surface_id][surface.repair.id])
          return;

        if (this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id]) {
          if (this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id][this.product_type.id][appTech.id]) {
            target += this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id][this.product_type.id][appTech.id];
          }
        }
      }
    }


    return target;
  }

  getTargetsForPanel(panel: JobPanel, appTech: ApplicationTechnique) {
    let targets = {};

    for (let surface of panel.surfaces) {
      if (surface.repair) { //if selected
        let surface_id = surface.is_inside ? 'inside' : 'outside';

        if (this.estimates[panel.panel.id][surface_id][surface.repair.id]) {
          if (this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id]) {
            if (this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id][this.product_type.id][appTech.id]) {
              targets[surface_id] = this.estimates[panel.panel.id][surface_id][surface.repair.id][this.coat.id][this.product_type.id][appTech.id];
            }
          }
        }
      }
    }


    return targets;
  }

  clickChip(event, panel: JobPanel, substrate: Substrate, appTech: ApplicationTechnique) {
    let key = panel.panel.id + "." + substrate.id;

    if (this.selection[key] && this.selection[key]['item'] == event.srcElement) {
      delete this.selection[key];
    }
    else {
      this.selection[key] = {
        'panel': panel,
        'substrate': substrate,
        'appTech': appTech,
        'value': this.getTargetForPanel(panel, substrate, appTech),
        'targets': this.getTargetsForPanel(panel, appTech),
        'item': event.srcElement
      };
    }
  }

  isSelected(chip, panel: JobPanel, substrate: Substrate) {

    let key = panel.panel.id + "." + substrate.id;

    if (this.selection[key]) {
      return this.selection[key]['item'] == chip;
    }
    else {
      return false;
    }

  }


  getTotal() {
    let total = 0.0;

    for (let key in this.selection) {
      total += this.selection[key]['value'];
    }

    return total;
  }

  cancel() {
    this.onCancel.emit();
  }

  submit() {
    this.onSubmit.emit({
      'selection': this.selection,
      'total_kg': this.getTotal()
    });
  }
}
