import { ProductService } from './../../../services/product.service';
import { Batch } from './../../../model/model';
import { JobService } from './../../../services/job.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-job-summary',
  templateUrl: './job-summary.component.html',
  styleUrls: ['./job-summary.component.css']
})
export class JobSummaryComponent implements OnInit {
  public data = [];
  public chartData_cost: any[];
  public chartData_consumption: any[];

  public dataSource_cost = new MatTableDataSource<any>();
  public dataSource_consumption = new MatTableDataSource<any>();

  public _selectedData;

  constructor(public jobService: JobService,
    public userService: UserService,
    public productService: ProductService) { }

  ngOnInit() {
    this.updateData();
    this.getChartData(this.data[0]);
  }

  job() {
    return this.jobService.currentJob;
  }

  updateData() {
    //job
    this.data.push({
      'heading': 'Job',
      'subheading': '-Total-',
      'cost': this.job().getTotalCost(),
      'consumption': this.job().getTotalConsumption() * 1000,
      'batches': this.job().batches
    });

    //primer
    let primer_item = {
      'heading': 'Primer',
      'cost': 0.0,
      'consumption': 0.0,
      'batches': []
    };
    this.data.push(primer_item);

    for (let batch of this.job().batches) {
      if (batch.coat.id == 'primer') {
        primer_item['cost'] += batch.getTotalCost();
        primer_item['consumption'] += batch.getTotalQuantityInKg() * 1000;
        primer_item['batches'].push(batch);
      }
    }

    //colours
    for (let cmatch of this.job().colours) {
      let col_item = {
        'heading': cmatch.colour.code,
        'subheading': '-Total-',
        'cost': 0.0,
        'consumption': 0.0,
        'batches': []
      };
      this.data.push(col_item);

      for (let batch of this.job().batches) {
        if (batch.colourMatch_id == cmatch.id) {
          col_item['cost'] += batch.getTotalCost();
          col_item['consumption'] += batch.getTotalQuantityInKg() * 1000;
          col_item['batches'].push(batch);
        }
      }

      //coats
      if (cmatch.variant && cmatch.variant.coats && cmatch.variant.coats.length > 1) {
        for (let coat of cmatch.variant.coats) {
          let coat_item = {
            'heading': cmatch.colour.code,
            'subheading': coat.coat.name,
            'cost': 0.0,
            'consumption': 0.0,
            'batches': []
          };
          this.data.push(coat_item);

          for (let batch of this.job().batches) {
            if (batch.colourMatch_id == cmatch.id && batch.coat.id == coat.coat.id) {
              coat_item['cost'] += batch.getTotalCost();
              coat_item['consumption'] += batch.getTotalQuantityInKg() * 1000;
              coat_item['batches'].push(batch);
            }
          }
        }
      }
    }


    //clear
    let clear_item = {
      'heading': 'Clear',
      'cost': 0.0,
      'consumption': 0.0,
      'batches': []
    };
    this.data.push(clear_item);

    for (let batch of this.job().batches) {
      if (batch.coat.id == 'clear') {
        clear_item['cost'] += batch.getTotalCost();
        clear_item['consumption'] += batch.getTotalQuantityInKg() * 1000;
        clear_item['batches'].push(batch);
      }
    }

    //waste
    let waste_item = {
      'heading': 'Waste',
      'cost': 0.0,
      'consumption': 0.0,
      'batches': []
    };
    this.data.push(waste_item);

    for (let batch of this.job().batches) {
      if (!batch.is_matchingBatch && batch.is_discarded) {
        waste_item['cost'] += batch.getTotalCost();
        waste_item['consumption'] += batch.getTotalQuantityInKg() * 1000;
        waste_item['batches'].push(batch);
      }
    }

  }

  getChartData(item) {
    this._selectedData = item;

    this.chartData_consumption = [];
    this.chartData_cost = [];
    let tmpData_cost = {};
    let tmpData_quantity = {};
    let batches: Batch[] = item['batches'];

    for (let batch of batches) {
      for (let component of batch.getAllComponents()) {
        if (component.quantity_kg == 0)
          continue;

        if (!tmpData_cost[component.product.id])
          tmpData_cost[component.product.id] = 0.0

        if (!tmpData_quantity[component.product.id])
          tmpData_quantity[component.product.id] = 0.0

        tmpData_cost[component.product.id] += (component.cost_per_kg * component.quantity_kg);
        tmpData_quantity[component.product.id] += (component.quantity_kg * 1000);
      }
    }

    //format data
    for (let key in tmpData_cost) {
      this.chartData_cost.push({
        'name': key + ": " + this.productService.getProductById(key).name,
        'value': tmpData_cost[key],
        '_product': this.productService.getProductById(key)
      })
    }

    for (let key in tmpData_quantity) {
      this.chartData_consumption.push({
        'name': key + ": " + this.productService.getProductById(key).name,
        'value': tmpData_quantity[key],
        '_product': this.productService.getProductById(key)
      })
    }

    //sort
    this.chartData_cost.sort((a, b) => {
      return b['value'] - a['value'];
    });

    this.chartData_consumption.sort((a, b) => {
      return b['value'] - a['value'];
    });


    this.dataSource_cost = new MatTableDataSource(this.chartData_cost);
    this.dataSource_consumption = new MatTableDataSource(this.chartData_consumption);
  }
}
