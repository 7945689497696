<div id="container">
  <div id="over" class="full-width" [ngStyle]="{'height': getHeightOver()}">
    <div class="column-width">
      <div id="over-value" [ngStyle]="{'height': getValueOver()}"></div>
    </div>
  </div>


  <div id="error-top" class="full-width" [ngStyle]="{'height': getHeightError()}" *ngIf="error">
    <div class="column-width">
      <div id="error-value-above" [ngStyle]="{'height': getValueErrorTop()}"></div>
    </div>
  </div>

  <div id="target-line"></div>

  <div id="error-bottom" class="full-width" [ngStyle]="{'height': getHeightError()}"  *ngIf="error">
    <div class="column-width">
      <div id="error-value-below" [ngStyle]="{'height': getValueErrorBottom()}" ></div>
    </div>
  </div>


  <div id="under" class="full-width" [ngStyle]="{'height': getHeightUnder()}">
    <div class="column-width">
      <div id="under-value" [ngStyle]="{'height': getValueUnder()}"></div>
    </div>
  </div>
</div>