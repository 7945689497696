import { ProductService } from './../../../services/product.service';
import { UserService } from './../../../services/user.service';
import { DataService } from './../../../services/data.service';
import { Bodyshop } from './../../../model/model';
import { Toolkit } from './../../../../toolkit/toolkit';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-consumption-by-product',
  templateUrl: './report-consumption-by-product.component.html',
  styleUrls: ['./report-consumption-by-product.component.css']
})
export class ReportConsumptionByProductComponent implements OnInit {
  public data = [];

  public date_to: Date = new Date();
  public date_from: Date = new Date();
  public bodyshops: Bodyshop[] = [];

  public _isLoading = false;

  constructor(public userService: UserService,
    public dataService: DataService,
    public productService: ProductService) {
    //default span t-30d 
    this.date_from.setDate(this.date_to.getDate() - 30);
    this.userService.initialised.subscribe(state => {
      if (state) {
        for (let bshop of this.userService.currentUser.reportingBodyshops) {
          this.bodyshops.push(bshop);
        }
      }
    })
  }

  ngOnInit() {
  }

  isReady() {
    if (!this.date_to)
      return false;

    if (!this.date_from)
      return false;

    if (this.bodyshops.length > 0)
      return true;
    else
      return false;
  }

  clickBodyshop(event) {
    if (event.checked) {
      if (!this.bodyshops.includes(event.source.value))
        this.bodyshops.push(event.source.value);
    }
    else {
      if (this.bodyshops.includes(event.source.value))
        this.bodyshops.splice(this.bodyshops.indexOf(event.source.value), 1);
    }
  }

  isBodyshopSelected(bshop: Bodyshop) {
    return this.bodyshops.includes(bshop);
  }

  refresh() {
    if (this.isReady()) {
      this._isLoading = true;
      //loading indicator
      this.dataService.blockUIforLoad = true;

      let url = this.dataService.getUrl() + "/report";
      let requestBody = {
        'report': 'consumption_by_product',
        'params': {
          'bodyshops': [],
          'start': Toolkit.getDateString(this.date_from),
          'end': Toolkit.getDateString(this.date_to)
        }
      };

      for (let bshop of this.bodyshops) {
        requestBody['params']['bodyshops'].push(bshop.id);
      }

      this.dataService.postRequest(url, requestBody).subscribe(response => {
        console.log(response);

        if (response && response['report'] && response['report']['data']) {
          let resData: any[] = response['report']['data'];
          this.data = [];

          for (let item of resData) {
            this.data.push({
              'product': this.productService.getProductById(item['product']),
              'cost': item['cost'],
              'kg': item['kg']
            });
          }
        }

        this._isLoading = false;
        //loading indicator
        this.dataService.blockUIforLoad = false;
      }, error => {
        console.log(error);
        this._isLoading = false;
        //loading indicator
        this.dataService.blockUIforLoad = false;
      });
    }
  }


  stringToDate(str) {
    return new Date(str);
  }
}
