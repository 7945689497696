import { UserService } from './../../services/user.service';
import { VariantService } from './../../services/variant.service';
import { ColourService } from './../../services/colour.service';
import { CarManufacturer, ProductType, Colour, Variant, Job, } from './../../model/model';
import { ProductService } from './../../services/product.service';
import { InventoryService } from './../../services/inventory.service';
import { VehicleService } from './../../services/vehicle.service';
import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChildren, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatStepper, MatTableDataSource, MatInput } from '@angular/material';
import { Router } from '@angular/router/';
import { MatPaginator, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-formula-search',
  templateUrl: './formula-search.component.html',
  styleUrls: ['./formula-search.component.css']
})
export class FormulaSearchComponent implements OnInit {
  public isRetrievingColours = false;
  public isRetrievingVariants = false;
  public isError = false;
  public error = null;

  @Input() primaryButtonLabel: string;
  @Input() secondaryButtonLabel: string;
  @Input() ignoreInventory: boolean;
  @Input() hideHeader: boolean;

  @Output() onPrimaryAction = new EventEmitter<Variant>();
  @Output() onSecondaryAction = new EventEmitter<Variant>();

  public selected_productType: ProductType;
  public selected_carManufacturer: CarManufacturer;
  public selected_colour: Colour;
  public selected_colourCode: string;
  public selected_colourName: string;
  public selected_variant: Variant;

  public colours: Colour[] = [];
  public colourDataSource: MatTableDataSource<Colour>;
  public colourTableColumns = ['colourCode', 'colourName'];

  public variants: Variant[] = [];
  public variantDataSource: MatTableDataSource<Variant>;
  public variantTableColumns = ['variant', 'coat'];



  @ViewChildren(MatPaginator) paginators;
  @ViewChildren(MatSort) sorts;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('cManFilter') cManFilter: MatInput;
  @ViewChild('colourFilter') colourFilter: MatInput;

  searchFormGroup;

  constructor(public dataService: DataService,
    public vehicleService: VehicleService,
    public variantService: VariantService,
    public colourService: ColourService,
    public inventoryService: InventoryService,
    public productService: ProductService,
    public userService: UserService,
    public router: Router,
    public formulaDetailDialog: MatDialog) { }

  ngOnInit() {
  }


  stepperSelectionChange() {
    this.selected_colourCode = undefined;
    this.selected_colourName = undefined;

    this.filterColour();
  }

  filterColour() {
    if (this.colourDataSource) {
      if (!this.selected_colourCode && !this.selected_colourName) {
        this.colourDataSource.filter = undefined;
      }
      else {
        this.colourDataSource.filter = "undefined";
      }
    }
  }

  getColours() {
    this.isRetrievingColours = true;
    this.isError = false;
    this.colourDataSource = new MatTableDataSource([]);

    this.colourService.getColours(this.selected_carManufacturer,
      this.selected_productType,
      this.selected_colourCode,
      this.selected_colourName).subscribe(colours => {
        if (colours) {
          this.colours = colours.sort((a: Colour, b: Colour) => {
            if (a.code > b.code) {
              return 1;
            }
            if (a.code < b.code) {
              return -1;
            }
            return 0;
          });;
          this.colourDataSource = new MatTableDataSource(this.colours);
          this.colourDataSource.paginator = this.paginators._results[0];

          this.colourDataSource.filterPredicate = (data: Colour, filter: string) => {
            let result = true;

            if (this.selected_colourCode) {
              result = result && data.code.toLowerCase().indexOf(this.selected_colourCode.trim().toLowerCase()) >= 0
            }
            if (this.selected_colourName) {
              result = result && data.name.toLowerCase().indexOf(this.selected_colourName.trim().toLowerCase()) >= 0
            }

            return result;
          };

          this.isRetrievingColours = false;
        }
      });
  }

  getVariants(row: Colour) {
    this.selected_colour = row;
    this.selected_variant = undefined;
    this.isRetrievingVariants = true;
    this.variantDataSource = new MatTableDataSource([]);

    this.variantService.getVariants(this.selected_colour, this.selected_productType).subscribe(variants => {
      if (variants) {
        this.variants = variants.sort((a: Variant, b: Variant) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        this.variantDataSource = new MatTableDataSource(this.variants);
        this.variantDataSource.paginator = this.paginators._results[0];
        this.variantDataSource.paginator.pageIndex = 0;
        this.variantDataSource.sort = this.sorts._results[0];

        this.variantDataSource.sortingDataAccessor = (data: Variant, sortHeaderId: String) => {
          switch (sortHeaderId) {
            case 'cost': return this.inventoryService.costForVariant(data, 0.1);
            case 'variant': return data.name;
          }
        };

        this.isRetrievingVariants = false;
      }
    });
  }

  selectProductType(product_type: ProductType) {
    this.selected_productType = product_type;
    this.cManFilter.value = '';
    this.colourFilter.value = '';
    this.selected_colour = undefined;
    this.stepper.next();
  }

  selectCarManufacturer(man: CarManufacturer) {
    this.selected_carManufacturer = man;
    this.colourFilter.value = '';
    this.selected_colour = undefined;
    this.getColours();
    this.stepper.next();
  }

  selectColour(colour: Colour) {
    this.selected_colour = colour;
    this.getVariants(colour);
    this.stepper.next();
  }

  selectVariant(variant: Variant) {

  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }

  openVariantDialog(variant: Variant) {
    let dialog = this.formulaDetailDialog.open(VariantDetailDiaglog, {
      width: '700px',
      data: { 
        variant: variant, 
        primaryButtonLabel: this.primaryButtonLabel, 
        //secondaryButtonLabel: this.secondaryButtonLabel,
        ignoreInventory: this.ignoreInventory,
        hideHeader: this.hideHeader
      }
    });

    dialog.componentInstance.onPrimaryAction.subscribe((evt_variant) => {
      this.onPrimaryAction.emit(evt_variant);
    });

    dialog.componentInstance.onSecondaryAction.subscribe((evt_variant) => {
      this.onSecondaryAction.emit(evt_variant);
    });

  }
}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<app-variant-detail ' +
    '[primaryButtonLabel]="primaryButtonLabel" ' +
    '[secondaryButtonLabel]="secondaryButtonLabel" ' +
    '[ignoreInventory]="ignoreInventory" ' +
    '[hideHeader]="hideHeader" ' +
    '[variant]="variant" ' +
    '(onPrimaryAction)="primaryAction($event)" ' +
    '(onSecondaryAction)="secondaryAction($event)" ' +
    '></app-variant-detail>',
  styleUrls: ['./formula-search.component.css']
})
export class VariantDetailDiaglog {
  @Output() onPrimaryAction = new EventEmitter<Variant>();
  @Output() onSecondaryAction = new EventEmitter<Variant>();

  public primaryButtonLabel: string;
  public secondaryButtonLabel: string;
  public ignoreInventory: boolean;
  public hideHeader: boolean;
  public variant: Variant;

  constructor(
    public dialogRef: MatDialogRef<VariantDetailDiaglog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.primaryButtonLabel = data['primaryButtonLabel'];
    this.secondaryButtonLabel = data['secondaryButtonLabel'];
    this.ignoreInventory = data['ignoreInventory'];
    this.hideHeader = data['hideHeader'];
    this.variant = data['variant'];
  }

  primaryAction(variant:Variant){
    this.onPrimaryAction.emit(variant);
    this.dialogRef.close();
  }

  secondaryAction(variant:Variant){
    this.onSecondaryAction.emit(variant);
    this.dialogRef.close();
  }

  log(item?) {
    if (!item) {
      item = this;
    }
    console.log(item);
  }
}