import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { JobService } from './../../../services/job.service';
import { MatTableDataSource } from '@angular/material';
import { Product, InventoryItem, JobCustomProduct } from './../../../model/model';
import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { InventoryService } from '../../../services/inventory.service';

@Component({
  selector: 'app-job-misc',
  templateUrl: './job-misc.component.html',
  styleUrls: ['./job-misc.component.css']
})
export class JobMiscComponent implements OnInit {
  public rows: any[];
  public dataSource: MatTableDataSource<any>;
  public inventoryTableColumns_custom = ['product_code', 'manufacturer', 'product_category', 'product_name', 'quantity', 'action'];


  myControl = new FormControl();
  filteredOptions: Observable<InventoryItem[]>;

  constructor(public inventoryService: InventoryService,
    public jobService: JobService) { }

  ngOnInit() {
    this.inventoryService.initialised.distinctUntilChanged().subscribe(state => {
      if (state) {
        this.setTableDataSource();
      }
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      )
      .map(data => data.sort((a: any, b: any) => {
        if (a.product.manufacturer && !b.product.manufacturer)
          return 1;

        if (!a.product.manufacturer && b.product.manufacturer)
          return -1;

        if (a.product.code < b.product.code)
          return -1;

        if (a.product.code > b.product.code)
          return 1;

        return 0;
      }));
  }

  private _filter(value: string): InventoryItem[] {
    const filterValue = value ? value.toLowerCase() : "";

    return this.inventoryService.inventory.filter(item => {
      return item.product.code.toLowerCase().includes(filterValue) || item.product.name.toLowerCase().includes(filterValue);
    });
  }

  setTableDataSource() {
    this.rows = [];

    //for all items NOT in inventory
    for (let jobProduct of this.jobService.currentJob.customProductConsumption) {
      let found = false;
      for (let row of this.rows) {
        if (row['jobProduct'] == jobProduct) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.rows.push({
          'jobProduct': jobProduct
        })
      }
    }
    this.rows.sort((a: any, b: any) => {
      if (a.jobProduct.manufacturer && !b.jobProduct.manufacturer)
        return 1;

      if (!a.jobProduct.manufacturer && b.jobProduct.manufacturer)
        return -1;

      if (a.jobProduct.product.code < b.jobProduct.product.code)
        return -1;

      if (a.jobProduct.product.code > b.jobProduct.product.code)
        return 1;

      return 0;
    });

    this.dataSource = new MatTableDataSource(this.rows);
  }

  addItem(item: InventoryItem) {
    if (!this.jobService.currentJob.getCustomProduct(item.product.id)) {
      let product = new JobCustomProduct();
      product.product = item.product;
      this.jobService.currentJob.customProductConsumption.push(product);
      this.setTableDataSource();
    }
  }

  deleteItem(product:JobCustomProduct){
    let index = this.jobService.currentJob.customProductConsumption.indexOf(product);

    if(index >= 0)
      this.jobService.currentJob.customProductConsumption.splice(index, 1);

    this.setTableDataSource();
  }

}
