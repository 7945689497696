<div id="container">
  <mat-table #formulaTable [dataSource]="formulaDataSource" id="formula-table">
    <!-- Column -->
    <ng-container matColumnDef="product_code">
      <mat-header-cell class="formula-table-cell-product-code" *matHeaderCellDef>
        Product Code
      </mat-header-cell>
      <mat-cell class="formula-table-cell-product-code" *matCellDef="let element" [class.not-available]="!inventoryService.getByProductId(element.product.id)">
        <mat-chip-list>
          <mat-chip>{{element.product.code}}</mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="product_code_short">
      <mat-header-cell class="formula-table-cell-product-code-short" *matHeaderCellDef>
        Product Code (Short)
      </mat-header-cell>
      <mat-cell class="formula-table-cell-product-code-short" *matCellDef="let element" [class.not-available]="!inventoryService.getByProductId(element.product.id)">
        {{element.product.code_short}}
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="product_name">
      <mat-header-cell class="formula-table-cell-product-name" *matHeaderCellDef>
        Product Name
      </mat-header-cell>
      <mat-cell class="formula-table-cell-product-name" *matCellDef="let element" [class.not-available]="!inventoryService.getByProductId(element.product.id)">
        {{element.product.name}}
      </mat-cell>
    </ng-container>

    <!-- Column -->
    <ng-container matColumnDef="ratio">
      <mat-header-cell class="formula-table-cell-amount" *matHeaderCellDef>
        Ratio (%)
      </mat-header-cell>
      <mat-cell class="formula-table-cell-amount monospace-number" *matCellDef="let element">
        {{element.ratio * 100 | number:'1.2-2' }}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="formulaTableColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: formulaTableColumns;"></mat-row>
  </mat-table>
</div>