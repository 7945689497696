<div id="container">
  <div class="vertical-spacer-top">
    <div id="logo-k"></div>
  </div>
  <ng-container *ngIf="!_isForgotPassword">
    <div class="form">
      <div class="title">
        Login
      </div>
      <div class="text">
        Enter your email address and password. <a [routerLink]="['/login', 'forgot-password']" class="forgot-password">Forgot
          Password?</a>
        <div *ngIf="invalidLogin" class="nok">
          Invalid username and/or password!
        </div>
      </div>
      <div class="field-container">
        <mat-form-field class="high-opacity login-item field-email" appearance="fill">
          <mat-label>Email Address</mat-label>
          <input matInput type="email" #email [(ngModel)]="_email" (keydown.enter)="login()">
        </mat-form-field>
        <mat-form-field class="high-opacity login-item field-password" appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" #password [(ngModel)]="_password" (keydown.enter)="login()">
          <button mat-button matSuffix mat-icon-button *ngIf="password.type=='password'" (click)="password.type='text'">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-button matSuffix mat-icon-button *ngIf="password.type=='text'" (click)="password.type='password'">
            <mat-icon>visibility_off</mat-icon>
          </button>
        </mat-form-field>

        <button mat-raised-button class="submit-button" [class.disabled-button]="submitButton.disabled" color="primary"
          (click)="login()" #submitButton [disabled]="!email.value || !password.value || _isAuthenticating">
          <ng-container *ngIf="_isAuthenticating">
            <mat-spinner diameter="20" class="spinner"></mat-spinner>
          </ng-container>
          <ng-container *ngIf="!_isAuthenticating"><i class="material-icons">
              arrow_forward
            </i></ng-container>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="_isForgotPassword">
    <div class="form">
      <div class="title">
        Forgot Password
      </div>
      <div class="text" *ngIf="!_requestSent">
        Enter the email address for which you want to reset your password. 
        <a [routerLink]="['/login']" class="forgot-password">Back to Login</a>
      </div>
      <div class="field-container" *ngIf="!_requestSent">
        <mat-form-field class="high-opacity login-item field-email" appearance="fill">
          <mat-label>Email Address</mat-label>
          <input matInput type="email" #resetEmail [(ngModel)]="_email" (keydown.enter)="login()">
        </mat-form-field>

        <button mat-raised-button class="submit-button" [class.disabled-button]="submitButton.disabled" color="primary"
          (click)="resetPassword()" #submitButton [disabled]="!resetEmail.value || _isAuthenticating">
          <ng-container *ngIf="_isAuthenticating">
            <mat-spinner diameter="20" class="spinner"></mat-spinner>
          </ng-container>
          <ng-container *ngIf="!_isAuthenticating"><i class="material-icons">
              arrow_forward
            </i></ng-container>
        </button>
      </div>



      <ng-container *ngIf="_requestSent">
        Instructions on how to set a new password have been sent to your email address. <br>
        If you have not received it, check your SPAM folder before trying again.
        <br>
        <a [routerLink]="['/login']" class="forgot-password">Back to Login</a>
      </ng-container>
    </div>
  </ng-container>


  <div class="vertical-spacer"></div>
</div>