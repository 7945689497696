<div id="container">
  <div class="heading-h2">Reports</div>

  <div id="sections">
    <div class="section">
      <div class="heading-h3">Cost & Consumption</div>

      <div class="report-container">
        <!--
        <div class="report">
          <div class="report-name">
            <a routerLink="product-consumption">Consumption by Product - Timeseries</a>
          </div>
          <div class="report-desc">
            Breakdown of consumption and cost for a selection of bodyshops over a selected period of time by day/week/month/year.
          </div>
        </div>-->
        <div class="report">
          <div class="report-name">
            <a routerLink="report-consumption-by-product">Cost & Consumption by Product</a>
          </div>
          <div class="report-desc">
            Breakdown of consumption and cost for each product over a selected period of time.
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="heading-h3">Jobs</div>

      <div class="report-container">

        <div class="report">
          <div class="report-name">
            <a routerLink="job-kpi">Job KPIs</a>
          </div>
          <div class="report-desc">
            High-Level KPIs for each job.
          </div>
        </div>
        
      </div>
    </div>
    <!--
    <div class="section">
        <div class="heading-h3">People</div>
  
        <div class="report-container">
  
          <div class="report">
            <div class="report-name">
              <a routerLink="consumption-by-product">__INSERT HERE___</a>
            </div>
            <div class="report-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua
            </div>
          </div>
  
          <div class="report">
            <div class="report-name">
              <a routerLink="consumption-by-product">__INSERT HERE___</a>
            </div>
            <div class="report-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua
            </div>
          </div>
        </div>
      </div>
      -->
  </div>
</div>