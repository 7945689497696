import { PasswordSetService } from './services/password-set.service';
import { PasswordResetComponent } from './components/registration/password-reset/password-reset.component';
import { RegistrationHomeComponent } from './components/registration/registration-home/registration-home.component';
import { ManageUsersEditComponent } from './components/manage/manage-users-edit/manage-users-edit.component';
import { ManageBodyshopsEditComponent } from './components/manage/manage-bodyshops-edit/manage-bodyshops-edit.component';
import { PaintshopComponent } from './components/paintshop/paintshop.component';
import { ManageUserService } from './services/manage-user.service';
import { FormulaTunerComponent } from './components/formula-tuner/formula-tuner.component';
import { ErrorService } from './services/error.service';
import { CountryService } from './services/country.service';
import { PanelService } from './services/panel.service';
import { BatchService } from './services/batch.service';
import { JobService } from './services/job.service';
import { VariantService } from './services/variant.service';
import { CoatService } from './services/coat.service';
import { ColourService } from './services/colour.service';
import { CacheService } from './services/cache.service';
import { VehicleService } from './services/vehicle.service';
import { AuthGuard } from './services/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { routing } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AppComponent } from './components/app/app.component';
import { LoginComponent } from './components/login/login.component';
import { FormulaSearchComponent, VariantDetailDiaglog } from './components/formula-search/formula-search.component';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { UserService } from './services/user.service';
import { HttpClientModule } from '@angular/common/http'
import { GlobalErrorHandler } from './global-error-handler';
import { AuthTokenInterceptor } from './services/auth-token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { InventoryComponent } from './components/inventory/inventory.component';
import { JobComponent } from './components/job/job.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductService } from './services/product.service';
import { InventoryService } from './services/inventory.service';
import { FormulaDetailComponent } from './components/formula-detail/formula-detail.component';
import { VariantDetailComponent } from './components/variant-detail/variant-detail.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { CommonModule } from '@angular/common';
import { LegalComponent } from './components/legal/legal.component';
import { HomeComponent } from './components/home/home.component';
import { PanelOptionsComponent } from './components/panel-options/panel-options.component';
import { JobVariantSelectionComponent } from './components/job-variant-selection/job-variant-selection.component';
import { FormulaExplorerComponent } from './components/formula-explorer/formula-explorer.component';
import { DialogTwoOptionsComponent } from './components/dialogs/dialog-two-options/dialog-two-options.component';
import { NewColourComponent } from './components/new-colour/new-colour.component';
import { NewVariantComponent } from './components/new-variant/new-variant.component';
import {MatBadgeModule} from '@angular/material/badge';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TestComponent } from './components/test/test.component';
import { DialogNewColourComponent } from './components/dialogs/dialog-new-colour/dialog-new-colour.component';
import { DialogNewVariantComponent } from './components/dialogs/dialog-new-variant/dialog-new-variant.component';
import { PanelSelectorComponent } from './components/panel-selector/panel-selector.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MAT_LABEL_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { KansaiLayoutComponent } from './components/layout/kansai-layout/kansai-layout.component';
import { Test2Component } from './components/test2/test2.component';
import { KansaiLayoutItemComponent } from './components/layout/kansai-layout-item/kansai-layout-item.component';
import { KansaiLayoutItemNavigationComponent } from './components/layout/kansai-layout-item-navigation/kansai-layout-item-navigation.component';
import { KansaiLayoutItemActionsComponent } from './components/layout/kansai-layout-item-actions/kansai-layout-item-actions.component';
import { KansaiLayoutItemPageComponent } from './components/layout/kansai-layout-item-page/kansai-layout-item-page.component';
import { FormulaEditorComponent } from './components/formula-editor/formula-editor.component';
import { MixerComponent } from './components/mixer/mixer.component';
import { MixerVisualComponent } from './components/mixer-visual/mixer-visual.component';
import { Test3Component } from './components/test3/test3.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { MixerVisualBarComponent } from './components/mixer-visual-bar/mixer-visual-bar.component';
import { MixerVisualCircleComponent } from './components/mixer-visual-circle/mixer-visual-circle.component';
import { DialogAssignBatchComponent } from './components/dialogs/dialog-assign-batch/dialog-assign-batch.component';
import { ManageComponent } from './components/manage/manage/manage.component';
import { DialogBatchSplitComponent } from './components/dialogs/dialog-batch-split/dialog-batch-split.component';
import { MatSliderModule } from '@angular/material/slider';
import { ErrorNotifierComponent } from './components/dialogs/error-notifier/error-notifier.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { JobDetailsComponent } from './components/job-page/job-details/job-details.component';
import { JobInfoComponent } from './components/job-page/job-info/job-info.component';
import { JobPrimerComponent } from './components/job-page/job-primer/job-primer.component';
import { JobClearComponent } from './components/job-page/job-clear/job-clear.component';
import { JobColourComponent } from './components/job-page/job-colour/job-colour.component';
import { JobMiscComponent } from './components/job-page/job-misc/job-misc.component';
import { SimpleCoatMixComponent } from './components/simple-coat-mix/simple-coat-mix.component';
import { JobElsComponent } from './components/job-page/job-els/job-els.component';
import { JobColourMixComponent } from './components/job-page/job-colour-mix/job-colour-mix.component';
import { JobColourMatchComponent } from './components/job-page/job-colour-match/job-colour-match.component';
import { JobSummaryComponent } from './components/job-page/job-summary/job-summary.component';
import { ManageTenantComponent } from './components/manage/manage-tenant/manage-tenant.component';
import { ManageUsersComponent } from './components/manage/manage-users/manage-users.component';
import { ManageBodyshopsComponent } from './components/manage/manage-bodyshops/manage-bodyshops.component';
import { PanelSelectForSimpleCoatComponent } from './components/panel-select-for-simple-coat/panel-select-for-simple-coat.component';
import { DialogPanelSelectForSimpleCoatComponent } from './components/dialogs/dialog-panel-select-for-simple-coat/dialog-panel-select-for-simple-coat.component';
import { JobsListComponent } from './components/jobs-list/jobs-list.component';
import { UserComponent } from './components/user/user.component';
import { AdminProductComponent } from './components/admin/admin-product/admin-product.component';
import { AdminProductTypeComponent } from './components/admin/admin-product-type/admin-product-type.component';
import { AdminComponent } from './components/admin/admin/admin.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportProductConsumptionComponent } from './components/report/report-product-consumption/report-product-consumption.component';
import { BatchCardComponent } from './components/batch-card/batch-card.component';
import { ReportJobKpiComponent } from './components/report/report-job-kpi/report-job-kpi.component';
import { ReportConsumptionByProductComponent } from './components/report/report-consumption-by-product/report-consumption-by-product.component';
import { ManageCustomProductsComponent } from './components/manage/manage-custom-products/manage-custom-products.component';
import { ManageCustomProductsEditComponent } from './components/manage/manage-custom-products-edit/manage-custom-products-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormulaSearchComponent,
    InventoryComponent,
    JobComponent,
    FormulaDetailComponent,
    VariantDetailDiaglog,
    VariantDetailComponent,
    JobsComponent,
    LegalComponent,
    HomeComponent,
    PanelOptionsComponent,
    JobVariantSelectionComponent,
    FormulaExplorerComponent,
    DialogTwoOptionsComponent,
    NewColourComponent,
    NewVariantComponent,
    TestComponent,
    DialogNewColourComponent,
    DialogNewVariantComponent,
    PanelSelectorComponent,
    KansaiLayoutComponent,
    Test2Component,
    KansaiLayoutItemComponent,
    KansaiLayoutItemNavigationComponent,
    KansaiLayoutItemActionsComponent,
    KansaiLayoutItemPageComponent,
    FormulaEditorComponent,
    FormulaTunerComponent,
    MixerComponent,
    MixerVisualComponent,
    Test3Component,
    ProductDetailComponent,
    MixerVisualBarComponent,
    MixerVisualCircleComponent,
    DialogAssignBatchComponent,
    ManageComponent,
    DialogBatchSplitComponent,
    ErrorNotifierComponent,
    JobDetailsComponent,
    JobInfoComponent,
    JobPrimerComponent,
    JobClearComponent,
    JobColourComponent,
    JobMiscComponent,
    SimpleCoatMixComponent,
    JobElsComponent,
    JobColourMixComponent,
    JobColourMatchComponent,
    JobSummaryComponent,
    ManageTenantComponent,
    ManageUsersComponent,
    ManageBodyshopsComponent,
    PaintshopComponent,
    ManageBodyshopsEditComponent,
    ManageUsersEditComponent,
    RegistrationHomeComponent,
    PasswordResetComponent,
    PanelSelectForSimpleCoatComponent,
    DialogPanelSelectForSimpleCoatComponent,
    JobsListComponent,
    UserComponent,
    AdminProductComponent,
    AdminProductTypeComponent,
    AdminComponent,
    ReportsComponent,
    ReportProductConsumptionComponent,
    BatchCardComponent,
    ReportJobKpiComponent,
    ReportConsumptionByProductComponent,
    ManageCustomProductsComponent,
    ManageCustomProductsEditComponent
  ],
  entryComponents: [
    VariantDetailDiaglog,
    PanelOptionsComponent,
    JobVariantSelectionComponent,
    DialogTwoOptionsComponent,
    DialogNewColourComponent,
    DialogBatchSplitComponent,
    ErrorNotifierComponent,
    DialogPanelSelectForSimpleCoatComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    MatListModule,
    MatSidenavModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCardModule,
    MatMenuModule,
    MatStepperModule,
    MatGridListModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatDividerModule,
    MatDialogModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxChartsModule,
    LayoutModule,
    routing
  ],
  providers: [
    AuthService,
    AuthGuard,
    MatDatepickerModule,
    DataService,
    UserService,
    VehicleService,
    ProductService,
    CacheService,
    ColourService,
    VariantService,
    InventoryService,
    JobService,
    BatchService,
    CoatService,
    PanelService,
    CountryService,
    ErrorService,
    ManageUserService,
    PasswordSetService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
