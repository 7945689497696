<div id="container" *ngIf="job && coat">
  <div id="title" class="heading-h3">
    Base Product Requirements
  </div>

  <table>
    <tr [class.tr-header]="getAppTechs().length == 1">
      <th class="td-header"></th>
      <th *ngFor="let substrate of getSubstrates()" [attr.colspan]="getAppTechs().length" class="td-values th td-header">{{substrate.name}}</th>
    </tr>
    <tr *ngIf="getAppTechs().length > 1" class="tr-header">
      <th class="td-header"></th>
      <ng-container *ngFor="let substrate of getSubstrates()">
        <td *ngFor="let appTech of getAppTechs()" [matTooltip]="appTech.name" matTooltipPosition="above" class="td-values" [class.td-header]="getAppTechs().indexOf(appTech) == getAppTechs().length - 1">{{appTech.id.toUpperCase()}}</td>
      </ng-container>
    </tr>
    <ng-container *ngFor="let panel of job.panels">
      <ng-container *ngIf="panel.isSelected()">
        <tr class="tr-panel">
          <td class="td-panel-name td-header">
            {{panel.panel.name}}
          </td>
          <ng-container *ngFor="let substrate of getSubstrates()">
            <ng-container *ngFor="let appTech of getAppTechs()">
              <td class="td-values" [class.td-header]="getAppTechs().indexOf(appTech) == getAppTechs().length - 1">
                <div *ngIf="getTargetForPanel(panel, substrate, appTech)" (click)="clickChip($event, panel, substrate, appTech)"
                  class="chip" [class.unselected-chip]="!isSelected(chip, panel, substrate)" [class.selected-chip]="isSelected(chip, panel, substrate)"
                  #chip>
                  {{getTargetForPanel(panel, substrate, appTech) * 1000 | number:'1.0-0'}}g
                </div>
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>

  </table>

  <div class="actions">
    <button mat-stroked-button (click)="cancel()">Skip</button>
    <div class="spacer-20"></div>
    <button mat-stroked-button color="primary" (click)="submit()" [disabled]="getTotal() == 0">Mix {{getTotal() * 1000 | number:'1.0-0'}}g</button>
  </div>

</div>